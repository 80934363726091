import { IColumn } from "office-ui-fabric-react";

export const toQueryString = (o: { [key: string]: any }) => Object.keys(o)
    .map(key => [key, o[key].toString()].map(encodeURIComponent).join("="))
    .join("&");

export const limitsToRangeLabels = (ns: number[]): string[] => ns.map((n, i) => 
    n + "-" + ((i === ns.length - 1) ? "" : ns[i + 1]));

export const limitsToRangeLabelsWeightx = (ns: number[]): string[] => ns.map((n, i) => 
n + "-" + ((i === ns.length - 1) ? "" : ns[i + 1]));

export const limitsToRangeLabelsWeight = (ns: number[]): string[] => {
    let str:string[] = [];

    ns.map((n, i) => {
        if (n === 90)
            {str.push("< 90");} 
        else if (n === 150)
            {str.push("90-150");} 
        else if (n === 999)
            {str.push("> 150");} 
            
    })
    return str;
}



    
export const limitsToRangeLabels2 = (ns: number[]): string[] => ns.map((n, i) => (
(n === 1 ? n : n + 1) + "-" + ((i === ns.length - 1) ? "" : ns[i + 1])
)
);

export const col = (fieldName: string, name: string, minWidth = 100, maxWidth?: number, isResizable?: boolean ): IColumn => ({
    key: fieldName,
    fieldName, 
    name, 
    minWidth,
    maxWidth
});

export const pad = (n: number, l: number) => String.prototype.padStart.call(n, l, "0");

export const getDateString = (date: Date) => `${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)}`;

export interface Query 
{
    from?: string;
    to?: string;
    location?: string;
}