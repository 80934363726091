import React, { useState, useRef } from "react";
import { IContextualMenuItem, IIconProps, FontIcon, ContextualMenu } from "office-ui-fabric-react";

export interface Props
{
    iconProps: IIconProps;
    menuItems: IContextualMenuItem[];
}

function IconMenuButton(props: Props)
{
    const { iconProps, menuItems } = props;
    const [menuVisible, setMenuVisible] = useState(false);
    const ref = useRef<HTMLElement>();
    
    const onClick = (e: React.SyntheticEvent<HTMLElement>) => {
        ref.current = e.currentTarget;
        setMenuVisible(true);
    }

    return (
        <>
            <FontIcon onClick={onClick} {...iconProps} style={{ cursor: "pointer" }} />
            <ContextualMenu hidden={!menuVisible} onDismiss={() => setMenuVisible(false)} items={menuItems} target={ref.current} />
        </>
    );
}

export default IconMenuButton;