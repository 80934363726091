import styled from "styled-components";
import { Pill } from "./FilterPill";

export const FilterList = styled.div`
    margin: 2em 0 0 2em;
    padding: 8px;
    ${Pill} {
        margin: 0px 6px;
    }
`;
