import React, { useReducer } from "react";
import { DatePicker, Text, PrimaryButton, Stack, DefaultButton, IStackTokens } from "office-ui-fabric-react";
import { max, min } from "../../util/date";

export const stackLargeGap: IStackTokens = { childrenGap: "l1" };
export const stackSmallGap: IStackTokens = { childrenGap: "s1" };

interface Props
{
    onAccept?: (from: Date, to: Date) => void;
    onDismiss?: () => void;
}

interface DateRangeState
{
    from?: Date;
    to?: Date;
}

type Event = { type: "UPDATE_FROM", date?: Date } | { type: "UPDATE_TO", date?: Date };

export const dateRangeReducer = (state: DateRangeState, action: Event): DateRangeState =>
{
    if (action.type === "UPDATE_FROM")
    {
        return ({
            from: action.date,
            to: state.to && action.date && max(state.to, action.date)
        })
    }

    if (action.type === "UPDATE_TO")
    {
        return ({
            from: state.from && action.date && min(state.from, action.date),
            to: action.date
        });
    }

    return state;
}


export function DateRangePicker(props: Props)
{
    const [state, dispatch] = useReducer<typeof dateRangeReducer>(dateRangeReducer, {});

    const onFromChange = (date: Date | undefined | null) => date && dispatch({ type: "UPDATE_FROM", date });
    const onToChange = (date: Date | undefined | null) => date && dispatch({ type: "UPDATE_TO", date });

    const onOkClick = () => 
    {
        props.onAccept?.(state.from!, state.to!);
        props.onDismiss?.();
    }

    return (<div style={{ padding: 18 }}>
        <Stack tokens={stackLargeGap}>
            <Text variant="large">Custom Date Range</Text>
            <Stack tokens={stackSmallGap}>
                <DatePicker placeholder="Select date" label="From" value={state.from} onSelectDate={onFromChange} />
                <DatePicker placeholder="Select date" label="To" value={state.to} onSelectDate={onToChange} />
            </Stack>
            <Stack horizontal horizontalAlign="end" tokens={stackSmallGap}>
                <DefaultButton onClick={props.onDismiss}>Cancel</DefaultButton>
                <PrimaryButton disabled={state.from === undefined || state.to === undefined} onClick={onOkClick}>OK</PrimaryButton>
            </Stack>
        </Stack>
    </div>);
}
