import React, {useRef,useEffect, useState, useCallback, HtmlHTMLAttributes,  } from "react";
import { Icon , FontIcon, Label, TextField, Stack, Dropdown, Checkbox, IStackTokens, ResponsiveMode, IRectangle, Target, DefaultButton, PrimaryButton, IconButton, IDropdownOption, MessageBarType, elementContainsAttribute } from "office-ui-fabric-react";
import { ExpandedLocation, adminUpdateLocation } from "../../../api";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNotificationService } from "../../../hooks/useNotificationService";
import { useGoogleTimezone } from "../../../hooks/useGoogleTimezone";
import { DialogType } from "./DialogController";
import debounce from 'debounce'
import { usePlacesWidget } from "react-google-autocomplete";
import pin from "../../../assets/Pin.png";
 
const tokens: IStackTokens = {
    childrenGap: 8
}


  function useAddressPredictions(input:string) {
    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>();
    const autocomplete = useRef<any>();
    const ref = useRef<HTMLDivElement>(null);


    
    if (!autocomplete.current) {
        let gm = new window.google.maps.Map(ref.current! , {zoom:10, center:{ lat: -34.397, lng: 150.644 } });

        autocomplete.current = null;

        //autocomplete.current = new window.google.maps.places.AutocompleteService();
    }
  
    function getPlacePredictions(input: string) {
        autocomplete.current.getPlacePredictions(
          { input, types: ['address'] },
          (predictions: any) => {
            setPredictions(
              predictions
                ? predictions.map((prediction: any) => prediction.description)
                : []
            )
          }
        )
      }
  
    const debouncedGetPlacePredictions = useCallback(
        debounce(getPlacePredictions, 500),
        []
      )

    useEffect(() => {
        debouncedGetPlacePredictions(input)
    }, [input,debouncedGetPlacePredictions]);
  
    return predictions;
  }



const computeBounds = (target?: Target, targetWindow?: Window) =>
{
    if(target instanceof Element && targetWindow !== undefined)
    {
        const rect = target.getBoundingClientRect();
        const result: IRectangle = {

            height: targetWindow.innerHeight - (rect.bottom) - 8,
            width: rect.width,
            bottom: targetWindow.innerHeight,
            right: rect.right,
            top: 0, //rect.bottom,
            left: 0, //rect.left,
        }
        return result;
    }
    return undefined;
}

const calloutProps = { bounds: computeBounds };

export interface Props
{
    location: ExpandedLocation;
    parentLocation: ExpandedLocation | null;
    timezoneOptions: IDropdownOption[];
    onSaved?: () => void;
    onShowDialog?: (type: DialogType) => void;
}

export interface IGooglePlaceInfo
{
    address?: string;
    lat?: string;
    lng?: string;
}


  const AutocompletePlace  = () => (
      <input></input>
  );

const checkForDisableTimeZone = (address: string | null):boolean => {
  return address === null || address !== "" ? true : false;
}

  

export function LocationForm(props: Props)
{
    const [input, setInput] = useState("");
    const [googlePlace, setGooglePlace] = useState<IGooglePlaceInfo | undefined>();
    const notifications = useNotificationService();
    const { timezoneOptions: options, location, parentLocation, onShowDialog } = props;
    const [ timeZone, reloadTimeZone ] =  useGoogleTimezone({apikey:"AIzaSyB6bnmHKloFJNJABRXBdTduNuepdToHMqI", timestamp:"1331161200"});

    const [locationLabel, setLocationlabel] = useState<string>(props.location.label);
    const [locationAddress, setLocationAddress] = useState<string>(props.location.effectiveAddress);
    const [locationAdrInherit, setLocationAdrInherit] = useState<boolean>(props.location.address === null);
    const [locationTimeZone, setLocationTimeZome] = useState<string>(props.location.effectiveTimezone);
    const [locationTimeZoneInherit, setLocationTimeZomeInherit] = useState<boolean>(props.location.timezone === null);
    const [disableAddress, setDisableAddress] = useState<boolean>(props.location.address === null ? true:false);
    const [disableTimeZone, setDisableTimeZone] = useState<boolean>(checkForDisableTimeZone(props.location.address));
    const [disableTimeZoneDrop, setDisableTimeZoneDrop] = useState<boolean>(props.location.timezone === null);


 
    const loadFieldsData = () => {

      setLocationlabel(props.location.label);
      setLocationAddress(props.location.effectiveAddress);
      setLocationAdrInherit(props.location.address === null);
      setLocationTimeZome(props.location.effectiveTimezone);
      setLocationTimeZomeInherit(props.location.timezone === null);
      setDisableAddress(props.location.address === null ? true:false);
      setDisableTimeZone(checkForDisableTimeZone(props.location.address));
      setDisableTimeZoneDrop(checkForDisableTimeZone(props.location.address) || props.location.timezone === null)
    }



    const { ref, autocompleteRef } = usePlacesWidget<any>({
      apiKey:"AIzaSyB6bnmHKloFJNJABRXBdTduNuepdToHMqI",
      language:"en",
      options:{types:["geocode", "establishment"]},
      onPlaceSelected: async (place) => {
        if (place.geometry !== undefined)
        {
          setGooglePlace({address: place.formatted_address, lat: place.geometry!.location!.lat().toString(), lng: place.geometry!.location!.lng().toString()  })
          //setFieldValue("address", place.formatted_address);
          setLocationAddress(place.formatted_address!)
          await reloadTimeZone(place.geometry!.location!.lat().toString() +"," + place.geometry!.location!.lng().toString());
        }
      }
    });

   
    const onChangeAdress = (event: React.ChangeEvent<HTMLInputElement>) => {
      
      setDisableTimeZone(checkForDisableTimeZone(event.target.value));
      setDisableTimeZoneDrop(checkForDisableTimeZone(event.target.value));
      if (event.target.value !== ""){
        setLocationTimeZomeInherit(!checkForDisableTimeZone(event.target.value));
      }

      setLocationAddress(event.target.value)
      setGooglePlace(undefined);
    }


    useEffect(
      () => {
          (async () => {
                if (timeZone?.timeZoneName! !== "" && timeZone?.timeZoneName! !== undefined && timeZone?.timeZoneName! !== null){
                  setLocationTimeZome(timeZone?.timeZoneName!); 
                }
      })()
  },[timeZone])


  useEffect(
    () => {
       loadFieldsData();

},[location])


//values.timezone


const submitForm = async () => {

  const inheritTimezone = locationAdrInherit === true || locationTimeZoneInherit === true ? true:false;

  const res = await adminUpdateLocation(location.id, { label: locationLabel , timezone: inheritTimezone === true ? null : locationTimeZone, address: locationAdrInherit === true ? null : locationAddress || "", position: googlePlace?.lat + "," + googlePlace?.lng });
      
    
    if(res.ok)
    {
        props.onSaved?.();
        notifications.addNotification(`Location '${location.label}' was successfully updated.`, MessageBarType.success);
    }
    else
    {
        notifications.addNotification(`Could not save changes to location '${location.label}'`, MessageBarType.error);
    }
  

}

const resetForm = () => {
  loadFieldsData();
}

const handleAddressCheckbox = (ev:React.FormEvent<HTMLInputElement | HTMLElement> | undefined, checked: boolean | undefined) => {
  checked! === true ? setDisableAddress(true) : setDisableAddress(false)

  // copy timezone
  if (checked!){
    setDisableTimeZone(true);
    setDisableTimeZoneDrop(true);
    setLocationTimeZome("");
  }
  else
  {
    setDisableTimeZone(false);
    setDisableTimeZoneDrop(false);
    setLocationTimeZome(props.location.effectiveTimezone);
  }

  // Copy Address....
  if (locationAddress !== "" && checked){
    setLocationAddress("");
  }
  else
  {
    setLocationAddress(props.location.address);
  }

  //set address and timezone inherit
  setLocationAdrInherit(checked!);
  setLocationTimeZomeInherit(checked!);
}

const handleTimeZoneCheckbox = (ev:React.FormEvent<HTMLInputElement | HTMLElement> | undefined, checked: boolean | undefined) => {
  setLocationTimeZomeInherit(checked!);

  // copy timezone
  if (checked!){
    setLocationTimeZome("");
    setDisableTimeZoneDrop(true);
  }
  else
  {
    setLocationTimeZome(props.location.effectiveTimezone);
    setDisableTimeZoneDrop(false);
  }

}

const handleTimeZoneChange = (ev:React.FormEvent<HTMLInputElement | HTMLElement> | undefined, option: IDropdownOption | undefined) => {
  setLocationTimeZome(option!.key.toString());
  
}

const handleLabelChange = (ev:React.FormEvent<HTMLInputElement | HTMLElement> | undefined, value: string | undefined) => {
  setLocationlabel(value!);
}




    return (
        <Stack tokens={{childrenGap: 20}}>
            <Stack tokens={tokens}>
                <TextField label="Name" value={locationLabel} onChange={(ev, value) => handleLabelChange(ev,value)}  name="label" />

                <div style={{position:"relative"}}>
                  <Label disabled={disableAddress}>Address</Label>
                  <input onChange={onChangeAdress} disabled={disableAddress} value={locationAddress ?? ""} ref={ref} style={{marginTop:"0px", height:"32px", borderColor:"rgb(96, 94, 92)", borderWidth:"thin", padding:"0px 8px", borderRadius:"0px", display: "block", boxSizing: "border-box", width:"100%" }} ></input>
                  {googlePlace !== undefined ?
                    <img src={pin} style={{height: "26px", position: "absolute", top: "27px", right: "5px", color:"green", fontSize:"22px", fontWeight: "bold"}} /> 
                  :
                  <></>      
                }
                </div>

                <Checkbox 
                  disabled={props.parentLocation === null ? true : false}
                    label="Inherit address" 
                    name="inheritAddress"
                    checked={locationAdrInherit}
                    onChange={(ev, checked) => {
                      handleAddressCheckbox(ev,checked);
                      //setFieldValue("timezone", checked ? (parentLocation?.effectiveTimezone || "Coordinated Universal Time") : location.effectiveTimezone);
                      //alert(checked);
                  }}
                />
                
 
                <Dropdown label="Time Zone" 
                    //errorMessage={errors.timezone}
                    disabled={disableTimeZoneDrop}
                    options={options} 
                    responsiveMode={ResponsiveMode.large} 
                    calloutProps={calloutProps}
                    //selectedKey={timeZone?.timeZoneName} 
                    selectedKey={locationTimeZone} 
                    onChange={(ev, option) => {
                      handleTimeZoneChange(ev, option)
                      //setFieldValue("timezone", option?.id);
                    }}
                 />



                <Checkbox 
                    disabled={disableTimeZone}
                    label="Inherit time zone" 
                    checked={locationTimeZoneInherit} 
                    name="inherit"
                    onChange={(ev, checked) => {
                        handleTimeZoneCheckbox(ev, checked);
                        //setFieldValue("timezone", checked ? (parentLocation?.effectiveTimezone || "Coordinated Universal Time") : location.effectiveTimezone);
                        //alert(checked);
                    }}  />
                 
            </Stack>
            <Stack horizontal tokens={tokens}>
                {/*<PrimaryButton disabled={!dirty} onClick={() => submitForm()}>Save</PrimaryButton>*/}
                <PrimaryButton onClick={() => submitForm()}>Save</PrimaryButton>
                <DefaultButton onClick={() => resetForm()}>Reset</DefaultButton>
                <div style={{flexGrow: 1}} />
                <IconButton iconProps={{iconName: "trash"}} title="Delete location" onClick={() => onShowDialog?.("location-remove") }/>
            </Stack>
        </Stack>
    );
}
