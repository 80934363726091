import React, { useRef, useMemo, useState, useCallback } from "react";
import { FaBalanceScale, FaTools } from "react-icons/fa"

import { Hoist } from "./useLocationHoists";
import { IDropdownOption, mergeStyles, FontIcon, Checkbox, IconButton, getTheme, Modal, IIconProps, IStackProps, mergeStyleSets, IButtonStyles, FontWeights, IColumn, Selection, Link, Panel, ColumnActionsMode, IContextualMenuItem, ContextualMenu, ContextualMenuItemType, ShimmeredDetailsList, Check } from "office-ui-fabric-react";
import { useLocationTree } from "../../hooks/useLocationTree";
import { Location } from "../../api/index";
import { Node } from "../../components/treevew/TreeView";
import { DetailsListLayoutMode } from "office-ui-fabric-react";
import { useBoolean } from '@uifabric/react-hooks'
import { ModalHoist } from "./../hoist/HoistModainfo"
import { StatusOptions, ICustomData } from "./../hoist/Hoists"
import './../../style.css';
import wifi from './../../assets/WiFi_green.png';
import scale from './../../assets/Scale_green.png';
import scaleIII from './../../assets/ScaleIII_green.png';
import service from './../../assets/Setup_green.png';
import trainer from './../../assets/Trainer_green.png';
import house from './../../assets/Adresse_green.png';
import house_yellow from './../../assets/Adresse_yellow.png';

import { getProductType } from "./../../util/helper";



import { number, string } from "yup";


const iconClass = mergeStyles({
  fontSize: 16,
  height: 16,
  width: 16,
  color: "green",
  cursor: "pointer",
  marginLeft: "8px"
});


const getBinPosition = (binString:string, position:number):number => {
    if (position == 0) return -1;
    if (position > binString.length) return -1;

    const len = binString.length;
    const bit = binString.substring(len - (position-1), len - position);

    return Number(bit);
}



export interface Props
{
    onSelectionChanged?: (selection: Hoist[]) => void;
    locationFilter?: LocationFilter;
    onLocationFilterChange?: (locationFilter?: LocationFilter) => void;
    items?: Hoist[];
    reload: () => void;
}

export interface Props
{
    onSelectionChanged?: (selection: Hoist[]) => void;
    locationFilter?: LocationFilter;
    onLocationFilterChange?: (locationFilter?: LocationFilter) => void;
    items?: Hoist[];
    reload: () => void;
}

export interface ITest
{
    items?: Hoist[];
}


const moduleInstalledColor = "#87c34b";

const hasModuleScale = (h: Hoist) => {
    return (Math.floor(h.liftType4 / 1000) % 10) > 0;  
};

const hasModuleService = (h: Hoist) => {
    return (Math.floor(h.liftType4 / 10) % 10) > 0;  
};

const toMenu = (locations: Node<Location>[], selected: number[]): IContextualMenuItem[] => 
    locations.map(node => ({
        key: node.id,
        text: node.label,
        data: node,
        canCheck: true,
        checked: selected.findIndex(locationId => locationId === node?.data?.id) > -1,
        ...(node.children ? { 
            split: true,
            subMenuProps: {
                items: toMenu(node.children, selected)
            }} : {})
    }));

export type LocationFilter = "unassigned" | number;

const modalProps = {
    isBlocking: true,
    topOffsetFixed: true,
  };


interface IBase {
    item?:Hoist;
}

interface IAdressCheckbox extends IBase{
}

interface IStatusIcons extends IBase {
    item?:Hoist;
}


const AdressCheckbox = (props:IAdressCheckbox) => {
    let checked = false;
    const adress = props.item?.address;
    let outPutElement = null;
    let houseType = undefined;

//if (props.item?.hoistSerialNo === '005521620002015092220456'){
//    const test = 0;
//}

let addressType = 0;

if (props.item?.useAutoAddress === true && props.item?.autoAddress !== "") //
{
    addressType = 1;
}
else if (props.item?.useAutoAddress === true && props.item?.autoPosition === "" && (props.item?.hoistPosition !== "" && props.item?.hoistPosition !== null)) //
{
    addressType = 2;
}
else if (props.item?.useAutoAddress === false && (props.item?.hoistPosition !== "" && props.item?.hoistPosition !== null)) //
{
    addressType = 2;
}
else if (props.item?.useAutoAddress === false && props.item?.hoistPosition === "") //
{
    addressType = 3;
}



if (addressType === 1){
    houseType = house;
}
else if (addressType === 2){
    houseType = house_yellow;
}
else if (addressType === 3 &&  (props.item?.locationAddress !== "" && props.item?.locationAddress !== null)){
    houseType = house_yellow;
}

outPutElement = <div><img src={houseType} height="16" style={{marginLeft:"16px"}}/></div>

return (
  <>
    {/*checked ? <Checkbox defaultChecked key={1}/> : <Checkbox key={2} />*/}
    {outPutElement}
  </>
)
  
}

const StatusIcons = (status: IStatusIcons) => {

    const options = StatusOptions as IDropdownOption[];
    const item = status.item?.status! === undefined ? '0' : status.item?.status!.toString();
    const data = options.find(x => x.key === item)?.data as ICustomData;

    return (
    <div className='customOption'>
        {data !== undefined ? <div className={data.name} style={ { backgroundColor: data.color } }></div> : null}
    </div>
    );
}


const sleep = (ms:number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }


const StatusIconsAsync = (props:ITest) => {

    const getValues = async () =>{
      for (let i = 0;i<props.items?.length!;i++){
        await sleep(3000);
        props.items![i].status = 3;
      }


    }
    
    React.useEffect(() => {
        const callback = async () => {
            await getValues()
      }
        callback();
      }, [props.items]);



    return(
        null
    );
}


function HoistList(props: Props)
{
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const { items, onSelectionChanged, locationFilter, onLocationFilterChange, reload } = props;
    const onSelectionChangedRef = useRef<typeof props.onSelectionChanged>();
    onSelectionChangedRef.current = onSelectionChanged;
    const [hoistDetailView, setHoistDetailView] = useState<Hoist>();
    const [contextMenu, setContextMenu] = useState<IContextualMenuItem[]>();
    const [locations] = useLocationTree();
    const contextMenuTarget = useRef<Element>();
    const [hoistItem, setHoistItem] = useState<Hoist>();

    const onClickHoist = useCallback((item: Hoist) =>{
      toggleHideDialog();
      setHoistItem(item);
    },[hoistItem]);


    const onColumnClick = (ev: React.MouseEvent<HTMLElement>,column: IColumn) => {

    }



    const createColumns = (onNameClick: (item: Hoist) => void): IColumn[] => [
        { fieldName: "displaySerialNo", key: "name", name: "Name", minWidth: 100, isResizable: true, onColumnClick: onColumnClick, onRender: (item, _, col) => (
            <Link onClick={() => onClickHoist(item)}>{item[col!.fieldName!]}</Link>
        ) },
        { fieldName: "hoistSerialNo", key: "serialNo", name: "Serial Number", minWidth: 200, isResizable: true },
        { fieldName: "locationName", key: "location", name: "Location", minWidth: 200, isResizable: true,
            columnActionsMode: ColumnActionsMode.hasDropdown
            // onRender: (item: Hoist & { locationName: string }) => (
            //     <TooltipHost content={item.locationName} >{item.locationName}</TooltipHost>
            // )
    },
    { fieldName: "address", key: "address", name: "Address", minWidth: 100, isResizable: true, onRender: (item, _, col) => {
      return (
        <div style={{pointerEvents:"none"}}><AdressCheckbox item={item} /></div>
      )

    }
      
   },



        { fieldName: "liftType1", key: "liftType1", name: "Model", minWidth: 60, isResizable: true, onRender: (item: Hoist) => <span>{getProductType(item.liftType1)}</span> },
        { fieldName: "liftType4", key: "liftType4", name: "Modules", minWidth: 150, isResizable: true, onRender: (item: Hoist) => {
            return (
                <>
                    {getBinPosition(item.liftType4.toString(),9) > 0 ? <><img src={trainer} height="20"/>&nbsp;</>: null}
                    {getBinPosition(item.liftType4.toString(),6) > 0 ? <><img src={scaleIII} height="20"/>&nbsp;</> : null}
                    {getBinPosition(item.liftType4.toString(),5) > 0 ? <><img src={wifi} height="20"/>&nbsp;</> : null}
                    {getBinPosition(item.liftType4.toString(),4) > 0 ? <><img src={scale} height="20"/>&nbsp;</> : null}
                    {getBinPosition(item.liftType4.toString(),2) > 0 ? <><img src={service} height="20"/>&nbsp;</> : null}
                </> 
            )
        }
    },
    { fieldName: "hoistServiceStatus", key: "status", name: "Status", minWidth: 100, isResizable: true, onRender: (item, _, col) => {
      return (
        <>
        <StatusIcons item={item} />
        {/*<StatusIconsAsync items={props.items} />*/}
        </>
      )
  
    }
      
   } 
  ];


    const columns = createColumns((item) => setHoistDetailView(item));

    
    const selection = useMemo(() => {
        const selection_: Selection = new Selection({
            onSelectionChanged: () => {
                onSelectionChangedRef.current?.(selection_.getSelection() as Hoist[])
            }
        });
        return selection_;
    }, []);




    const onColumnHeaderContextMenu = (column?: IColumn, ev?: React.MouseEvent<HTMLElement, MouseEvent>) => 
    {
        if(column?.key === "location" && locations !== undefined)
        {
            if(ev !== undefined)
            {
                contextMenuTarget.current = ev.currentTarget;
            }
            setContextMenu([
                ...(
                    locationFilter !== undefined ? [{
                        key: "__clear",
                        text: "Clear filter"
                    },{
                        key: "divider_2",
                        itemType: ContextualMenuItemType.Divider,
                    }] : []
                ),

                ...toMenu(locations, (locationFilter && locationFilter !== "unassigned") ? [locationFilter] : []),

                {
                    key: "divider_2",
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: "__unassigned",
                    text: "Unassigned",
                    canCheck: true,
                    checked: locationFilter === "unassigned"
                },
                
            ]);
        }
    };

    const onContextualMenuDismiss = () => setContextMenu(undefined);
    const onContextualMenuItemClick = (ev: any, item?: IContextualMenuItem) => 
    {
        switch(item?.key)
        {
            case "__clear":
                onLocationFilterChange?.(undefined);
                break;
            case "__unassigned":
                onLocationFilterChange?.("unassigned");
                break;
            default:
                onLocationFilterChange?.((item?.data as Location)?.id);
                break;
        }
    }


    return (
        <>
        {
            (contextMenu) ? (
                <ContextualMenu 
                    target={contextMenuTarget.current}
                    items={contextMenu} 
                    onItemClick={onContextualMenuItemClick}
                    onDismiss={onContextualMenuDismiss} />
            ) : null
        }
{
  <ModalHoist isOpen={hideDialog} onDismiss={toggleHideDialog} hoistItem={hoistItem} onReload={() => reload()}/>
}

        <ShimmeredDetailsList enableShimmer={items === undefined} items={items ?? []} columns={items === undefined || items.length === 0  ? [] : columns} selection={selection} 
            onColumnHeaderContextMenu={onColumnHeaderContextMenu}
            skipViewportMeasures 
            layoutMode={DetailsListLayoutMode.justified}
        />
        </>
    );
}


//https://codepen.io/piyushwaykole/pen/KKgrYLz

export default HoistList;