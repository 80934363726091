import React, { useState, useEffect } from "react";
import UnassignDialog from "./UnassignDialog";
import AssignDialog from "./AssignDialog";
import ErrorPanel from "./ErrorPanel";
import { Location, adminHoistAssignLocation, adminHoistUnassignLocation } from "../../api";
import { Hoist } from "./useLocationHoists";
import { Node } from "../../components/treevew/TreeView";
import RegisterHoistPanel from "./RegisterHoistPanel";
import { PanelType, Panel } from "office-ui-fabric-react";
import { useBoolean, useId } from '@uifabric/react-hooks'
import { hoistsErrors } from "../../atoms/hoistsError";
import { useRecoilState } from "recoil";


export type DialogType = "register-hoist" | "hoist-unassign" | "hoist-assign";

export interface Props
{
    visibleDialog?: DialogType;
    selectedHoists?: Hoist[];
    locations: Node<Location>[];

    onAssignHoistToLocation?: (hoists: Hoist[], location: Location) => void;
    onUnassignHoistLocation?: (hoists: Hoist[]) => void;
    onDismiss: () => void;
    refresh: () => void;
}

function DialogController(props: Props)
{
    const [busy, setBusy] = useState(false);
    const { 
        visibleDialog, 
        selectedHoists,
        locations,
        onDismiss,
        refresh
    }  = props;
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [hoistsError, setHoistError] = useRecoilState(hoistsErrors);


    const onAssignHoistToLocation = (hoists: Hoist[], location: Location) => {
        setBusy(true);
        adminHoistAssignLocation(location.id, hoists.map(x => x.hoistSerialNo)).then(res => {
            if(res.ok)
            {
                onDismiss();
                refresh();
            }
        }).finally(() => setBusy(false));
    }

    const onDetails = () => {
        openPanel();
    }

    const onUnassignHoist = (hoists: Hoist[]) => {
        setBusy(true);
        adminHoistUnassignLocation(hoists.map(x => x.hoistSerialNo)).then(res => {
            if(res.ok)
            {
                onDismiss();
                refresh();
            }
        }).finally(() => setBusy(false));
    }
    
    return (
        <>
            <UnassignDialog 
                busy={busy}
                hoists={selectedHoists} 
                visible={visibleDialog === "hoist-unassign" && selectedHoists !== undefined} 
                onDismiss={onDismiss} 
                onUnassign={onUnassignHoist}
                />
            <AssignDialog
                busy={busy}
                locations={locations}
                visible={visibleDialog === "hoist-assign" && selectedHoists !== undefined}
                onDismiss={onDismiss}
                hoists={selectedHoists}
                onAssign={onAssignHoistToLocation}
                />
            <RegisterHoistPanel
                open={visibleDialog === "register-hoist"}
                onDismiss={onDismiss}
                onDetails={onDetails}
            />
            <Panel headerText="Hoists error details" isOpen={isOpen} onDismiss={dismissPanel}  closeButtonAriaLabel="Close" type={PanelType.custom} customWidth="600px" >
                <ErrorPanel hoistErrors={hoistsError}></ErrorPanel>
            </Panel>


        </>
    );
}

export default DialogController;