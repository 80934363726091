import { API_LOCATIONS, Location, ExpandedLocation } from "../api";
import { useAuthorizedFetch } from "./useFetch";
import { Node } from "../components/treevew/TreeView";
import { useMemo } from "react";

const mapToTreeViewData = <T extends Location>(locations: T[]): Node<T>[] =>
    locations.map(location => ({
        id: location.id.toString(),
        data: location,
        label: location.label,
        children: (location.children.length > 0) ? mapToTreeViewData<T>(location.children as T[]) : undefined,
    }));

const removeTopLevel = <T extends Location>(locations: Node<T>[]) => 
    locations.reduce<Node<T>[]>((result, node) => [...result, ...(node.children ?? [])], []);

export function useLocationTree(removeRoots = false): [Node<Location>[] | undefined, () => void, Location[] | undefined]
{
    const [state, locations, refresh] = useAuthorizedFetch<Location[]>(API_LOCATIONS);
    const value = useMemo(() => {
        if(locations === undefined || state !== "success")
        {
            return undefined;
        }

        const nodes = mapToTreeViewData(locations);
        return (removeRoots)
            ? removeTopLevel(nodes)
            : nodes;
    }, [locations, removeRoots, state]);
    
    return [value, refresh, locations];
}

export function useExpandedLocationTree(removeRoots = false): [Node<ExpandedLocation>[] | undefined, () => void, ExpandedLocation[] | undefined]
{
    const [state, locations, refresh] = useAuthorizedFetch<ExpandedLocation[]>(`${API_LOCATIONS}/expanded`);
    const value = useMemo(() => {
        if(locations === undefined || state !== "success")
        {
            return undefined;
        }

        const nodes = mapToTreeViewData(locations);
        return (removeRoots)
            ? removeTopLevel(nodes)
            : nodes;
    }, [locations, removeRoots, state]);
    
    return [value, refresh, locations];
}
    