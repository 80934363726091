import React, { useMemo, useState } from "react";
import { useAuthorizedFetch } from "../../hooks/useFetch";
import { API_LOCATIONS, Location } from "../../api";
import { IContextualMenuItem, ContextualMenuItemType } from "office-ui-fabric-react";
import { FilterPill } from "../../components/filter/FilterPill";

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    
    keepRootLevel?: boolean;
    
    
    onLocationChange?: (location?: Location[]) => void;

}

const locationsToMenuItems = (locations: Location[], selection: Location[]): IContextualMenuItem[] => 
    locations.map(loc => ({
        key: loc.id.toString(),
        text: loc.label,
        // canCheck: true,
        // checked: selection.find(x => x.id === loc.id) !== undefined,
        data: loc,
        ...((loc.children.length === 0) ? {} : ({
            subMenuProps: { items: locationsToMenuItems(loc.children, selection) },
            split: true
        })),
    }));

function LocationPill(props: Props)
{
    const [state, locations] = useAuthorizedFetch<Location[]>(API_LOCATIONS);
    const [selection, setSelection] = useState<Location[]>([]);

    const onItemClicked = (item: IContextualMenuItem) => 
    {
        const newSelection = (item.key === "__all")
            ? undefined
            : [item.data as Location];

        setSelection(newSelection ?? []);
        props.onLocationChange?.(newSelection);
    }

    const menu: IContextualMenuItem[] = useMemo(() => 
    {
        const result: IContextualMenuItem[] = 
              (state.endsWith("error")) ? [{ key: "error", label: "Could not load locations", }] 
            : (locations === undefined) ? [{ key: "progress", label: "Loading...", }]
            : locationsToMenuItems(locations, selection);
        
        const all: IContextualMenuItem = {
            key: "__all",
            text: "All",
            onClick: () => setSelection([])
        }

        return [
            all, 
            { key: "__divider_1", itemType: ContextualMenuItemType.Divider },
            ...result
        ];
    }, [locations, state, selection]);

    const text = (selection.length === 0) ? "All" : selection.map(x => x.label).join(", ");

    return (
        <FilterPill label="Location" text={text} menu={menu} onItemClicked={onItemClicked} />
    );
}

export default LocationPill;