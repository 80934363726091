import styled from "styled-components";

export const SiteContainer = styled.div`
    display: grid;
    min-height: 100vh;
    grid-template-columns: 230px auto;
    grid-template-rows: 50px auto; /*calc(2em + 2vw) auto ; */
    grid-template-areas:
        "header header"
        "sidebar main"
/*        "footer footer" */
    ;

    @media screen and (max-width: ${props => props.theme.breakpoint.m}) {
        grid-template-areas:
            "header header"
            "main main"
    }
`;