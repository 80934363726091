import React, { useMemo } from "react";
import { IStackProps, Stack } from "office-ui-fabric-react";

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;
}

const defaultTokens = { childrenGap: "l1" };
const defaultStyle = { marginTop: 8 };
// const PanelForm = () => (
//     <Stack tokens={}
// );


export function PanelForm({ tokens, style, ...rest }: IStackProps)
{
    const _tokens = useMemo(() => ({...defaultTokens, ...tokens, }), [tokens]);
    const _style = useMemo(() => ({...defaultStyle, ...style, }), [style]);
    return <Stack tokens={_tokens} style={_style} {...rest} />;
}

export const PanelFormSection = Stack.Item;

const fieldsDefaultTokens = { childrenGap: "s1" };
export function PanelFormFields({ tokens, ...rest }: IStackProps)
{
    const _tokens = useMemo(() => ({...fieldsDefaultTokens, ...tokens, }), [tokens]);
    return <Stack tokens={_tokens} {...rest} />;
}

