import React, { useCallback, useEffect } from "react";
import { Nav, INavLink, INavLinkGroup } from "office-ui-fabric-react/lib/Nav";
import { useNavigate } from "react-router-dom";
import { useUserRoles } from "../api-authorization/useUserRoles";
import { License, substriptionPageStep, IValid, substriptionValidation, licenseAtom, IRefreshLicense, refreshLicense} from "./../atoms/license";
import { useRecoilState, useRecoilValue } from "recoil";


export interface Props
{
    onLinkClick?: (item: INavLink) => void;
}

function SidebarNavigation(props: Props)
{
    const navigate = useNavigate();
    const roles = useUserRoles();
    const [pageView, setPageView] = useRecoilState(substriptionPageStep);
    const [valid, setValid] = useRecoilState<IValid>(substriptionValidation);
    const [license, SetLicense] = useRecoilState<License>(licenseAtom);


    const onLinkClick = useCallback((e?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        if(item !== undefined)
        {
            navigate(item.url);
            e?.preventDefault();
            props.onLinkClick?.(item);
            // reset pageview in substription
            
            if (!license.valid)
            {
                navigate('/admin/subscription');
            }
            
            if (item.name === 'Subscription' && license.valid){
                setPageView({step:1, pagetype: "overview", lincenseRefresh:false})
                setValid({msg: "", valid:undefined, show:false, saved: false});
            }
        }

    }, [navigate, props.onLinkClick, license]);

    const groups: INavLinkGroup[] = [
        {
            // name: "Main",
            links: [{
                name: "Dashboard",
                url: "/",
            },{
                name: "Hoists",
                url: "/hoists",
            },
            {
                name: "Map",
                url: "/map",
            },
            {
                name: "Training",
                url: "/training",
            },
        ]
        }
    ];

    if(roles?.includes("Administrator"))
    {
        groups.push({
            name: "Administration",
            
            links: [{
                name: "Locations",
                url: "/admin/locations",
            },{
                name: "Users",
                url: "/admin/user-management",
            },{
                name: "Roles",
                url: "/admin/role-management",
            },{
                name: "Audit",
                url: "/admin/audit"
            },{
                name: "Tags",
                url: "/admin/tag"
            },{
                name: "Subscription",
                url: "/admin/subscription"
            },{
                name: "Settings",
                url: "/admin/settings"
            },
        ]
        });
    }

    return (
        <Nav onLinkClick={onLinkClick} groups={groups} />
    )
}

export default SidebarNavigation;