import styled from "styled-components";

const Sidebar = styled.nav`
    background-color: #faf9f8;
    border-right: 1px solid #eee;
    grid-area: sidebar;
    justify-self: stretch;
    /* align-self: flex-start; */
    align-self: stretch;

    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${props => props.theme.breakpoint.m}) {
        display: none;
    }

    & > * { flex-grow: 1; }
`;

export const SidebarFooter = styled.div`
    padding: 8px;
    width: 100%;
    flex-grow: 0;
`;

export default Sidebar;