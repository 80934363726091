import React, { useState, useEffect, useCallback, MouseEvent } from "react";
import { useAuthorizedFetch } from "../../../hooks/useFetch";
import { PanelForm, PanelFormSection } from "../../../components/panelform/PanelForm";
import {intl} from "../../../i18n";
import {Iproducts} from "./SubscriptionList";
import {TextField, PrimaryButton, DefaultButton, Icon, MessageBar, MessageBarType, Text, Dialog, DialogFooter} from "office-ui-fabric-react";
import Voucher, { IVoucherInfo } from "./Voucher";
import { getVoucher, createPlan, cancelsubscription } from "../../../api";
import styled from "styled-components";
import { contestContainer, subscriptionContainer, dialogContentProps, dialogStyles, dialogContentPropsSave} from "./SubscriptionStyle" 
import { useRecoilState, useSetRecoilState } from "recoil";
import { IValid, substriptionValidation } from "./../../../atoms/license";
import { useBoolean, useId } from '@uifabric/react-hooks'
import { refreshLicense, IRefreshLicense} from "../../../atoms/license";
import { useNavigate } from "react-router-dom"

interface ILicenseDescriptionProps {
    title?: string;
    licenseDescription?:ISubscriptionDetails | undefined;
    onRestart?: () => void;
//    onSuccess?: boolean | undefined;
    notification?: (valid:IValid) => void;
    refreshProp: () => void;
}




interface ILicenseRenewProps {
  licenseDescription?:ISubscriptionDetails | undefined;
}


interface ILicenseDescription {
    validTo?: string;
    hoists?: string;
}

export interface IPlan {
  product? : Iproducts;
  voucher? : IVoucherInfo;
}

export interface ILicenseCollection {
  name: string;
  start: Date;
  end: Date;
  hoists: number;
  status: number;
  licenseType: number;
  txtStatus: string;
  expired: boolean; 
  sku: string;
  plan: string;
}

export interface ISubscriptionDetails
{
    voucherId: string;
    licenseId: string;
    licensedHoists: number;
    regHoists: number;
    licensePlan: string;
    licenseList: ILicenseCollection[];
    days: number;
    renewDate: Date;
    sku: string;
    plan: string;
}

const findPriceFromList = (prodList:Iproducts[], sku:string):string =>{
  let price: string | undefined = "0";
  price = prodList.find(x => x.productno === sku)?.price;
  if (price === undefined)
  {
    return "0";
  } 
  else{
    return price;
  }
}

const RowIcon = styled(Icon)` font-size: 19px; `;

const LicenseDescription2 = (props:ILicenseDescriptionProps) => {
  const [productsStatus, products, refresh] = useAuthorizedFetch<Iproducts[]>("api/admin/subscription/AllproductsM3");
  const [valueVoucher, setSVoucher] = useState<string>();
  const [voucherInfo, setvoucherInfo] = useState<IVoucherInfo>();
  const [validVoucher, setValidVoucher] = useState<boolean>(false);
  const [itemProduct, setItemProduct] = useState<Iproducts>();
  const [countLicenseHoists, setCountLicenseHoists] = useState<string>();
  const [copyOfProduct, setCopyOfProduct] = useState<Iproducts>();
  const [valid, setValid] = useRecoilState<IValid>(substriptionValidation);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [hideDialogSave, { toggle: toggleHideDialogSave }] = useBoolean(true);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');
  const [refreshLic, setRefreshLic] = useRecoilState<IRefreshLicense>(refreshLicense);
  const navigate = useNavigate();
  const [diaTitle, setDiaTitle] = useState<string>("");
  const [diaContent, setDiaContent] = useState<string>("");


  useEffect(() => {
      let proItem = null;
      let quantity = undefined
      
      if (products !== undefined){
        if (copyOfProduct === undefined){
          setCopyOfProduct(products[0]);
        }
        
        proItem = Object.assign({}, products[0]);
//        quantity = props.licenseDescription?.licenseList.find(x=> x.licenseType === 1)?.hoists;
        
        if (props.licenseDescription !== undefined && props.licenseDescription?.licenseList.length > 0){
          quantity = props.licenseDescription?.licenseList[0].hoists;
        }
        
        if (quantity !== undefined){
          proItem.quantity = quantity;
        }

        if (props.licenseDescription !== undefined)
        {
          setCountLicenseHoists(proItem.quantity?.toString());
          setItemProduct(proItem);
        }

      }

  }, [props, products])


  const voucherOnchange = async (e:any, value?: string | undefined) => {
    const voucher = voucherInfo;
    removeBar();

    if (voucher !== undefined)
    {
        voucher.errorDescription = "";
        setvoucherInfo(voucher);
    }
    setSVoucher(value);


    let res = await getVoucher(value ?? "");
    if(res.ok)
    {
        const voucherInRes:IVoucherInfo = await res.json();
        setvoucherInfo(voucherInRes);
        if (voucherInRes.validVoucher)
        {
           setValidVoucher(true);
        }
        else
        {
            setValidVoucher(false);
        }
    }
    else
    {
    }
}

const validSubmit = useCallback(() : IValid => {
  let msg = "";
  let valid = true;
  let quantity = 0
  let show = false;
  let validVoucherStatus = false;

  //check valid voucher
  if (validVoucher === true && valueVoucher !== undefined && valueVoucher.length > 0)
  {
    validVoucherStatus = true;
  }


  quantity = copyOfProduct?.quantity !== undefined ? copyOfProduct?.quantity : 0;
  let count = props.licenseDescription?.licenseList.filter(x => x.licenseType === 1);
  let currentLicense = props.licenseDescription?.licenseList.filter(x => x.licenseType === 2);
  
  

  if (validVoucher === false && valueVoucher !== undefined && valueVoucher.length > 0 && valid === true)
  {
    msg = "Voucher not valid.";
    valid = false;
  }
  
  //check if try to downgarde
  if (currentLicense?.length === 1 && countLicenseHoists !== undefined && valid === true)
  {
    if (parseInt(countLicenseHoists) < currentLicense[0].hoists){
      msg = "Can't downgarde voucher.";
      valid = false;
    }
  }
  
  


  if (countLicenseHoists !== undefined  && valid === true)
  {
    if (parseInt(countLicenseHoists) < quantity){
      msg = "Can not be less than " +  quantity.toString();
      valid = false;
    }
    else if (parseInt(countLicenseHoists) === props.licenseDescription!.licensedHoists && validVoucherStatus !== true){
      msg = "No changed."
      valid = false;
    }
    else if (isNaN(countLicenseHoists as any)){
      msg = "Is not a number.";
      valid = false;
    }
  }
  
  if (count!.length === 2 &&  itemProduct?.quantity! > props.licenseDescription!.licensedHoists && valid === true){
       msg = "Can't upgrade when already have one downgrade.";
       valid = false;
  }


if (!valid)
{
  show =  true;
}

  return {msg: msg, valid: valid, show:show, saved: false};
},[valid, props, countLicenseHoists, validVoucher]);




const MsgBar = () => {
  let success =  <MessageBar key="license" messageBarType={MessageBarType.success}>{valid?.msg}</MessageBar>;
  let error =  <MessageBar key="license" messageBarType={MessageBarType.error}>{valid?.msg}</MessageBar>;
  let output =  null;

  if  (valid != undefined)
  {
   if (valid?.valid === true){
           output = success;
      }
      else if (valid?.valid === false)
      {
          output = error;
      }
  }

  return ( output )
} 


// onChangeButton
const cancelSubscription = () => {
  toggleHideDialog(); 
}

// cancel the substription
const actionCancelSubscription = async () => {
  let res = await cancelsubscription();
  if(res.ok)
  {
      const ret:boolean = await res.json();
      onRestart();
      props.refreshProp();
  }
  toggleHideDialog();
  refresh();
}

const actionCancelSubscriptionSave = async () => {
  setValid(statePrev => ({...statePrev, show:true, valid:true, msg:'Success, subscription process'}))
  onRestart();

  let res = await createPlan({product:itemProduct, voucher:voucherInfo} as IPlan);
  if(res.ok)
  {
      const saveStatus:boolean = await res.json();
      
      if(saveStatus === true)
      {
        setValid(prevState => ({...prevState, saved: true }));
        setRefreshLic(prevState => ({...prevState, refresh: true }));
        props.refreshProp();
        setvoucherInfo(undefined);
      }
  }

  toggleHideDialogSave();
}

// onChangeButton
const cancelSubscriptionSave = () => {
  toggleHideDialogSave(); 
}


const savePlan = async () => {
  let msg = "";
  let validObj = validSubmit();
  let voucerCodeStatus = false;
  setValid(validObj);
  let used = true;

  //check valid voucher
  if (validVoucher === true && valueVoucher !== undefined && valueVoucher.length > 0)
  {
    voucerCodeStatus = true
  }

  if (validObj.valid === true)
  {
    let count = props.licenseDescription?.licenseList.length;
    let currentLicenseVoucher = props.licenseDescription?.licenseList.filter(x => x.licenseType === 2);
  
    if (count === undefined){
      count = 0;
    }


    setDiaTitle("Subscription");
    
    if (count! <= 1 && itemProduct?.quantity! > props.licenseDescription!.licensedHoists){
      msg = "Add " + countLicenseHoists + " licenses to your subscription plan?";
    }
    else if ((count! <= 1 || count! === 2) && itemProduct?.quantity! < props.licenseDescription!.licensedHoists){
      msg = "Downgrade license to " + countLicenseHoists + "?";
    }


  // only first time when no license
  if (voucerCodeStatus && count === 0)
  {
    msg = "Add voucher code to start free trial + add " + countLicenseHoists + " licenses to your subscription plan?";
  }

  // only first time (added) has licnese
  if (voucerCodeStatus && count > 0)
  {
    msg = "Add voucher code to start free trial?";
  }




  //check if upgrade
  if (itemProduct?.quantity! > props.licenseDescription!.licensedHoists && currentLicenseVoucher?.length === 1){
    msg = "Upgrade voucher with " + countLicenseHoists + "?";
  }



    setDiaContent(msg);
    
    toggleHideDialogSave();
  }


}


      const textStyle = {
        root: {
          color: "rgba(51, 51, 51, 0.933)",
        }
      };
    
      const cardTokens = {
        boxShadow: "none",
        maxWidth: "415px",
    };
    
      const stackTokens = {
        childrenGap: 10
      };

    const LicenseList = () =>{
        return (
          <React.Fragment>
          {
            props.licenseDescription?.licenseList.map((item, index) => (
              <div key={index} style={row}>
                <div style={colUnderline}>{item.name}</div>
                <div style={colUnderline}>{dtf.format(new Date(item.start))} - {dtf.format(new Date(item.end))}</div>
                {/*item.name === "Voucher" ?
                    <div style={colUnderline}>{dtf.format(new Date(item.start))} - {dtf.format(new Date(item.end))}</div>
                    :
                    <div style={colUnderline}>Monthly Recurring</div>
            */}
                <div style={colUnderline}>{item.hoists}</div>
                <div style={colUnderline}><span style={item.expired === false ? active : expired}>{item.txtStatus}</span></div>
              </div>
            ))
          }
          </React.Fragment>
        );
    }  


    const calHoistsLeft = ():string => {
     let licenseHoists:number = props.licenseDescription !== undefined ? (props.licenseDescription.licenseList.length > 0 ? props.licenseDescription.licenseList[0].hoists : 0) : 0;
      let regHoists:number = props.licenseDescription !== undefined ? props.licenseDescription.regHoists : 0;
           
      return ( licenseHoists - regHoists ) + "/" + licenseHoists; 
    }

    const onRestart = () =>{
      if (props.onRestart != undefined)
      {
        props.onRestart();
      }
    }

    const modalProps = React.useMemo(
      () => ({
        titleAriaId: "tyest1",
        subtitleAriaId: "subTextId",
        isBlocking: false,
        styles: dialogStyles,
        
      }),
      [labelId, subTextId],
  );

const removeBar = () =>{
  setValid({msg: "", valid:undefined, show:false, saved: false});
}

    //New
    const onChangeField = (e:any, newValue?: string | undefined) => {
            setCountLicenseHoists( newValue );
            setItemProduct(item => ({...item, quantity: parseInt(newValue!) }));
            removeBar();
    }


    return (

      <div>
        {valid.show === true ? <MsgBar/> : null}

      <div style={subscriptionContainer}>
      <div style={contestContainer}>
        <Text variant="large" styles={textStyle}>Subscription</Text>
      </div>
      <br/><br/>
      <PanelForm>
          <PanelFormSection>
          <section style={sectionStyle}>
            <div style={row}>
                <div style={col}>Available licenses</div>
                <div style={col}>{calHoistsLeft()}</div>
            </div>
            <div style={row}>
                <div style={col}>Hoists</div>
                <div style={col}><TextField onChange={onChangeField} errorMessage={""} placeholder="Quantity of hoists" value={countLicenseHoists}/></div>
            </div>
            <div style={row}>
                <div style={col}>License plan</div>
                <div style={col}>{props.licenseDescription !== undefined ? props.licenseDescription.licensePlan : ""}</div>
            </div>
            <div style={row}>
                <div style={col}>Price per hoist</div>
                <div style={col}>{products !== undefined ? intl.formatNumber( Number(findPriceFromList(products, "123")), {style:'currency', currency: 'USD'}) : ""}</div>
            </div>
            <div style={row}>
                <div style={col}>Monthly amount</div>
                <div style={col}>{products !== undefined ? intl.formatNumber( Number(findPriceFromList(products, "123")), {style:'currency', currency: 'USD'}) : ""}</div>
            </div>
            {props.licenseDescription !== undefined && props.licenseDescription.voucherId === "" ?
            <div style={row}>
                <div style={col}>Voucher code</div>
                <div style={col}><TextField onChange={voucherOnchange} errorMessage={""} placeholder="Voucher code"/></div>
                {validVoucher === true ?
                  <div style={{height:"30px", marginLeft:"6px"}}><RowIcon iconName={"CompletedSolid"} style={{color: "green"}}/></div>
                  :
                  null
                }
            </div>
           : null}
            <div style={tableSpace}>
            </div>
                <div style={row}>
                    <div style={colUnderline}>Name</div>
                    <div style={colUnderline}>Period</div>
                    <div style={colUnderline}>Hoists</div>
                    <div style={colUnderline}></div>
                </div>
                <LicenseList/>
          </section>
          </PanelFormSection>



      <PanelFormSection>
        <section style={sectionStyle}>
          <div style={row}>
            
          { props.licenseDescription != undefined && props.licenseDescription.licenseList.length > 0 ?   
            <div><PrimaryButton styles={{}} onClick={savePlan}>Update Subscription</PrimaryButton>
            <DefaultButton styles={buttonStyles} onClick={cancelSubscription}>Cancel Subscription</DefaultButton></div>
            :
            <PrimaryButton styles={{}} onClick={savePlan}>Create Subscription</PrimaryButton>
         }


            
          </div>
        </section>
      </PanelFormSection>
      </PanelForm>
      </div>
        <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={actionCancelSubscription} text="Cancel" />
                <DefaultButton onClick={cancelSubscription} text="Don't cancel" />
            </DialogFooter>
        </Dialog>

        <Dialog hidden={hideDialogSave} onDismiss={toggleHideDialogSave} dialogContentProps={dialogContentPropsSave(diaTitle,diaContent)} modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={actionCancelSubscriptionSave} text="OK" />
                <DefaultButton onClick={cancelSubscriptionSave} text="CANCEL" />
            </DialogFooter>
        </Dialog>


      </div>

      )
  }

  const RenewInfo = (props?:ILicenseRenewProps):JSX.Element => {

    if (props?.licenseDescription !== undefined)
    {
        if (props.licenseDescription.licensePlan === "Recurring Billing"){
          return <div style={col}>Your license will automatucally renew : <strong style={bold}>{dtf.format(new Date(props.licenseDescription.renewDate))}</strong></div>
        }
        else
        {
          return <div style={col}>Your license will expire in <strong style={bold}>{props.licenseDescription.days > 1 ?  props.licenseDescription.days + ' days' : props.licenseDescription.days + ' day' }</strong></div>
        }
    }
    else
    {
      return <span></span>
    }
  }


  const dtf = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
});


const sectionStyle = {
  display: "table",
  width: "80%",
}

const row = {
  display: "table-row",
}

const col = {
  display: "table-cell",
  paddingBottom: "15px",
  width: "250px"
}

const colUnderline = {
  display: "table-cell",
  paddingBottom: "10px",
  paddingTop: "10px",
  borderBottom: "1px solid rgb(243, 242, 241)",
}

const active = {
  color : "rgba(16, 176, 67, 1)"
}

const expired = {
  color : "rgba(248, 7, 27, 1)"
}

const tableSpace = {
  marginTop: "40px"  
}

const bold = {
  fontWeight: 900
}

const buttonStyles = { root: { marginLeft: 8 } };

export default LicenseDescription2;