import React, { useCallback, useState, useMemo, useEffect } from "react";
import {  Checkbox, PrimaryButton, Label, TextField, Dialog, DialogFooter, DefaultButton, DialogType } from "office-ui-fabric-react";
import { getOptions, saveOption, getApikey, generateApikey } from "../../../api";
import { IApiKeys } from "../../../api";
import { useBoolean, useId } from '@uifabric/react-hooks'





import styled from "styled-components";

export interface IOption{
    id: string;
    optionType: string;
    optionValue: string;
    customerId: string
}

const buttonSpace = {
    marginRight: "5px",
  }

const Pane = styled.div`
    marginTop: "20px",
`;

const dialogStyles = { main: { maxWidth: "450px" } };

export const dialogContentProps = {
    type: DialogType.normal,
    title: 'Generate Api Key',
    closeButtonAriaLabel: 'Close',
    subText: 'Are you certain that you wish to generate a new key?',
};



const Settings = () =>{
    const [options, setOptions] = useState<IOption[]>();
    const [weightOption, setweightOption] = useState<boolean>(false);
    const [tagOption, setTagOption] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(true);
    const [apiKey, setsetApiKey] = useState<IApiKeys>();
    const labelId: string = 'dialogLabel';
    const subTextId: string = 'subTextLabel';
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);



const getOptionsAsync = async () => {
        let res = await getOptions();
        if(res.ok)
        {
            const retValues:IOption[] = await res.json();
            setOptions(retValues);
            initAlloptions(retValues);
        }
        else
        {
            console.log("Error from get options")
        }  
}


const getApikeyAsync = async () => {
    let res = await getApikey();
    if(res.ok)
    {
       //IApiKeys = res
       const retValues:IApiKeys = await res.json();
       setsetApiKey(retValues); 
    }
    else
    {
        console.log("get api key error");
    }
}

    useEffect(() => {
        if (reload === true){
            getOptionsAsync();
            getApikeyAsync();
            setReload(false);
        }
     }, [reload]);


const initAlloptions = async (optionJson:IOption[]) => {

    //Weight
    const weightOption = optionJson?.find(x => x.optionType.toLowerCase() === 'weight');
    setweightOption(weightOption === undefined ? false :  JSON.parse(weightOption.optionValue))
    //Tag
    const tagOption = optionJson?.find(x => x.optionType.toLowerCase() === 'tag');
    setTagOption(tagOption === undefined ? false :  JSON.parse(tagOption.optionValue))

}

const modalProps = React.useMemo(
    () => ({
      titleAriaId: "tyest1",
      subtitleAriaId: "subTextId",
      isBlocking: false,
      styles: dialogStyles,
      
    }),
    [labelId, subTextId],
);


const onChangeOption = async (optionname:string) => {

    let option = options?.find(x => x.optionType.toLowerCase() === optionname);

    if (option !== undefined)
    {
        option.optionValue = option.optionValue === 'true' ? 'false' : 'true';
    }
    else
    {
        option = {id:'0',optionType:optionname, optionValue:'true', customerId:'0'} as IOption;
    }


    let res = await saveOption(option);
    if(res.ok)
    {
    }
    else
    {
        console.log("Save "+ optionname +" option error")
    }
    
    setReload(true);
}




const onClickGenerateKey = async () => {
    toggleHideDialog();
}


const createKey = async() => {
    
    let res = await generateApikey();
    if(res.ok)
    {
       //IApiKeys = res
       const retValues:IApiKeys = await res.json();
       setsetApiKey(retValues); 
    }
    else
    {
        console.log("create api key error");
    }

    toggleHideDialog();
}


const cancelKey = () => {
    toggleHideDialog();
}


return(
    <>
        <div style={{marginTop:"20px",marginLeft:"20px"}}>
            <Label>Show estimated weight from hoists without scale :</Label>
            <Checkbox checked={weightOption} onChange={() => onChangeOption("weight")} />
        </div>
        <div style={{marginTop:"20px",marginLeft:"20px"}}>
            <Label>All users can create new tags :</Label>
            <Checkbox checked={tagOption} onChange={() => onChangeOption("tag")} />
        </div>
        <div style={{display:"Block"}}>
        <div style={{marginTop:"20px",marginLeft:"20px"}}>
            <Label>Generate API Key :</Label>
            <PrimaryButton style={buttonSpace} onClick={() => onClickGenerateKey()}>Generate Key</PrimaryButton>
            <span style={{width:"290px", display:"block"}}>
                <TextField label="Name" placeholder="" name="Name" value={apiKey?.user} readOnly={true}/>
                <TextField label="Key" placeholder="" name="Key" value={apiKey?.key} readOnly={true}/>
            </span>
        </div>
        </div>

        <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={() => createKey()} text="Create new Key" />
                <DefaultButton onClick={() => cancelKey()} text="Cancel" />
            </DialogFooter>
        </Dialog>
    </>






)




}



export default Settings;