import React, {useState, useRef} from "react";
import {getImageTextFromType} from "./../util/helper";


interface IImageType {
  type : number;
}

const ImageType = (props:IImageType) => {
    const [type, setType] = useState<string>(getImageTextFromType(props.type));

    return(
        <img src={type} alt="gh3+" style={{maxWidth: "250px"}} />
    )

}

export default ImageType;
