import React, { useEffect } from "react";

export function useOutsideClickCallback(callback: () => any, ref: React.RefObject<HTMLElement>)
{
    useEffect(() => {
        const onClick = (event: MouseEvent) => {
            if(ref.current !== null && (event.target as Element).contains(ref.current))
            {
                callback();
            }
        }

        window.addEventListener("click", onClick);
        return () => { window.removeEventListener("click", onClick); }
    }, [callback, ref]);
}