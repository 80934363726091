import React, {useEffect} from "react";
import { useState, useCallback } from "react";
import Stripe from "./StripeContainer";
import SubscriptionList, {Iproducts} from "./SubscriptionList";
import Voucher, { IVoucherInfo } from "./Voucher";
import ConfirmPlan, {} from "./ConfirmPlan"
import { PanelType, DialogType, Dialog, DialogFooter, Text, PrimaryButton, Panel, DefaultButton, CommandBar, IButtonProps, MessageBar,  MessageBarType, Spinner, SpinnerSize } from "office-ui-fabric-react";
import { useBoolean, useId } from '@uifabric/react-hooks'

import { getVoucher, createPlan, cancelsubscription } from "../../../api";
import { useAuthorizedFetch } from "./../../../hooks/useFetch";
import { useNavigate, Link } from "react-router-dom";
import LicenseDescription2, {ILicenseCollection, ISubscriptionDetails} from "./LicenseDescription2" 
import { buttonStyles, buttonCancelStyles, textStyle, linkStyle, contentContainer, chooseContainer, dialogContentProps, dialogStyles, contestContainer, subscriptionContainer} from "./SubscriptionStyle" 
import { substriptionAtom, chooseView, substriptionPageStep } from "./../../../atoms/license";
import { useRecoilState, useSetRecoilState } from "recoil";
import { IValid } from "./../../../atoms/license";



interface ISubscriptionStatus{
    showVoucher? : boolean;
    showVoucherDes? : boolean;
}

interface ISubscriptionInfo{
    validLicense : boolean;
}


interface IChoose{
    validLicense : boolean;
}

interface IPageView{
    valid? : boolean;
    step? : number;
    chooseType? : chooseView
}

interface IBarTitle {
    title : string;
    hideTitle : string;
}

const chooseBarTitle = ():IBarTitle => {
    return {title : "Enter your voucher code", hideTitle: "Choose a plan"};
}

export interface IPlan {
    product? : Iproducts;
    voucher? : IVoucherInfo;
}

function Subscription() {
    const [status, licenseDescriptionDetails, refresh] = useAuthorizedFetch<ISubscriptionDetails>("api/admin/subscription/GetSubscriptionDetails");
    const [voucherInfo, setvoucherInfo] = useState<IVoucherInfo>();
    const [itemProduct, setItemProduct] = useState<Iproducts>();

    //New setting
    const [validVoucher, setValidVoucher] = useState<boolean>(false);
    const [pageView, setPageView] = useRecoilState(substriptionPageStep);
    const [showMsg, setShowMsg] = useState<boolean | undefined>(false);



    const removeCoucher = () => {
        setValidVoucher(false);
        setvoucherInfo(undefined);
    }



    const onSubscriptionChange = (item:Iproducts) => {
        setItemProduct(item);
    }

   const overflowProps: IButtonProps = { ariaLabel: 'More commands' };







useEffect(() => {
    if (pageView.pagetype === "plans" && pageView.step === 3 && pageView.lincenseRefresh === true)
    {
        refresh(); 
    }
}, [pageView]);


const onRestart = () => {
    setShowMsg(true);
}

const refreshProp = () => {
    refresh();
}



    return( 
        <React.Fragment>
            <LicenseDescription2 title={'Subscription details'} licenseDescription={licenseDescriptionDetails} refreshProp={() => refreshProp()} onRestart={() => onRestart()} />
        </React.Fragment>
    )

}

const checkIfShowCancelButton = (list:ILicenseCollection[] | undefined):boolean =>{
    let showButton = false;

    if (list !== undefined)
    {
        showButton = true;

        list.forEach(element => {
            if (element.expired === true)
            {
                showButton = false;
            }

            if (element.name === "Voucher" && list.length === 1)
            {
                showButton = false;
            }
        });

        if (list.length === 0)
        {
            showButton = false;
        }    
    }
    return showButton;
}

export default Subscription;