import React, { useRef, useEffect, useCallback, useState} from "react"
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { Ilocations, locationsFilterList } from "./../../atoms/google";
import { useBoolean } from '@uifabric/react-hooks';
import { ModalHoist } from './../hoist/HoistModainfo';
import { useAuthorizedFetch } from "../../hooks/useFetch";
import { Hoist } from "./../../views/hoist/useLocationHoists";
import { Location, API_HOISTS } from "../../api";
import pin1 from "../../assets/pin1.png";
import pin2 from "../../assets/pin2.png";
import pin3 from "../../assets/pin3.png";
import pin4 from "../../assets/pin4.png";
import pin5 from "../../assets/pin5.png";


export interface IGoogleMapProps{
    center: google.maps.LatLngLiteral;
    zoom: number;
    locations: {
        lat: number;
        lng: number;
        hoist: string;
        status:number | undefined;
    }[];
    onClickMarker?: (hoistNo:string) => void;
    
}

export interface IGetlocationHoistInfo{
    hoistPostion: string;
    hoistAddress: string;
}

export interface IPosition{
    lag: string;
    lng: string;
}

const render = (status: Status) => {
    if (status === Status.FAILURE) return <div>Error</div>;
    return <></>;
  };

export const GoogleMapComponet = (props:IGoogleMapProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
    const [lFilterList, setLFilterList] = useRecoilState<string[]>(locationsFilterList);
    const navigate = useNavigate();
    

    const infoWindow = new google.maps.InfoWindow({
        content: "",
        disableAutoPan: true,
    });

    const icon = (status: number | undefined) => {
      let IconArr = [pin1,pin2,pin3,pin4,pin5]
      let outPutIcon = {url: IconArr[0], scaledSize: new google.maps.Size(25,40),};

      if (status !== undefined){
        outPutIcon = {url: IconArr[status-1], scaledSize: new google.maps.Size(25,40),};
      }

      return (outPutIcon)
    };
  

    const markers = props.locations.map((position, i) => {
      const marker = new google.maps.Marker({position,icon : icon(position.status)}) as google.maps.Marker;
      marker.set("Hoist", position.hoist);
    
      marker.addListener("click", () => {
      var zoom = map?.getZoom();
      var center = map?.getCenter();
      //console.log(zoom + " - " + center);

      props.onClickMarker!(marker.get("Hoist"));
    });

    return marker;
  },[props.locations]);


  const renderer = {
    render({ count, position }:{count:any, position:any}, stats:any) {

        const color = "#0000ff"; //count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";
        // create svg url with fill color
        const svg = window.btoa(`
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity=".6" r="70" />
            <circle cx="120" cy="120" opacity=".3" r="90" />
            <circle cx="120" cy="120" opacity=".2" r="110" />
        </svg>`);

        return new google.maps.Marker({
        label: { text: String(count), color: "rgba(255,255,255,0.9)", fontSize: "32px" },
        position,
        title: String(count) + " hoists",
        icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.maps.Size(45, 45),
        },
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      })
    }
  }

  
 // Add a marker clusterer to manage the markers.
 let markerClusterer =  new MarkerClusterer({map, markers, renderer, algorithm: new SuperClusterAlgorithm({maxZoom: 24}) });
 
  google.maps.event.addListener(markerClusterer, "click", function (mCluster:any) {    
    let arr = [] as Partial<string[]>;
    for (let i=0; i < mCluster.markers.length;i++){
      let position = mCluster.markers[i].position;
      arr.push(mCluster.markers[i].get("Hoist"));
    }
    setLFilterList(arr as string[])
    navigate('/hoists');
});

      
    useEffect(() => {
        if (ref.current) {
            let map = new window.google.maps.Map(ref.current, {zoom:props.zoom, center:{ lat: props.center.lat, lng: props.center.lng } });
              setMap(map);
// googlezoom
//              google.maps.event.addListener(map, 'drag', function() {
//                var zoom = map.getZoom();
//                var center = map.getCenter();
//                console.log(zoom + " - " + center);
//            });

              
        }
    },[]);
  
    return <div style={{height:"100%"}} ref={ref} id="map" />;
  }

  
  export const GoogleMap  = (props:IGoogleMapProps) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
    const [, allHoists, refresh] = useAuthorizedFetch<Hoist[]>(`${API_HOISTS}`);
    const [itemHoists, setItemHoists] = useState<Hoist>();
    const [itemNo, setItemNo] = useState<string>("");


    React.useEffect(() => {
      if (allHoists !== undefined && itemNo !== ""){
          let hoist = allHoists.find(x => x.hoistSerialNo === itemNo)
          setItemHoists(hoist);
      }
  },[allHoists,itemNo]);
    

    const onClickMarker = (hoistNo:string) => {
      setItemNo(hoistNo);
      toggleHideDialog();
    }

    const onReload = () => {
    }


   return( 
      <Wrapper apiKey={"AIzaSyB6bnmHKloFJNJABRXBdTduNuepdToHMqI"} render={render} language={"en"} libraries={['places']}>
        <GoogleMapComponet zoom={props.zoom} center={{lat: props.center.lat, lng: props.center.lng}} locations={props.locations} onClickMarker={(hoistNo:string) => onClickMarker(hoistNo)}></GoogleMapComponet>
        <ModalHoist isOpen={hideDialog} onDismiss={toggleHideDialog} hoistItem={itemHoists} onReload={() => onReload()}/>
      </Wrapper>      
   )

};