import React, { useEffect, useRef } from "react";
import ButtonPanel from "../../components/ui/ButtonPanel";
import { PanelForm, PanelFormSection, PanelFormFields } from "../../components/panelform/PanelForm";
import { TextField, MessageBarType } from "office-ui-fabric-react";
import { useAuthorizedFetch } from "../../hooks/useFetch";
import { API_USER, updateUserProfile } from "../../api";
import { UserProfileSchema, userProfileSchema } from "../../api/schema";
import { useFormik } from "formik";
import { useNotificationService } from "../../hooks/useNotificationService";
import { userInfoAtom } from "../../atoms/user";
import { useSetRecoilState } from "recoil";

export interface Props 
{
    open?: boolean;
    onDismiss?: () => void;
    
}

interface FormProps
{
    userProfile: UserProfileSchema;
    commandRef?: React.MutableRefObject<{submit: () => Promise<void>} | undefined>; 
    onDismiss?: () => void;
}

function Form(props: FormProps)
{
    const { userProfile, commandRef, onDismiss } = props;
    const { addNotification } = useNotificationService();
    const setUserProfile = useSetRecoilState(userInfoAtom);
    const { values, handleChange, submitForm } = useFormik({
        initialValues: userProfile,
        validationSchema: userProfileSchema,
        onSubmit: async (values: UserProfileSchema) => {
            const res = await updateUserProfile(values);
            if(res.ok)
            {
                addNotification("Your user profile details were updated.", MessageBarType.info);
                onDismiss?.();
                setUserProfile(v => ({...v!, ...values}));
            }
            else
            {
                addNotification("Something went wrong when updating you user profile", MessageBarType.error);
                onDismiss?.();
            }
        }
    });

    useEffect(() => {
        if(commandRef === undefined)
        {
            return;
        }

        commandRef.current = {
            submit: submitForm
        }
    }, [commandRef, submitForm]);

    return (
        <PanelForm>
            <PanelFormSection>
                <PanelFormFields>
                    <TextField label="Full Name" placeholder="Please provide your full name" name="fullName"
                        value={values.fullName} onChange={handleChange} />
                    <TextField label="Job Title" placeholder="Please provide your current job title" name="jobTitle"
                        value={values.jobTitle} onChange={handleChange} />
                </PanelFormFields>
            </PanelFormSection>
        </PanelForm>
    );
}

function EditProfilePanel(props: Props)
{
    const { open, onDismiss } = props;
    const [status, userProfile] = useAuthorizedFetch<UserProfileSchema>(`${API_USER}/profile`);
    const formRef = useRef<{ submit: () => Promise<void> }>();

    const isLoading = status !== "success";
    return (
        <ButtonPanel 
            isLoading={isLoading}
            isOpen={open}
            onDismiss={onDismiss}
            onPrimaryButtonClick={() => formRef.current?.submit() }
            headerText="Edit Profile">
            {
                (!isLoading && userProfile) ? (
                    <Form userProfile={userProfile} commandRef={formRef} onDismiss={onDismiss} />
                ) : null
            }
        </ButtonPanel>
    );
}

export default EditProfilePanel;