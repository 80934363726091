import { Query, limitsToRangeLabelsWeight, col } from "./utils";
import { IColumn,  } from "office-ui-fabric-react";
import { createChartComponent } from "./createChartComponent";

export interface Props
{
    query: Query;
}

const mapWeights = (data: { 
    [max: number]: number 
}) => ({
    //labels: limitsToRangeLabelsWeight([5, ...Object.keys(data).map(x => parseInt(x))].filter((_, i, a) => i < a.length - 1)),
    labels: limitsToRangeLabelsWeight(Object.keys(data).map(x => {return parseInt(x)})),
    values: Object.keys(data).map(x => data[parseInt(x)])
});




const columns : IColumn[] = [
    col("liftType4", "Modules", 50, 100, true),
    //col("displaySerialNo", "Name", 250, 300, true),
    col("displayName", "Name", 250, 300, true),
//    col("hoistSerialNo", "Hoist Serial Number"),
    col("to90", "< 90kg"),
    col("to150", "90kg - 150kg"),
    col("moreThan150", "> 150kg"),
]

const WeightPerActivation = createChartComponent(
    "/api/statistics/weight",
    mapWeights,
    "Weight pr lift",
    columns
);

export default WeightPerActivation;