import React, { useCallback, useState } from "react";
import ButtonPanel from "../../components/ui/ButtonPanel";
import { Text, Label, Stack, ShimmerElementType, Shimmer, TextField, Dialog, DialogFooter, DialogType, PrimaryButton } from "office-ui-fabric-react";
import styled from "styled-components";
import QRCode from "../../components/ui/QRCode";
import { useAuthorizedFetch } from "../../hooks/useFetch";
import { API_USER, userAuthenticatorEnable } from "../../api";
import { NeutralColors } from "@uifabric/fluent-theme/lib/fluent/FluentColors";
import { useSetRecoilState } from "recoil";
import { mfaAtom } from "../../atoms/user";

// import appStoreBadge from "../../../assets/app-store-badge.svg";
// import googlePlayBadge from "../../../assets/google-play-badge.png";

export interface Props
{
    open?: boolean;
    onDismiss?: () => void;
}


interface AuthenticatorKey
{
    uri: string;
    key: string;
}

const Code = styled.code`
    font-family: 'Courier New', Courier, monospace;
    font-size: 18px;
    padding: .4em;
    background-color: ${() => NeutralColors.gray30};
`;

interface ConfirmationDialogData
{
    visible: true;
    recoveryCodes: string[];
}

const options = { method: "POST" };

function ConfirmationDialog(props: { recoveryCodes: string[], onDismiss?: () => void })
{
    const { recoveryCodes, onDismiss } = props;

    return (
        <Dialog
            hidden={false}
            onDismiss={onDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: "Two-Factor Authentication Enabled",
                subText: "Your account is now protected. Please save the recovery codes below in a secure location in case you lose access to the authenticator app"
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 450 } },
            }}>

            <Stack horizontal>
                <Stack.Item grow={1}>
                    <Stack tokens={{childrenGap: "s1"}}>
                    {
                        recoveryCodes.filter((_, i, {length}) => i < length / 2).map(code => (
                                <Text key={code} variant="mediumPlus">{code}</Text>
                        ))
                    }                        
                    </Stack>
                </Stack.Item>
                <Stack.Item grow={1}>
                    <Stack tokens={{childrenGap: "s1"}}>
                    {
                        recoveryCodes.filter((_, i, {length}) => i >= length / 2).map(code => (
                            <Text key={code} variant="mediumPlus">{code}</Text>
                        ))
                    }                        
                    </Stack>
                </Stack.Item>
            </Stack>

            <DialogFooter>
                <PrimaryButton onClick={onDismiss}>Ok</PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}

function SetupAuthenticator(props: Props)
{
    const { open, onDismiss } = props;
    const [status, authenticator] = useAuthorizedFetch<AuthenticatorKey>(`${API_USER}/authenticator-key`, options);
    const [code, setCode] = useState("");
    const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialogData>();
    const setMfaEnabled = useSetRecoilState(mfaAtom);

    const onVerify = useCallback(() => {
        userAuthenticatorEnable(code)
            .then(async res => 
            {
                if(res.ok)
                {
                    const { recoveryCodes } = await res.json() as { recoveryCodes: string[] };
                    setMfaEnabled(true);
                    setConfirmationDialog({ visible: true, recoveryCodes});
                }
            })
        
    }, [code, setMfaEnabled]);


    if(confirmationDialog?.visible)
    {
        return <ConfirmationDialog recoveryCodes={confirmationDialog.recoveryCodes} onDismiss={onDismiss} />
    }

    return (
        <ButtonPanel
            isOpen={open}
            onDismiss={onDismiss}
            headerText="Two-Factor Authentication"
            primaryButtonDisabled={!/\d{6}/.test(code.replace(/-| /, ""))}
            onPrimaryButtonClick={onVerify}
            primaryButtonText="Verify"
        >
            <Stack tokens={{childrenGap: "l1"}}>
                <p>To use an authenticator app go through the following steps:</p>

                <Text variant="mediumPlus">1. Download an authenticator app</Text>

                <div>
                    <Label>Microsoft Authenticator</Label>
                    <Stack horizontal tokens={{childrenGap: "m"}}>
                        <a href="https://go.microsoft.com/fwlink/?Linkid=825072" target="_blank" rel="noopener noreferrer">Android</a>
                        <a href="https://go.microsoft.com/fwlink/?Linkid=825073" target="_blank" rel="noopener noreferrer">iOS</a>
                    </Stack>
                </div>

                <div>
                    <Label>Google Authenticator</Label>
                    <Stack horizontal tokens={{childrenGap: "m"}}>
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" target="_blank" rel="noopener noreferrer">Android</a>
                        <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank" rel="noopener noreferrer">iOS</a>
                    </Stack>
                </div>

                <Text variant="mediumPlus">2. Scan the QR code</Text>
                {
                    (status === "success" && authenticator) ? (
                        <>
                            <QRCode data={authenticator.uri} width="200" height="200" errorCorrectionLevel="medium" />
                            or enter this key
                            <Code>{authenticator.key}</Code>
                        </>
                    ) : (
                        <Shimmer width="200px" shimmerElements={[
                            { type: ShimmerElementType.line, height: 200, width: 200 }
                        ]} />
                )
                }

                <Text variant="mediumPlus">3. Verify Authenticator Code</Text>
                <p>Once you have scanned the QR code or input the key above, your two factor authentication app will provide you
                with a unique code. Enter the code in the confirmation box below.</p>

                <TextField placeholder="Enter code from authenticator app" value={code} onChange={(_, v) => setCode(v ?? "")} />
                
            </Stack>
        </ButtonPanel>
    );
}

export default SetupAuthenticator;