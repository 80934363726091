import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ChartOptions } from "chart.js";
import { ICardTokens, Card, ICardProps } from "@uifabric/react-cards";
import { Spinner, Text, Stack, ActionButton, IColumn, ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import { getFileTypeIconProps } from "@uifabric/file-type-icons";

import Chart from "./Chart";
import { useAuthorizedFetch } from "../../hooks/useFetch";
import produce from "immer";
import { device } from "../layout/device";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { theme } from "../../theme/guldmann";
import { apiCall } from "../../api";
import DetailsPanel from "./DetailsPanel";

const cardTokens: ICardTokens = { 
    childrenMargin: 12 ,
};

interface StyledCardProps extends ICardProps
{
    doubleWidth?: boolean;
}

const mqSmallScreen: string = `screen and (max-width: ${theme.breakpoint.sm})`;

const StyledCard = styled(({ doubleWidth, ...rest }: StyledCardProps) => <Card {...rest} />)`
    background-color: white;
    margin: 0.5em;
    height: 354px;

    width: ${props => props.doubleWidth ? "calc(572px + 1em)" : "286px"};
    max-width: ${props => props.doubleWidth ? "calc(572px + 1em)" : "286px"};
    @media screen and (max-width: ${props => props.theme.breakpoint.sm}) {
        width: 286px;
        max-width: 286px;
        // width: 100%;
        // max-width: 100%;
    }

    // @media screen and ${device.desktop} {
        width: ${props => props.doubleWidth ? "calc(572px + 1em)" : "286px"};
        max-width: ${props => props.doubleWidth ? "calc(572px + 1em)" : "286px"};
    // }
`;


export interface Props<T = any>
{
    className?: string;
    style?: React.CSSProperties;
    doubleWidth?: boolean;
    type: string;
    uri: string;
    title?: string;
    legend?: string;
    map: (response: T) => { labels: string[], values: number[] };
    colorscheme?: string;
    csvLink?: string;
    xlsxLink?: string;
    detailsLink?: string;
    detailsColumns?: IColumn[];
    noBorder?: boolean;
}

const chartOptions: ChartOptions = 
{
    plugins: {
        colorschemes: {
            scheme: "office.Blue6"
        }
    },
    legend: { position: "bottom" }
}

function ChartCard<T>(props: Props<T>)
{
    const { uri, title, map, colorscheme, type, legend, doubleWidth = false, csvLink, xlsxLink, detailsLink, detailsColumns, noBorder = false } = props;
    const [state, data] = useAuthorizedFetch<T>(uri);
    const [isSmallScreen] = useMediaQuery([mqSmallScreen]);
    const [detailsViewVisible, setDetailsViewVisible] = useState(false);
    
    const chartData = useMemo(() => {
        if(data === undefined || state !== "success")
        {
            return undefined;
        }

        const result = map(data);
        return {
            labels: result.labels,
            datasets: [{ label: legend, data: result.values }]
        };
    }, [data, map, legend, state]);

    const options = useMemo(() => produce(chartOptions, draft => {
        draft.plugins!["colorschemes"]!.scheme = colorscheme ?? "office.Blue6";
        draft.responsive = false;
    }), [ colorscheme ]);

    const chartWidth = 262  // base width
        * ((doubleWidth && !isSmallScreen) ? 2 : 1) // double width or not (ignored on smaller screens)
        + 16;

    const onDownload = (event: any, item?: IContextualMenuItem) => 
    {
        const dl = (item?.key === "csv") ? apiCall(csvLink!, "GET")
            : (item?.key === "xlsx") ? apiCall(xlsxLink!, "GET")
            : null;

        dl?.then<string>(res => res.text())
           .then(uri => window.open(uri));
    };

    const tokens = useMemo(() => ({
        ...(noBorder ? ({ boxShadow: "" }) : ({})),
        ...cardTokens
    }), [noBorder]);

    return (
        <>
        {
            (detailsViewVisible && detailsLink) ? (
                <DetailsPanel 
                    columns={detailsColumns}
                    title={title ?? ""}
                    url={detailsLink} 
                    visible={detailsViewVisible} 
                    onDismiss={() => setDetailsViewVisible(false)} />
            ) : null
        }
        <StyledCard tokens={tokens} doubleWidth={doubleWidth}>
            { (title) ? (
                <Card.Item>
                    <Text variant="medium">{title}</Text>
                </Card.Item>
            ) : null }
            <Card.Item fill>
            {
                (state === "success") ? (
                    <Chart type={type} width={chartWidth} height={252} options={options} data={chartData!} />
                ) : (state === "http_error" || state === "transport_error") ? (
                    <span>An error occurred.</span>
                ) : (
                    <Spinner label="Fetching data..." />
                )
            }
            </Card.Item>
            <Card.Section horizontal tokens={{ padding: "0px 0px 0px" }} styles={{ root: { borderTop: '1px solid #F3F2F1', height: 35 } }}>
            {
                (csvLink) ? (
                    <>
                        <ActionButton iconProps={{ iconName: "table" }} onClick={() => setDetailsViewVisible(true)}></ActionButton>
                        <Stack.Item grow={1}>
                            <span />
                        </Stack.Item>
                        <ActionButton 
                            menuProps={{ 
                                onItemClick: onDownload,
                                items: [{
                                    key: "header",
                                    text: "Download",
                                    itemType: ContextualMenuItemType.Header,
                                },{
                                    key: "xlsx",
                                    text: "Excel-file",
                                    iconProps: getFileTypeIconProps({ extension: "xlsx", size: 32 }),
                                },{
                                    key: "csv",
                                    text: "CSV-file",
                                    iconProps: getFileTypeIconProps({ extension: "csv", size: 32 }),
                                }] }} 
                            onRenderMenuIcon={() => null}
                            iconProps={{ iconName: "download" }} />
                    </>
                ) : null
            }
            </Card.Section>
        </StyledCard>
        </>
    )
}

export default ChartCard;