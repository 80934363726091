import React, { useCallback } from "react";
import styled from "styled-components";
import { Text, Icon, getTheme } from "office-ui-fabric-react";

export interface Props
{
    pages: number;
    currentPage: number;
    onPageClick?: (n: number) => void;
}

const repeat = <T extends any>(from: number, to: number, callback: (n: number) => T): T[] =>
{
    let result: T[] = [];
    for(let n = from; n <= to; n++)
    {
        result.push(callback(n));
    }
    return result;
}

const theme = getTheme();
const PageInner = styled.li<{current?: boolean}>`
    display: inline-block;
    max-height: 1rem;
    min-width: 1rem;
    padding: .4rem;
    text-align: center;
    
    user-select: none;
    cursor: ${props => props.current ? "inherit" : "pointer"};

    line-height: 1rem;

    &:hover {
        background-color: ${props => props.current ? "inherit" : theme.palette.themeLighterAlt};
    }

    span { font-weight: ${props => props.current ? "bold" : "inherit"}; }
    /* ${prop => prop.current ? `background-color: ${theme.palette.themeLighter}` : ""} */
`;


const Bar = styled.ol`
`;

function Page(props: { current?: boolean, n: number, onClick?: (n: number) => void })
{
    const { current, n, onClick } = props;
    const _onClick = useCallback(() => onClick?.(n), [onClick, n]);
    return <PageInner current={current} onClick={_onClick}><Text>{n}</Text></PageInner>;
}

function PagingBar(props: Props)
{
    const { pages, currentPage, onPageClick } = props;

    const from = currentPage - (currentPage % 10) + 1;
    const to = Math.min(from + 10, pages);
    
    const onPrevClick = () => onPageClick?.(Math.max(1, currentPage - 1));
    const onNextClick = () => onPageClick?.(Math.min(pages, currentPage + 1));

    return (
        <Bar>
            <PageInner onClick={onPrevClick}><Icon iconName="ChevronLeft" style={{ fontSize: 11 }}/></PageInner>
        {
            repeat(from, to, n => (
                <Page key={n} current={n === currentPage} n={n} onClick={onPageClick} />
            ))
        }
            <PageInner onClick={onNextClick}><Icon iconName="ChevronRight" style={{ fontSize: 11 }}/></PageInner>
        </Bar>
    );
}

export default PagingBar;