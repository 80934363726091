import React, {useState, useRef, useEffect, useReducer} from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import { IconButton, IIconProps, IButtonStyles, getTheme } from "office-ui-fabric-react";
import { useUserRoles } from "../../api-authorization/useUserRoles";
import { IOption } from "./../../views/admin/settings/Settings";
import { useAuthorizedFetch } from "./../../hooks/useFetch";


export interface ITags {
  id: string;
  text: string;
}



export interface ISaveTags {
  name: string;
  description: string;
  trainingId: number;
}

export interface IProps {
  close: () => void;
  bounding?: IBounding;
  tags?: ITags[];
  suggestionsTags?: ITags[];
  saveTag: (saveTags:ISaveTags) => void;
  item?: ITrainingData;
  savedTags?: ITags[];
  onDelete: (tag:ITags, trainingId:number) => void;
  reloadData: () => void;
}

const KeyCodes = {
  comma: 188,
  enter: 13,
};

/*const suggestions = COUNTRIES.map((country) => {
  return {
    id: country,
    text: country,
  };
});*/

const delimiters = [KeyCodes.comma, KeyCodes.enter];

// ***********************************************



export interface IBounding {
  top : number | undefined;
  bottom : number | undefined;
  left : number | undefined;
  right : number | undefined;
  height : number | undefined;
  width : number | undefined;
}

export interface ITrainingData {
  trainingId : number;
  tags: string;
}


interface ITagContainerProps {
  open : (bounding:IBounding, item : ITrainingData) => void;
  item : ITrainingData;
}

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: '265px',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const tagStyle = (tagsList:string) => {
  const listTags:string[] = tagsList.split(',');
  const count = listTags.length;
  const more = count >= 1 && count <= 4 ? "" : "...";
  
  let data: JSX.Element[];
  if (tagsList !== ""){
    data = listTags.slice(0,4).map((item) => { return <div className="training-react-tags">{item}</div>});
  }
  else{
    data = [<div className="training-react-tags">Add new tag</div>];
  }

  return (<>{data}<span>{more}</span></>)
}


export const TagContainer = (props:ITagContainerProps) => {
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const item = props.item as ITrainingData;  
  
  const getContainerBounding = () => {
      props.open({ 
          top: tagContainerRef.current?.getBoundingClientRect().top,
          bottom: tagContainerRef.current?.getBoundingClientRect().bottom,
          left: tagContainerRef.current?.getBoundingClientRect().left,
          right: tagContainerRef.current?.getBoundingClientRect().right,
          height: tagContainerRef.current?.getBoundingClientRect().height,
          width:  tagContainerRef.current?.getBoundingClientRect().width,
      },props.item);
  }


  return (
      <div ref={tagContainerRef} style={{cursor:"pointer", minHeight:"16px"}} onClick={() => getContainerBounding()}>{item.tags === "" ? tagStyle('') : tagStyle(item.tags) }</div>
  )
}


const checkSuggestionsTagsExist = (sugges:ITags[] | undefined, searchTxt:string):boolean => {

  const tag = sugges?.filter(x => x.text === searchTxt);

 if(tag !== undefined && tag?.length > 0){
  return true;
 }
 else
 {
  return false;
 }
}


const TagPopUp = (props:IProps) => {
  const left = props.bounding?.left! - 300 - 10;
  const top = props.bounding?.top! -10 ;
  const divRef = useRef<HTMLDivElement>(null);
  const [tags, setTags] = React.useState<ITags[] | undefined>();
  const [suggestionsTags, setSuggestionsTags] = React.useState<ITags[] | undefined>();
  const roles = useUserRoles();
  const [status, optionValue, refresh] = useAuthorizedFetch<IOption>("api/setting/GetOption/tag");
  const [canSaveTag, setCanSaveTag] = useState<boolean | undefined>(undefined);

  // training data list  
  useEffect(() => {
    const callback = async () => {
     setTags(props.savedTags);
  }
    callback();
  }, [props.savedTags]);


  // Suggestions data list
  useEffect(() => {
    const callback = async () => {

    var uniqueResultArrayObjOne = props?.suggestionsTags?.filter(function(objOne) {
      return !props.savedTags?.some(function(objTwo) {
          return objOne.id == objTwo.id;
      });
    });

    setSuggestionsTags(uniqueResultArrayObjOne);
  }
    callback();
  }, [props?.suggestionsTags]);


  // Mouse click event  
  useEffect(() => {
    const handleClickOutside = (event:Event) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        props.close();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  const handleDelete = (i:number) => {
    const item = tags?.filter((tag, index) => index === i)

    if (item !== undefined){
      props.onDelete(item[0],props.item?.trainingId!);
    }
  };

  const handleAddition = (tag:ITags) => {
    let canSave = false;
    const tagExist = checkSuggestionsTagsExist(suggestionsTags,tag.text);

    if(tagExist === true){
        canSave = true;
    }
    else {
      if (status === "success"){
        if (optionValue?.optionValue !== undefined && optionValue?.optionValue !== "")
        {
          if (optionValue?.optionValue === "true"){
            canSave = true;
          }        
        }
      } 

      if(roles?.includes("Administrator")){
        canSave = true;
      }
      
    }

    if (canSave === true){
      setCanSaveTag(true);
      props.saveTag({name:tag.text, description:tag.text, trainingId: props.item?.trainingId!});
    }
    else{
      setCanSaveTag(false);
    }

  };

  const handleDrag = (tag:ITags, currPos:number, newPos:number) => {
    const newTags = tags?.slice();
    newTags?.splice(currPos, 1);
    newTags?.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index:number) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

    return(
      <div className="popup-container" style={{left:left, top:top}} ref={divRef}>
      <div className="popup-body">
      
      <IconButton
        styles={iconButtonStyles}
        iconProps={cancelIcon}
        ariaLabel="Close popup modal"
        onClick={() => props.close()}
      />


      <ReactTags
          tags={tags}
          suggestions={suggestionsTags}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleTagClick={handleTagClick}
          inputFieldPosition="bottom"
          minQueryLength={1}
          autocomplete={true}
          allowDragDrop={false}
      />
      <div>{canSaveTag}</div>
      {canSaveTag === false ?
      <div style={{color:"red"}}>Only administrators can create a new tag.</div>: null
      }
      </div>
    </div>
    )
}

export default TagPopUp;