import React, { useMemo, useCallback } from "react";
import { Location } from "../../../api";
import { useLocationMenu } from "../../../hooks/useLocationMenu";
import { IContextualMenuItem, DefaultButton, IButtonStyles, ContextualMenuItemType } from "office-ui-fabric-react";
import { findByKey } from "../../../util/helper";

interface LocationSelectorProps
{
    name?: string;
    value?: Location["id"];
    onChange?: (locationId: Location["id"]) => void;
    disabledLocationIds?: Location["id"][];
    disabled?: boolean;
}

const buttonStyles: IButtonStyles = { root: { paddingLeft: 8, paddingRight: 8 }, label: { textAlign: "left", fontWeight: "normal" }};

function LocationSelector(props: LocationSelectorProps)
{
    const { name, value, onChange, disabledLocationIds = [], disabled = false } = props;
    const onClick = useCallback((_: any, item?: IContextualMenuItem) => {
        if(item)
        {
            if(item.key === "-1")
            {
                if(!disabledLocationIds.includes(-1))
                {
                    onChange?.(-1);
                }
            }
            else
            {
                const loc = item.data as Location;
                if(!disabledLocationIds.includes(loc.id))
                {
                    onChange?.(loc.id);
                }
            }
        }
    }, [onChange, disabledLocationIds]);

    // get location menu from server and compute derived items with "all locations"
    const _items = useLocationMenu([], { onClick }, disabledLocationIds) ?? [];
    const items = useMemo<IContextualMenuItem[]>(() => [
        { key: "-1", text: "All locations", onClick, disabled: disabledLocationIds.includes(-1) },
        { key: "divider_1", itemType: ContextualMenuItemType.Divider },
        ..._items
    ], [_items, onClick, disabledLocationIds]);

    const label = useMemo(() => {
        if(value !== undefined)
        {
            const item = findByKey(value.toString(), items, item => item.subMenuProps?.items ?? []);
            return item?.text;
        }
    }, [value, items]);
    
    return (
        <DefaultButton 
            disabled={disabled}
            name={name}
            value={value}
            styles={buttonStyles} 
            menuProps={{ isBeakVisible: false, items }}>
            {
                label ?? "Select location"
            }
        </DefaultButton>
    );
}

export default LocationSelector;