import React, { Component, useState, useEffect, useCallback } from "react";
import {PanelForm, PanelFormSection, PanelFormFields } from "../../components/panelform/PanelForm";
import logo from "../../assets/logo_g2.png";
import {  Link } from "office-ui-fabric-react";
import { getversion } from "../../api";


export interface Props 
{
    download: () => void;
}

export interface IAbout 
{
    buildversion: string;
}



function About(props: Props)
{
    const { download } = props;
    const [buildversion, setBuildversion] = useState<string>();


    useEffect(() => {
      const callback = async () => {
        let res = await getversion();
        if(res.ok)
        {
          const ret:IAbout = await res.json();
          setBuildversion(ret.buildversion);
        }
        else
        {
        }  
      };
      
      callback();
      
  }, []);




    return (

        <PanelForm>
         <PanelFormSection>
          <div style={row}>
               <div style={colNoSwap}><img src={logo} height="25px"></img></div>
               <div style={headertxt}>CLM Online</div>
           </div>
           <div style={tableSpace}></div>
         <section style={sectionStyle}>
           <div style={row}>
               <div style={col}>Build version</div>
               <div style={col}>{buildversion}</div>
           </div>
           <div style={row}>
               <div style={col}>Support e-mail</div>
               <div style={col}><Link href="mailto:clmsupport@guldmann.com">Contact us</Link></div>
           </div>
           <div style={row}>
               <div style={col}>Download user guide</div>
               <div style={col}><Link onClick={() => download()}>Download</Link></div>
           </div>
           <div style={tableSpace}></div>
         </section>
         </PanelFormSection>
     


     <PanelFormSection>
       <section style={sectionStyle}>
         <div style={row}>
           {/*<PrimaryButton onClick={onRestart} styles={buttonStyles}>E-mail us</PrimaryButton>*/}
         </div>
       </section>
     </PanelFormSection>



     </PanelForm>




    );
}


const sectionStyle = {
    display: "table",
    width: "100%",
  }
  
  const row = {
    display: "table-row",
  }
  
  const col = {
    display: "table-cell",
    paddingBottom: "10px"
  }

  const colNoSwap = {
    display: "table-cell",
  }
  const headertxt = {
    display: "table-cell",
    paddingLeft: "10px",
    fontSize: "25px",
  }


  const colUnderline = {
    display: "table-cell",
    paddingBottom: "10px",
    paddingTop: "10px",
    borderBottom: "1px solid rgb(243, 242, 241)",
  }
  
  const active = {
    color : "rgba(16, 176, 67, 1)"
  }
  
  const expired = {
    color : "rgba(248, 7, 27, 1)"
  }
  
  const tableSpace = {
    marginTop: "40px"  
  }
  
  const bold = {
    fontWeight: 900
  }

export default About;