import React, {useState, useRef} from "react";
import { useRecoilValue } from "recoil";
import { getTheme, mergeStyleSets, FontWeights, Modal, IStackProps, Checkbox } from "office-ui-fabric-react";
import { authenticatedAtom, mfaAtom, userInfoAtom } from "../atoms/user";
import { useAuthorizedFetch } from "./../hooks/useFetch";
import ReactHtmlParser from 'react-html-parser';
import { saveAgreEeula } from "../api";
import { FaPrint } from "react-icons/fa"

interface IEula {
  content : string;
  agree : boolean;
}

const Eula = () => {
    const [modal, setModal] = useState(true);
    const userInfo = useRecoilValue(userInfoAtom);
    const [eulaStatus, eula, refresh] = useAuthorizedFetch<IEula>("api/eula/geteula");
    const printRef = useRef<HTMLDivElement>(null);


    const onChange = async () =>{
      let res = await saveAgreEeula();
      if(res.ok)
      {
          const ret:boolean = await res.json();
      }
      refresh();
    }


    const print = ()  =>{
      if (printRef.current !== null){
        window.frames[0].document.title = "End-User License Agreement (EULA)";
        window.frames[0].document.body.innerHTML = printRef.current.innerHTML;
        window.frames[0].window.focus();
        window.frames[0].window.print();
      }
    }
    

    return (
      <React.Fragment>
          <span>
              {eula !== undefined && eula.agree === false ?
                <Modal isOpen={!eula?.agree} containerClassName={contentStyles.container}>
                <div ref={printRef}>
                  <div className={contentStyles.header}>
                      <span id={"eula-title"}>End-User License Agreement (EULA)</span>
                  </div>
                  <div className={contentStyles.body} id='PrintContent'>
                  <embed src="/api/eula/eula" width="900px" height="600px" />
                      {/*eula != undefined ? ReactHtmlParser(eula.content) : null*/}          
                  </div>
                  </div>
                  <div style={actionBox}>
                        <div style={agree}><Checkbox label="I AGREE" onChange={onChange} /></div>
                        {/*<div onClick={print} style={printbox}><FaPrint size="20" title="Service" color={"#000000"} /></div>
                        <label style={printLabel} onClick={print}>PRINT</label>*/}
                      </div>

                </Modal>
              : null}
            </span>
            <iframe src="about:blank" style={printdisplay}></iframe>
      </React.Fragment>

        )}

        const theme = getTheme();


        const printbox = {
          marginLeft : '15px',
          cursor: 'pointer',
        }           

        const agree = {
          paddingBottom: '10px'
      }

        const printLabel = {
            marginLeft : '5px',
            cursor: 'pointer',
            paddingTop: '3px'
        }


        const printdisplay = {
          display: "none",
        }
        
        const actionBox = {
          display: 'flex',
          marginLeft : '25px'
        }

        const contentStyles = mergeStyleSets({
          container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            marginLeft:"100px",
            marginRight:"100px",
            hight: "600px"
          },
          header: [
            theme.fonts.large,
            {
              flex: '1 1 auto',
              borderTop: `4px solid ${theme.palette.themePrimary}`,
              color: theme.palette.neutralPrimary,
              display: 'flex',
              alignItems: 'center',
              fontWeight: FontWeights.semibold,
              padding: '12px 12px 14px 24px',
            },
          ],
          body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
              p: { margin: '14px 0' },
              'p:first-child': { marginTop: 0 },
              'p:last-child': { marginBottom: 0 },
            },
          },
        });

        const stackProps: Partial<IStackProps> = {
          horizontal: true,
          tokens: { childrenGap: 40 },
          styles: { root: { marginBottom: 20 } },
        };

  export default Eula;