import React from "react";
import { IContextualMenuItem, ContextualMenuItemType, Modal } from "office-ui-fabric-react";
import { getDate, addDays } from "../../util/date";
import { useState } from "react";
import { FilterPill } from "../../components/filter/FilterPill";
import { DateRangePicker } from "./DateRangePicker";

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;

    // initialDateRange?: DateRange;
    onDateRangeChange?: (dateRange: DateRange) => void;
}

export interface DateRange
{
    from: Date;
    to: Date;
}

interface TimespanMenuItem extends IContextualMenuItem
{
    key: TimespanKeys;
}

const timespanMenu: TimespanMenuItem[] = [
    {
        key: "yesterday",
        text: "Yesterday",
    },
    {
        key: "week",
        text: "Last 7 days",
    },
    {
        key: "fortnight",
        text: "Last 14 days",
    },
    {
        key: "month",
        text: "Last 30 days",
    },
    {
        key: "divider_1",
        itemType: ContextualMenuItemType.Divider
    },
    {
        key: "current_month",
        text: "Current month",
    },
    {
        key: "prev_month",
        text: "Previous month",
    },
    {
        key: "divider_2",
        itemType: ContextualMenuItemType.Divider
    },
    {
        key: "custom",
        text: "Custom...",
    }
];

type TimespanKeys = "today" |"yesterday" | "week" | "fortnight" | "month" | "current_month" | "prev_month" | "custom" | "divider_1" | "divider_2";

function getDateRange(key: TimespanKeys): DateRange
{
//    const now = getDate(new Date());
    const now = new Date();
    // const now = getDate(new Date(2020, 0, 14)); 
    // console.warn("Using fake current date: " + now); // TODO - remove fake date
    const to = now;
    switch(key)
    {
        case "today": return { from: now, to: now };
        case "yesterday": return { from: addDays(now, -1), to: addDays(now, -1) }
        case "week": return { from: addDays(now, -7), to }
        case "fortnight": return { from: addDays(now, -14), to };
        case "month": return { from: addDays(now, -30), to };
        case "current_month": return { from: new Date(now.setDate(1)), to : new Date(now.getFullYear(), now.getMonth()+1, 0)}
        case "prev_month":  {
            let lastMonth = now.getMonth() - 1;
            let year = now.getFullYear();
            if(lastMonth < 0) 
            {
                lastMonth = 11;
                year--;
            }
            const from = new Date(year, lastMonth, 1);
            const to = addDays(new Date(now.getFullYear(), now.getMonth(), 1), -1);
            return { from, to }
        }
        case "divider_1": 
        case "divider_2": 
        default:
            throw new Error("");
    }
}

const initialValue = timespanMenu[1];

var dtf = new Intl.DateTimeFormat();


function TimespanPill(props: Props)
{
    const [timespan, setTimespan] = useState(initialValue);
    const [customDialogVisible, setCustomDialogVisible] = useState(false);
    
    const onItemClicked = (item: IContextualMenuItem) => 
    {
        if(item.key === "custom")
        {
            setCustomDialogVisible(true);
            return;
        }

        setTimespan(item as TimespanMenuItem);
        props.onDateRangeChange?.(getDateRange((item as TimespanMenuItem).key));
    }

    const onCustomRange = ((from: Date, to: Date) => 
    {
        const item: TimespanMenuItem = {
            key: "custom",
            text: `${dtf.format(from)} - ${dtf.format(to)}`
        };

        setTimespan(item);
        props.onDateRangeChange?.({ from, to });
    });

    return (
        <>
            <FilterPill label="Timespan" 
                menu={timespanMenu} 
                text={timespan.text!} 
                onItemClicked={onItemClicked} />
            
            <Modal isOpen={customDialogVisible} 
                   isClickableOutsideFocusTrap={true}>
                <DateRangePicker 
                    onDismiss={() => setCustomDialogVisible(false)}
                    onAccept={onCustomRange}
                />
            </Modal>
        </>
    );
}

TimespanPill.initialValue = getDateRange(initialValue.key);

export default TimespanPill;