import React, { useState, useCallback, useEffect } from "react";
import { TextField, MessageBar, MessageBarType, Checkbox, Stack, Text, ProgressIndicator, themeRulesStandardCreator } from "office-ui-fabric-react";
import { PanelForm, PanelFormSection, PanelFormFields } from "../../components/panelform/PanelForm";
import { adminRegisterHoists } from "../../api";
import { useSetRecoilState } from "recoil";
import { notifications } from "../../atoms/notifications";
import * as uuid from "uuid";
import ButtonPanel from "../../components/ui/ButtonPanel";
import { useRecoilValue, useRecoilState } from "recoil";
import { licenseAtom } from "../../atoms/license";
import { string } from "yup";
import { Link, NavLink } from "react-router-dom";
import { hoistsErrors } from "../../atoms/hoistsError";


export interface Props
{
    open?: boolean;
    onDismiss?: () => void;
    onDetails?: () => void;
}


interface RegisterResult
{
    succeeded: string[];
    failed: IHoistErrors[];
}

export interface IHoistErrors
{
    displaySerial : string;
    internalSerial : string;
    msg : string;
}


type hoistErrorsTypes = "unknow" | "notexists" | "already" | "nolicense";


const filledLines = (v: string): number => v.split("\n").filter(x => x.trim() !== "").length;

const hoistErrorsMessage = (error:string): string => {

    if (error as hoistErrorsTypes === "unknow"){
        return("");
    }
    else if (error as hoistErrorsTypes === "notexists"){
        return("");
    }
    else if (error as hoistErrorsTypes === "already"){
        return("");
    }
    else if (error as hoistErrorsTypes === "nolicense"){
        return("");
    }
    else {
        return("");
    }
}


function RegisterHoistPanel(props: Props)
{
    const { open, onDismiss } = props;
    const [busy, setBusy] = useState(false);
    const license = useRecoilValue(licenseAtom);
    const [serialNumbers, setSerialNumbers] = useState("");
    const [acknowledged, setAcknowledged] = useState(false);
    //const setMessages = useSetRecoilState(notifications);
    const [messages, setMessages] = useRecoilState(notifications);


    const [hoistsError, setHoistError] = useRecoilState(hoistsErrors);
    
    const serialNumbersCount = filledLines(serialNumbers);
    const availableLicenses = license.details ? (license.details.licensedHoists - license.details.totalHoists) : 0;

    const errorDetailsPanel = () => {
        if (props.onDetails !== undefined)
        {
            props.onDetails();
        }
    }

    const onChange = useCallback((_, v?: string) => 
    {
        if(v && license.details && filledLines(v) > availableLicenses)
        {
            const lines = v.split("\n");
            while(filledLines(lines.join("\n")) > availableLicenses && lines.length > 0)
            {
                lines.pop();
            }
            lines.push("");
            v = lines.join("\n");
        }
        setSerialNumbers(v ?? "")
    }   , [availableLicenses, license.details]);
    const onSave = useCallback(() => {
        setMessages([]);

        const hoists = serialNumbers.split(/(\n\r*)+/)
            .map(s => s.trim())
            .filter(s => s !== "");

        setBusy(true);
        adminRegisterHoists(hoists)
            .then(res => res.json())
            .then((result: RegisterResult) => {
                onDismiss?.();
                if (result.succeeded.length > 0) {
                    setMessages(v => [...v, {
                        id: uuid.v1(),
                        messageBarType: MessageBarType.success,
                        children: <span>Successfully registered {result.succeeded.length} new hoist{result.succeeded.length > 1 ? "s" : ""}. </span>
                    }]);
                }

                if (result.failed.length > 0) {
                    setHoistError(result.failed);
                    setMessages(v => [...v, {
                        id: uuid.v1(),
                        messageBarType: MessageBarType.error,
                        children: <span>Failed to register {result.failed.length} hoist{result.failed.length > 1 ? "s" : ""}. <Link to="/hoists" onClick={() => errorDetailsPanel()}>Click here for more details</Link></span>
                    }]);
                }

                if (result.failed.length === 0 && result.succeeded.length === 0)
                {
                    setMessages(v => [...v, {
                        id: uuid.v1(),
                        messageBarType: MessageBarType.info,
                        children: <span>No new hoists were registered.</span>
                    }]);
                }
            })
            .finally(() => setBusy(false));
    }, [onDismiss, serialNumbers, setMessages]);


    return (
        <ButtonPanel
            headerText="Register Hoist"
            primaryButtonText="Register"
            onPrimaryButtonClick={onSave}
            primaryButtonDisabled={busy || !acknowledged || serialNumbers.trim().length === 0}
            secondaryButtonDisabled={busy}
            isOpen={open}
            onDismiss={onDismiss}>

            { busy ? <ProgressIndicator/> : null}
            

            <PanelForm>
                <PanelFormSection>
                    <p>
                        Please provide the serial number as visible on the hoist in the input field below. You can 
                        multiple serial numbers by supplying one serial number per line.
                    </p>
                </PanelFormSection>
                <PanelFormSection>
                    <p>
                        <strong style={{fontWeight: "bold"}}>Note:</strong> It is not possible to register a hoist more than once or register hoists that
                        are associated with a different CLM account. Newly installed hoists might not be available for registration until they have been in
                        use.
                    </p>
                </PanelFormSection>
                <PanelFormSection>
                    <PanelFormFields>
                        <TextField multiline value={serialNumbers} onChange={onChange} rows={15} disabled={busy} />
                    </PanelFormFields>
                </PanelFormSection>
                {
                    (license.details !== undefined) ? (
                        <PanelFormSection>
                            <Stack horizontal tokens={{childrenGap: 8}}>
                                <Stack grow={1}  tokens={{childrenGap: 4}}>
                                    <Text>Available Licenses</Text>
                                    <Text>Registered Hoists</Text>
                                </Stack>
                                <Stack grow={1}  tokens={{childrenGap: 4}}>
                                    <Text>
                                    {
                                        (serialNumbersCount > 0) ? (
                                            <span>
                                                <span style={{textDecoration: "line-through"}}>{availableLicenses}</span>
                                                <span> {availableLicenses - serialNumbersCount}</span> 
                                            </span>
                                        ) : (
                                            <span>
                                                <span>{availableLicenses}</span>
                                            </span>
                                        )
                                    } / {license.details.licensedHoists}
                                    </Text>
                                    <Text>{license.details.totalHoists}</Text>
                                </Stack>
                            </Stack>
                        </PanelFormSection>
                    ) : null
                }
                <PanelFormSection>
                    <MessageBar messageBarType={MessageBarType.warning}>
                        <p>
                            When registering hoists for use in Guldmann CLM you are acknowleding that you
                            are the legal owner of said hoists. By supplying serial numbers of hoists that
                            you are not the legal owner you are voilating the terms of service.
                        </p>
                    </MessageBar>
                </PanelFormSection>
                <PanelFormSection>
                    <PanelFormFields>
                        <Checkbox 
                            label="I acknowledge that I am the legal owner of the above hoists" 
                            disabled={busy}
                            checked={acknowledged} onChange={(_, v = false) => setAcknowledged(v)} />
                    </PanelFormFields>
                </PanelFormSection>
            </PanelForm>
        </ButtonPanel>
    );
}

export default RegisterHoistPanel;