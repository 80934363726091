import React, { useState, useCallback } from "react";
import { ICommandBarItemProps, CommandBar, Stack, StackItem, Label, TextField, ComboBox, IconButton, PrimaryButton, MessageBarType, MessageBar } from "office-ui-fabric-react";
import styled from "styled-components";
import * as yup from "yup";
import { useFormik } from "formik";
import LocationSelector from "./LocationSelector";
import { useAuthorizedFetch } from "../../../hooks/useFetch";
import { API_ADMIN_ROLES, apiCall, adminUpdateRole, adminDeleteRole } from "../../../api";
import { notifications } from "../../../atoms/notifications";
import { useSetRecoilState } from "recoil";
import { v1 } from "uuid";
import AddRolePanel from "./AddRolePanel";
import ButtonPanel from "../../../components/ui/ButtonPanel";
import { useNotificationService } from "../../../hooks/useNotificationService";

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;
}

const Main = styled.div`
    display: grid;
    grid-template-columns: 150px auto;
    grid-template-rows: auto;
`;

const Details = styled.div`
    padding: 16px;
    max-width: 600px;

`;

const Roles = styled.div`
    border-right: 1px solid #e0e0e0;
`;

const RoleItem = styled.div`
    height: 46px;
    padding: 0 12px;
    display: flex;
    align-items: center;

    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
`;

const LocationAccessGrid = styled.div`
    display: grid;
    column-gap: 12px;
    row-gap: 6px;
    grid-template-columns: auto 100px 18px; 
`;


const schema = yup.object({
    name: yup.string().required(),
    acl: yup.array(
        yup.object({
            id: yup.number(),
            locationId: yup.number().required(),
            read: yup.string().oneOf(["allow", "deny"]).required()
        }).defined()
    ).defined()
}).defined();


type Schema = yup.InferType<typeof schema>;
type Acl = Schema["acl"][0];

interface Role
{
    id: string;
    name: string;
}

interface RoleEditorProps
{
    initialValues?: Schema;
    onSave?: (values: Schema) => void;
    onDelete?: () => void;
}

const emptySchema: Schema = {
    name: "",
    acl: []
};

function RoleEditor(props: RoleEditorProps)
{
    const { initialValues, onSave, onDelete } = props;
    const { setValues, values, setFieldValue, handleChange, submitForm } = useFormik<Schema>({
        initialValues: initialValues ?? emptySchema,
        validationSchema: schema,
        onSubmit: (values) => {
            onSave?.(schema.cast(values));
        }
    });

    const isAdmin = initialValues?.name === "Administrator";

    const disabledIds = schema.cast(values).acl.map(x => x.locationId);

    return (
        <Stack tokens={{childrenGap: "m"}}>
        {
            (isAdmin) ? (
                <MessageBar messageBarType={MessageBarType.warning}>
                    The 'Administrator' role is special in that it grants full access and administrative rights to the site. 
                    It is not possible to change the settings of this role.
                </MessageBar>
            ) : null
        }
            <StackItem>
                <TextField label="Name" disabled={isAdmin} placeholder="Enter a unique role name here" name="name" value={values.name} onChange={handleChange} />
            </StackItem>
            <StackItem>
                <LocationAccessGrid>
                    <Label>Location</Label>
                    <Label>View</Label>
                    <span />

                    {
                        values.acl.map((v, idx) => (
                            <>
                                <LocationSelector 
                                    disabled={isAdmin}
                                    disabledLocationIds={disabledIds}
                                    value={v.locationId} 
                                    onChange={v => setFieldValue(`acl[${idx}].locationId`, v.toString()) } />
                                <ComboBox placeholder="View" options={[
                                    { key: "allow", text: "Allow" },
                                    { key: "deny", text: "Deny" },
                                ]}  selectedKey={v?.read}
                                    disabled={isAdmin}
                                    onChange={(_, v) => setFieldValue(`acl[${idx}].read`, v?.key)} />
                                <IconButton iconProps={{iconName: "cancel"}} disabled={isAdmin} onClick={() => {
                                    setValues({...values, acl: values.acl.filter((_, i) => i !== idx )});
                                }}/>
                            </>
                        ))
                    }

                    <IconButton iconProps={{ iconName: "add" }} disabled={isAdmin} onClick={
                        () => setValues({...values, acl: [...values.acl, { locationId: -1, read: "deny" }]})
                    } />

                </LocationAccessGrid>
            </StackItem>
            <StackItem>
                <Stack tokens={{ childrenGap: 8 }} horizontal>
                    {/* <DefaultButton disabled={isAdmin} style={{backgroundColor: SharedColors.redOrange10, color: "white"}}>Delete</DefaultButton> */}
                    <div style={{ flexGrow: 1 }}>
                        <PrimaryButton disabled={isAdmin} onClick={submitForm}>Save</PrimaryButton>
                    </div>
                    <IconButton iconProps={{ iconName: "delete" }} onClick={onDelete} />
                </Stack>
            </StackItem>
        </Stack>
    );
}

function RoleManagement()
{
    const [, roles, refresh] = useAuthorizedFetch<Role[]>(API_ADMIN_ROLES);
    const [activeRole, setActiveRole] = useState<Role>();
    const [acl, setAcl] = useState<Acl[]>();
    const setNotifications = useSetRecoilState(notifications);
    const [activatePanel, setActivatePanel] = useState<string>();
    const { addNotification } =  useNotificationService();

    const changeRole = useCallback(async (role: Role) => 
    {
        setActiveRole(undefined);
        setAcl(undefined);

        const res = await apiCall(`${API_ADMIN_ROLES}/${role.id}/acl`, "GET");
        if(res.ok)
        {
            const acl: Acl[] = await res.json();
            setAcl(acl);
            setActiveRole(role);
        }

    }, []);

    const onSave = async (values: Schema) => 
    {
        if (activeRole)
        {
            const res = await adminUpdateRole(activeRole.id, values);
            if (res.ok)
            {
                setNotifications(notifs => [...notifs, {
                    id: v1(),
                    messageBarType: MessageBarType.success,
                    children: <span>Role '{values.name}' was succesfully updated.</span>
                }]);
            }
        }
        refresh();
    };

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: "new",
            text: "Add Role",
            iconProps: { iconName: "addGroup" },
            onClick: () => setActivatePanel("add")
        },
    ];

    const onDismissPanel = () => setActivatePanel(undefined);
    const onRoleAdded = () => {
        setActivatePanel(undefined);
        refresh();
    }
    const onRoleDeleted = async (role: Role) => {

        const res = await adminDeleteRole(role.id)
        if(res.ok)
        {
            addNotification(`Role '${role.name}' was deleted.`, MessageBarType.info);
            setActiveRole(undefined);
        }
        else
        {
            addNotification(`Could not delete role '${role.name}'. An error occurred.`, MessageBarType.info);
        }
        setActivatePanel(undefined);
        refresh();
    }

    return (
        <>
            {
                (activatePanel === "add") ? (
                    <AddRolePanel onDismiss={onDismissPanel} onAdded={onRoleAdded} />
                )  : 
                (activatePanel === "delete" && (activeRole !== undefined)) ? (
                    <DeleteRolePanel role={activeRole} onDismiss={onDismissPanel} onConfirm={onRoleDeleted} />
                )
                : null
            }
            <div style={{borderBottom: "1px solid #e0e0e0", display: "flex", padding: 2}}>
                <CommandBar items={commandBarItems} />
            </div>
            <Main>
                <Roles>
                    <Stack>
                    {
                        roles?.map(role => (
                            <StackItem key={role.id}>
                                <RoleItem onClick={() => {
                                    changeRole(role); 
                                }}>{role.name}</RoleItem>
                            </StackItem>
                        ))
                    }
                    </Stack>
                </Roles>
                <Details>
                {
                    (activeRole && acl) ? <RoleEditor key={activeRole.id} onSave={onSave} onDelete={() => setActivatePanel("delete")} initialValues={{
                        name: activeRole.name,
                        acl
                    }}/> : null
                }
                </Details>
            </Main>
        </>
    );
}

function DeleteRolePanel(props: { role: Role, onDismiss?: () => void, onConfirm?: (role: Role) => void })
{
    const { role, onDismiss, onConfirm } = props;
    const onPrimaryClick = useCallback(() => onConfirm?.(role), [onConfirm, role])
    return (
        <ButtonPanel headerText="Delete Role" isOpen={true} onDismiss={onDismiss} onPrimaryButtonClick={onPrimaryClick}> 
        <p>
            Are you sure you want to delete the role '<span style={{fontWeight: "bold"}}>{role.name}</span>'?
            All users that have the role assigned could lose access to locations and data.
        </p>
        </ButtonPanel>
    );
}

export default RoleManagement;