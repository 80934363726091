import * as yup from "yup";

export const passwordPolicySchema = yup.object({
    mfaEnabled: yup.bool().required(),
    requireDigit: yup.bool().required(),
    requireLowercase: yup.bool().required(),
    requireNonAlphanumeric: yup.bool().required(),
    requireUppercase: yup.bool().required(),
    requiredLength: yup.number().integer().min(6, "Minimum length is 6").required("You must specificy a required password length"),
    requiredUniqueChars: yup.number().integer().min(1, "Minimum length is 1").required("You must specify a minimum number of unique characters"),
}).defined();

export type PasswordPolicySchema = yup.InferType<typeof passwordPolicySchema>;

export const userProfileSchema = yup.object({
    fullName: yup.string().defined(),
    jobTitle: yup.string().defined()
}).defined();

export type UserProfileSchema = yup.InferType<typeof userProfileSchema>;

