import { useState, useEffect, useCallback } from "react";
import { Machine, DoneInvokeEvent, interpret, Interpreter, MachineConfig } from "xstate";
import authService from "../api-authorization/AuthorizeService";

interface Options
{
    retry?: boolean;
    retryLimit?: number;
    retryType?: "transport_error" | "http_error" | "both";
    parseAs?: "json" | "text";
}

interface ServiceResultObject
{
    type: "http_error" | "success";
    statusCode: number;
    body: any;
    headers: Headers;
}


type ServiceResult = { type: "transport_error", error: Error } | ServiceResultObject;

type FetchEvent = DoneInvokeEvent<ServiceResult>;

interface StateSchema
{
    states: {
        idle: {},
        fetching: {},
        success: {},
        http_error: {},
        transport_error: {},
        failed: {}
    }
}

const fetchMachinceDefinition: MachineConfig<null, StateSchema, FetchEvent> = {
    initial: "idle",

    states: {

        idle: {
            always: "fetching",
        },

        fetching: {
            invoke: {
                id: "fetch",
                src: "fetch",
                onDone: "success",
                onError:[
                    { cond: "isHttpError", target: "http_error" },
                    { cond: "isTransportError", target: "transport_error" }, 
                    { target: "failed" } // fallback
                ]

            }
        },

        success: {
            type: "final",
            data: (_: any, event: FetchEvent) => event.data
        },

        http_error: {
            type: "final",
            data: (_: any, event: FetchEvent) => event.data
        },

        transport_error: {
            type: "final",
            data: (_: any, event: FetchEvent) => event.data
        },

        failed: {
            type: "final"
        }
    }
};

const createFetchMachine = (input: RequestInfo, init?: RequestInit) => Machine<null, FetchEvent>(fetchMachinceDefinition, {
    services: {
        fetch: async () => {
            try 
            {
                const response = await fetch(input, init);
                const contentType = response.headers.get("Content-Type")?.split(";")[0].trim();
                
                const body = (contentType === "application/json") 
                    ? await response.json() 
                    : (contentType?.startsWith("text/")) 
                    ? await response.text()
                    : response.body;

                const result: ServiceResultObject = {
                    type: response.ok ? "success" : "http_error",
                    body,
                    headers: response.headers,
                    statusCode: response.status 
                };

                return (result.type === "http_error") 
                    ? Promise.reject(result)
                    : result;
            }
            catch(e)
            {
                console.error(e);
                return { type: "transport_error", e };
            }
        }
    },
    guards: {
        isHttpError: (context, event: DoneInvokeEvent<ServiceResult>) => event.data.type === "http_error",
        isTransportError: (context, event: DoneInvokeEvent<ServiceResult>) => event.data.type === "transport_error",
    }
});

/**
 * 
 * @param input 
 * @param init 
 * @returns Array of state, result, refreshRequest
 */
export function useFetch<T>(input: RequestInfo, init?: RequestInit): [string, T | undefined, () => void, Headers | undefined]
{
    const [state, setState] = useState<string>("fetching");
    const [result, setResult] = useState<T>();
    const [responseHeaders, setResponseHeaders] = useState<Headers>();
    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
    
    const refresh = useCallback(() => setRefreshTrigger(x => !x), []);

    useEffect(() => {
        let service = interpret(createFetchMachine(input, init))
            .onTransition(state => setState(state.value as string))
            .onDone(event => {
                const data: ServiceResultObject = event.data;
                setResponseHeaders(data.headers);
                setResult(data.body);
            })
            .start();

        return () => {
            service.stop();
        }
    }, [input, init, refreshTrigger]);

    return [state, result, refresh, responseHeaders];
}

type FetchState = keyof (StateSchema["states"]);

export function useAuthorizedFetch<T>(input: RequestInfo | undefined, init?: RequestInit): [FetchState, T | undefined, () => void, Headers | undefined]
{
    const [state, setState] = useState<FetchState>("idle");
    const [result, setResult] = useState<T>();
    const [responseHeaders, setResponseHeaders] = useState<Headers>();
    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
    
    const refresh = useCallback(() => setRefreshTrigger(x => !x), []);

    useEffect(() => {
        let cancelled = false;
        let service: Interpreter<null, StateSchema, FetchEvent>;
        authService.getAccessToken()
            .then(accessToken => {
                if(cancelled)
                {
                    return;
                }

                if(input === undefined)
                {
                    setState("idle");
                    setResult(undefined);
                    return;
                }

                const _init: RequestInit = init || {};
                _init.headers = {
                    ...(_init.headers || {}),
                    Authorization: `Bearer ${accessToken}`
                }

                console.log("fetching", input);

                service = interpret(createFetchMachine(input, _init))
                    .onTransition(state => setState(state.value as FetchState))
                    .onDone(event => {
                        const data: ServiceResultObject = event.data;
                        setResponseHeaders(data.headers);
                        setResult(data.body);
                    })
                    .start();
            })

        return () => {
            cancelled = true;
            service?.stop();
        }
    }, [input, init, refreshTrigger]);

    return [state, result, refresh, responseHeaders];
}