import { useEffect } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { API_CUSTOMER } from "../api";
import { License, licenseAtom, substriptionPageStep,ISubstriptionPageStep,IRefreshLicense,refreshLicense,licenseValid } from "../atoms/license";
import { authorizedFetch } from "../util/fetch";
import { delay } from "../util/promise";
import equal from "fast-deep-equal";
import { useNavigate } from "react-router-dom";

type JsonDate<T> = {
    [P in keyof T]: T[P] extends Date ? string : T[P];
}

interface LicenseReply
{
    details?: JsonDate<License["details"]>;
    valid: License["valid"];
}

class CancellationTokenSource
{
    private _promise: Promise<void>;
    private _accept?: () => void;

    static CANCELLED = "__CANCELLATION_TOKEN_SOURCE:CANCELLED";

    constructor()
    {
        this._promise = new Promise((accept) => this._accept = accept);
    }

    get token(): Promise<void>
    {
        return this._promise;
    }

    async cancel()
    {
        let wait = 0;
        while(this._accept === undefined)
        {
            await delay(wait);
            wait = 100;
        }
        this._accept();
    }
}


function LicenseManager()
{
    const [license, setLicense] = useRecoilState<License>(licenseAtom);
    const [licenseValidRead, setLicenseValidRead] = useRecoilState<boolean>(licenseValid);
    const navigate = useNavigate();
    
    const [pageView, setPageView] = useRecoilState<ISubstriptionPageStep>(substriptionPageStep);
    const [refreshLic, setRefreshLic] = useRecoilState<IRefreshLicense>(refreshLicense);

    const update  = async () =>  {
        const res = await authorizedFetch(`${API_CUSTOMER}/license`);
        if(res.ok)
        {
            const result: LicenseReply = await res.json();
            let license: License = 
            {
                details: result.details ? ({
                    ...result.details,
                    hardValidTo: new Date(Date.parse(result.details.hardValidTo)),
                    softValidTo: new Date(Date.parse(result.details.softValidTo))
                }) : undefined,
                valid: result.valid
            }
            setLicense(license);

            if (!license.valid)
            {
                navigate('/admin/subscription');
            }

        }
    }


    useEffect(() => {
        
        update();
    
    }, [pageView, refreshLic]);




    useEffect(() => {
        const source = new CancellationTokenSource();

        async function updateLicense()
        {

            const res = await authorizedFetch(`${API_CUSTOMER}/license`);
            if(res.ok)
            {
                const result: LicenseReply = await res.json();
                let license: License = 
                {
                    details: result.details ? ({
                        ...result.details,
                        hardValidTo: new Date(Date.parse(result.details.hardValidTo)),
                        softValidTo: new Date(Date.parse(result.details.softValidTo))
                    }) : undefined,
                    valid: result.valid
                }

                setLicense(oldLicense => equal(oldLicense, license) ? oldLicense : license);
            }
        }

        async function runUpdater()
        {
            while(true)
            {
                try 
                {
                    await updateLicense();
                } 
                catch(e)
                {
                    // possible network issues
                    console.error(e);
                }

                // UPDATE every 5 minutes (TODO * 1000)
                if(await Promise.race([source.token, delay(5 * 3600 * 1000)]) === CancellationTokenSource.CANCELLED)
                {
                    break;
                }
                
            }
        }

        runUpdater();


        return () => {
            source.cancel();
        }
    }, [setLicense]);


    return null;
}

export default LicenseManager;