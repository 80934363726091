import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Routes, Route, Outlet } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { theme } from "../../theme/guldmann";
import { IconButton, Panel, PanelType } from "office-ui-fabric-react";
import SidebarNavigation from "../SidebarNavigation";
import UserMenu from "../../views/profile/UserMenu";
import { INavLink } from "office-ui-fabric-react/lib/Nav";
import { locationsFilterList } from "./../../atoms/google";
import { useRecoilState, useSetRecoilState } from "recoil";
// const HeaderButton = styled.button`

// `;

const HeaderContainer = styled.header`
    display: flex;
    /* height: calc(2em + 2vw); */
    max-height: calc(2em + 2vw);
    border-bottom: 2px solid #FDB913;
    grid-area: header;
`;

const HeaderLogo = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 15px;

    img.logo { height: 20px; display: block; }
    button { display: block; }
    @media screen and (max-width: ${props => props.theme.breakpoint.m}) {
        .logo { justify-self: center; margin-left: 12px; }
        /* justify-content: center;
        justify-items: center; */
    }
`;

const Main = styled.main``;

function Header()
{
    const isSmallScreen = useMediaQuery(`screen and (max-width: ${theme.breakpoint.m})`);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [lFilterList, setLFilterList] = useRecoilState<string[]>(locationsFilterList);

    const onMenuButtonClick = useCallback(() => {
        setIsMenuOpen(true);
    }, []);

    const onDismiss = useCallback((item: INavLink) => {
        if (item.name === "Hoists"){
            setLFilterList([]);
          }
        
        setIsMenuOpen(false);
        
    }, []);

    const onDismiss2 = useCallback(() => {
        setIsMenuOpen(false);
    }, []);

    return (
        <HeaderContainer>

            <HeaderLogo>
            {
                (isSmallScreen) ? (
                <>
                    <IconButton iconProps={{iconName: "GlobalNavButton"}} onClick={onMenuButtonClick} style={{float: "left"}} />
                    <img src={logo} className="logo" alt="" />

                    <Panel isOpen={isMenuOpen} onDismiss={onDismiss2} type={PanelType.smallFixedNear}>
                        <SidebarNavigation onLinkClick={(item) => onDismiss(item)} />
                    </Panel>
                </>
                ) : (
                    <img src={logo} className="logo" alt="" />
                )
            }
            </HeaderLogo>

            <Main>
                <Routes>
                    <Route path="/" element={<Outlet />}></Route>
                </Routes>
            </Main>

            <UserMenu />
    
        </HeaderContainer>
    )
}
    


export default Header;