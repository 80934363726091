import * as React from "react";
import { ICommandBarItemProps, SearchBox, CommandBar, IContextualMenuItem } from "office-ui-fabric-react";
import { useCallback, useMemo } from "react";
import { memoize } from "../../util/fn";
import { useUserRoles } from "../../api-authorization/useUserRoles";


export interface Props
{
    hasSelection?: boolean;
    onCommandClick?: (command: string) => void;
    onFilter?: (filter: string | undefined) => void;
    googleMap?: boolean;
    onClearGoogleResult?: () => void;
}

const searchStyles = {
    root: {
        marginTop: "6px",
        marginRight: "12px",
        width: "220px",
    }
};

interface MenuRoles
{
    roles?: string[];
}

const commandMenu: ICommandBarItemProps[] = [{
    key: "register-hoist",
    text: "Register Hoist",
    iconProps: { iconName: "add" },
    data: { roles: ["Administrator"] }
}, {
    key: "hoist-assign",
    text: "Set Location",
    iconProps: { iconName: "PageArrowRight" },
    data: { roles: ["Administrator"] }

}, {
    key: "hoist-unassign",
    text: "Remove Location",
    iconProps: { iconName: "DependencyRemove" },
    data: { roles: ["Administrator"] }
},
{
    key: "hoist-googlemap",
    text: "Reset filter",
    iconProps: { iconName: "DependencyRemove" },
}

];

const commandMenuFar = memoize((onFilter?: (filter: string | undefined) => void): ICommandBarItemProps[] => [{
    key: "search",
    onRender: () => <SearchBox placeholder="Search..." styles={searchStyles} onChange={(e, v) => onFilter?.(v)} />
}]);

const containsAny = <T extends any>(haystack: T[], needles: T[]): boolean =>
    haystack.some(x => needles.includes(x));

const hasRoles = <T extends IContextualMenuItem>(menuItem: T): menuItem is T & { data: MenuRoles }  =>
    (typeof(menuItem.data) === "object") && ("roles" in menuItem.data) && Array.isArray(menuItem.data.roles);

function ListViewCommandBar(props: Props)
{
    const roles = useUserRoles() ?? [];
    const { hasSelection, onCommandClick, onFilter } = props;
    const onMenuClick = useCallback((_?, item?: IContextualMenuItem) => {
        if(item !== undefined)
        {
            onCommandClick?.(item.key);
        }
    }, [onCommandClick]);

    const onGooglemap = useCallback(() => {
        if (props.onClearGoogleResult !== undefined){
            props.onClearGoogleResult();
        }
    }, [onCommandClick, props.googleMap]);



    const items: ICommandBarItemProps[] = useMemo<ICommandBarItemProps[]>(() => 
        commandMenu
            .filter(item => !hasRoles(item) || containsAny(item.data.roles, roles))
            .map(item => ({
                ...item,
                disabled:  (!hasSelection && (item.key !== "register-hoist" && item.key !== "hoist-googlemap")) || (item.key === "hoist-googlemap" && props.googleMap === false),
                onClick: item.key !== "hoist-googlemap" ? onMenuClick : props.googleMap === true ? onGooglemap : undefined
            }))
    , [hasSelection, onMenuClick, roles, props.googleMap]);

    return <CommandBar 
        items={items} 
        farItems={commandMenuFar(onFilter)} 
        />;
}

export default ListViewCommandBar;