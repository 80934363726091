import { getTheme, Icon } from "office-ui-fabric-react";
import styled, { css, CSSObject } from "styled-components";

const theme = getTheme();

const font = css(theme.fonts.medium as CSSObject);
export const Tree = styled.ul`
    ${font};
    margin-left: 14px;
`;

export const TreeItemLabel = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const TreeItemIcon = styled(Icon).attrs({
    iconName: "ChevronRight",
})<{ show: boolean, open: boolean }>`
    line-height: 42px;
    visibility: ${props => props.show ? "visible" : "hidden"};
    transform-origin: center;
    transform: rotate(${props => props.open ? 90 : 0}deg);
    transition: transform ease-out 100ms;
    width: 42px;
    text-align: center;
    cursor: pointer;
    user-select: none;
`;

export const TreeItemLi = styled.li<{active?: boolean, disabled?: boolean}>`
    & > ${TreeItemLabel} {
        height: 42px;
        line-height: 42px;
        font-weight: ${props => props.active ? "bold" : "normal"};
        color: ${props => props.disabled ? "#aaa" : "inherit" };
        cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    }

    ${TreeItemIcon} {
        color: ${props => props.disabled ? "#aaa" : "inherit" };
        cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    }
    
`;

