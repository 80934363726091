import React, { useCallback, useState, useMemo } from "react";
import { DetailsList, IColumn, CheckboxVisibility, Icon, ColumnActionsMode } from "office-ui-fabric-react";
import { useAuthorizedFetch } from "../../../hooks/useFetch";
import { API_ADMIN_AUDIT } from "../../../api";
import {intl} from "../../../i18n";
import styled from "styled-components";
import produce from "immer";
import PagingBar from "../../../components/ui/PagingBar";
import LocationName from "./LocationName";

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;
}

interface AuditItem
{
    id: string;
    type: number;
    customerId: string;
    dateTime: string;
    userId: string;
    userEmail: string;
    target: string;
    category: string;
    action: string;
    
    data: any;
    userName: string;

}

const dtf = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
});
const formatDate = (item?: AuditItem, index?: number, column?: IColumn) => 
{
    return (item === undefined) ? "" : dtf.format(new Date(item.dateTime));
}

const AuditLogType = {
    Read: 0,
    Update: 1,
    Create: 2,
    Delete: 3,
    Access: 4
};


// const formatType = (item?: AuditItem, index?: number, column?: IColumn) => 
//     (item === undefined) ? "" :
//     (item.type === AuditLogType.Read) ? intl.formatMessage({ defaultMessage: "Read" }) :
//     (item.type === AuditLogType.Update) ? intl.formatMessage({ defaultMessage: "Update" }) :
//     (item.type === AuditLogType.Create) ? intl.formatMessage({ defaultMessage: "Create" }) :
//     (item.type === AuditLogType.Delete) ? intl.formatMessage({ defaultMessage: "Delete" }) :
//     (item.type === AuditLogType.Access) ? intl.formatMessage({ defaultMessage: "Access" }) :
//     "";

const formatCategory = (item?: AuditItem, index?: number, column?: IColumn) => 
    (item === undefined) ? "" :
    (item.category.startsWith("auth:")) ? formatAuth(item) :
    (item.category.startsWith("entity:")) ? formatEntity(item) :
    (item.category === "user:invite") ? intl.formatMessage({ defaultMessage: "User ’{email}’ invited" }, { email: item.target }) :
    (item.category === "user:deleted") ? intl.formatMessage({ defaultMessage: "User ’{email}’ deleted" }, { email: item.target }) :
    (item.category === "user:accept_invite") ? intl.formatMessage({ defaultMessage: "User ’{email}’ accepted the invitation" }, { email: item.target }) :
    (item.category === "setting:Weight") ? intl.formatMessage({ defaultMessage: "Settings:Weight: changed to "}) + ConvertBool(item) :
    (item.category === "setting:GenerateApi") ? intl.formatMessage({ defaultMessage: "New Api Key generated"}) :
    (item.category === "location:organization") ? intl.formatMessage({ defaultMessage: "Organization address associated with hoist ’{serialNumber}’"},{ serialNumber: item.target}) :
    (item.category === "location:auto") ? intl.formatMessage({ defaultMessage: "Auto address associated with host ’{serialNumber}’"},{ serialNumber: item.target}) + " " + convertStatus(item) :
    (item.category === "location:address") ? intl.formatMessage({ defaultMessage: "Address associated with hoist ’{serialNumber}’"},{ serialNumber: item.target}) + " " + convertStatusEmpty(item) :
    (item.category === "location:assign") ? intl.formatMessage({ defaultMessage: "Hoist ’{serialNumber}’ has been assigned to location ’{locationId}’"},{ serialNumber: item.target, locationId: item.data.locationId}) :
    item.category;
    

    const convertStatusEmpty = (item?: AuditItem):string => {
        let status = (item?.data.status as string);
    
        if (item?.data.status !== undefined){
            status = status.toString();
        }
    
        return status === '' ? " Set to Off" : "Set to On";    
    }
    

const convertStatus = (item?: AuditItem):string => {
    let status = (item?.data.status as string);

    if (item?.data.status !== undefined){
        status = status.toString();
    }

    return status === 'true' ? " Set to On" : "Set to Off";    
}

const ConvertBool = (item?: AuditItem):string => {
    const isActive = item?.action!.toLowerCase() === "true";
    return isActive? " active" : " inactive";
}


const formatAuth = ({target, category, action}: AuditItem) => 
    (category === "auth:login") ? (
        (action === "invalid_login") ? intl.formatMessage({ defaultMessage: "Invalid login attempt for user ’{name}’" }, { name: target }) :
        (action === "require_mfa") ? intl.formatMessage({ defaultMessage: "User ’{name}’ logged in with password. Waiting for MFA validation" }, { name: target })
        : category
    ) :
    (category === "auth:login:mfa") ? intl.formatMessage({ defaultMessage: "User ’{name}’ logged in with MFA" }, { name: target }) :
    "";

const formatEntity = (item: AuditItem) =>
    (item.category === "entity:Hoist") ? (
        (item.type === AuditLogType.Update) ? (
            (item.action === "register") ? intl.formatMessage({ defaultMessage: "Register ownership for hoist ’{serialNumber}’" }, { serialNumber: item.target, }) :
            (item.data?.values?.LocationId !== undefined) ? intl.formatMessage({defaultMessage: "Moved hoist ’{serialNumber}’ to location {locationId}" },{ serialNumber: item.target, locationId: item.data.values.LocationId}) :
            intl.formatMessage({ defaultMessage: "Update hoist ’{serialNumber}’" }, { serialNumber: item.target })
        ) : null
    ) : 
    (item.category === "entity:Location") ? formatLocation2(item)  :
    (item.category === "entity:Hoist") ? formatHoist(item) :
    (item.category === "entity:Role") ? formatRole(item) :
    (item.category === "entity:LocationAcl") ? formatLocationAcl(item) :
    (item.category === "entity:CustomerPasswordPolicy") ? formatPasswordPolicy(item) :
    (item.category === "entity:User") ? formatUser(item) :
    //(item.category === "entity:LocationChanged") ? intl.formatMessage({ defaultMessage: "A hoist has been changed" }):
    item.category
    ;

const formatUser = (item: AuditItem) => 
    intl.formatMessage({ defaultMessage: "Updated user information" });

const formatLocation2 = (item: AuditItem) => 
    (item.type === AuditLogType.Create) ? ( intl.formatMessage({defaultMessage: "Create new location ’{name}’"}, { name: item.data.values.address }) ) : 

    (item.type === AuditLogType.Delete) ? ( intl.formatMessage({defaultMessage: "Delete location ’{name}’"}, { name: item.data.values.address }) ) : 
    (item.type === AuditLogType.Update) ? ( intl.formatMessage({defaultMessage: "Update location ’{name}’"}, {  name: item.data.values.address })) :
     item.category;

/*    const formatLocation2 = (item: AuditItem) => 
    (item.type === AuditLogType.Create) ? (
        intl.formatMessage({defaultMessage: "Create new location ’{name}’"}, { name: item.data.values.label })
    ) : (item.type === AuditLogType.Delete) ? (
        intl.formatMessage({defaultMessage: "Delete location ’{name}’"}, { name: item.data.values.label })
    ) : (item.type === AuditLogType.Update) ? (
        intl.formatMessage({defaultMessage: "Update location ’<location-name>{id}</location-name>’"}, { 
            id: item.target,
            "location-name": (chunks: string) => <LocationName>{chunks}</LocationName>,
        })
    )
    : item.category;*/




const formatHoist = (item: AuditItem) =>
    (item.type === AuditLogType.Update) ? (
        intl.formatMessage({ defaultMessage: "Updated hoist ’{serialNumber}’" }, { name: item.target })
    )
    : item.category;

const formatRole = (item: AuditItem) => 
    (item.type === AuditLogType.Create) ? intl.formatMessage({ defaultMessage: "Create new role ’{roleName}’" }, { roleName: item.data.values.Name })
    : item.category

const formatLocationAcl = (item: AuditItem) => 
    (item.type === AuditLogType.Create) ? intl.formatMessage({ 
            defaultMessage: "Added permission to ’{role}’ for location ’{location}’" 
        }, { 
            role: item.data.values.roleId,
            location: item.data.values.locationId 
        }) :
    (item.type === AuditLogType.Delete) ? intl.formatMessage({
            defaultMessage: "Removed permission for location ’{location}’ from ’{role}’"
        },{ 
            role: item.data.values.roleId,
            location: item.data.values.locationId 
        }) :
    (item.type === AuditLogType.Update) ? intl.formatMessage({
            defaultMessage: "Updated permission for location ’{location}’ from ’{role}’ "
        },{ 
            role: item.data.values.roleId,
            location: item.data.values.locationId 
        }) :
    item.category
    ;
    
const formatPasswordPolicy = (item: AuditItem) => 
        (item.type === AuditLogType.Update || item.type === AuditLogType.Create) ? intl.formatMessage({
            defaultMessage: "Updated password policy"
        }) : 
        item.category
        ;

const renderUser = (item?: AuditItem) =>
    (item !== undefined) ? (
        <span>{item.userName}</span>
    ) : null;

const RowIcon = styled(Icon)` font-size: 16px; `;

const renderIcon = (item: AuditItem) => 
    (item.category === "auth:login" || item.category === "auth:login:mfa") ? (
        (item.action === "success") ? <RowIcon iconName={"UserFollowed"} style={{color: "green"}}/> : 
        (item.action === "invalid_login") ? <RowIcon style={{color: "red" }} iconName={"UserDelete"} /> :
        <RowIcon iconName={"UserWarning"} style={{color: "goldenrod" }} />
    ) : 
    (item.type === AuditLogType.Create) ? <RowIcon  iconName={"PageAdd"} /> :
    (item.type === AuditLogType.Update) ? <RowIcon iconName={"Refresh"} style={{color: "blueviolet" }} /> :
    (item.type === AuditLogType.Delete) ? <RowIcon style={{color: "red" }} iconName={"Delete"} /> :
    null;

    // <RowIcon iconName={getAuditItemIconName(item) ?? ""} />;
    // getAuditItemIconName(item) ?? "<?>";

const renderType = (item: AuditItem) =>
    (item.category.startsWith("auth:")) ? intl.formatMessage({ defaultMessage: "Authentication" }) :
    (item.category.startsWith("user:") || item.category === "entity:User") ? intl.formatMessage({ defaultMessage: "User" }) :
    (item.category === "entity:Hoist") ? intl.formatMessage({ defaultMessage: "Hoist" }) :
    (item.category === "entity:Location2") ? intl.formatMessage({ defaultMessage: "Location" }) :
    (item.category === "entity:Location") ? intl.formatMessage({ defaultMessage: "Location" }) :
    (item.category === "entity:LocationChanged") ? intl.formatMessage({ defaultMessage: "Location" }) :
    (item.category === "entity:CustomerPasswordPolicy") ? intl.formatMessage({ defaultMessage: "Custom Password Policy" }) :
    (item.category === "entity:Role") ? intl.formatMessage({ defaultMessage: "Role" }) :
    (item.category === "entity:LocationAcl") ? intl.formatMessage({ defaultMessage: "Role" }) :
    (item.category === "setting:GenerateApi") ? intl.formatMessage({ defaultMessage: "Generate API Key" }) :
    (item.category === "setting:Weight") ? intl.formatMessage({ defaultMessage: "Settings" }, { name: "tester setting:Weight" }) :
    (item.category === "location:organization") ? intl.formatMessage({ defaultMessage: "Address" }) :
    (item.category === "location:auto") ? intl.formatMessage({ defaultMessage: "Address" }) :
    (item.category === "location:address") ? intl.formatMessage({ defaultMessage: "Address" }) :
    (item.category === "location:assign") ? intl.formatMessage({ defaultMessage: "location assign" }) :
     item.category


    

const _columns: IColumn[] = [
    {
        key: "icon",
        name: "",
        minWidth: 25,
        maxWidth: 30,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: renderIcon,
    },
    { 
        key: "title",
        name: intl.formatMessage({ defaultMessage: "Title" }), 
        minWidth: 50 ,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: formatCategory,
    },
    { 
        key: "dateTime", 
        fieldName: "dateTime", 
        name: intl.formatMessage({ defaultMessage: "Time" }), 
        minWidth: 150,
        onRender: formatDate,
        isSorted: true,
        isSortedDescending: true,
    },
    {
        key: "Type",
        fieldName: "category",
        name: intl.formatMessage({ defaultMessage: "Subject" }),
        minWidth: 100,
        onRender: renderType,
    },
    { 
        key: "userId", 
        fieldName: "userName", 
        name: intl.formatMessage({ defaultMessage: "User" }), 
        minWidth: 150,
        onRender: renderUser,
    },
];

interface Ordering
{
    sort_by: string;
    order_by: "asc" | "desc";
}

interface Paging
{
    offset?: number;
    limit?: number;
}

const toQueryParams = (o: { [key: string]: any }): string => 
    Object.keys(o)
        .map(key => `${key}=${encodeURIComponent(o[key].toString())}`)
        .join("&");

function Audit(props: Props)
{
    const pageSize = 18;
    const [ordering, setOrdering] = useState<Ordering>({sort_by: "dateTime", order_by: "desc"});
    const [paging, setPaging] = useState<Paging>({ limit: pageSize });
    const [currentPage, setCurrentPage ] = useState(1);
    const [columns, setColumns] = useState(_columns);
    const onColumnClick = useCallback((ev?: React.MouseEvent<HTMLElement, MouseEvent>, column?: IColumn) => 
    {
        if(column !== undefined)
        {
            const newColumns = produce(columns, columns => {

                columns.filter(x => x.key !== column.key && x.isSorted)
                    .forEach(x => x.isSorted = false);
                
                const currentColumn = columns.filter(x => x.key === column.key)?.[0];
                if(currentColumn !== undefined)
                {
                    currentColumn.isSortedDescending = (currentColumn.isSorted) ? !!!currentColumn.isSortedDescending : !!currentColumn.isSortedDescending;
                    currentColumn.isSorted = true;

                    setOrdering({ sort_by: currentColumn.fieldName!, order_by: currentColumn.isSortedDescending ? "desc" : "asc" })
                }

            });
            setColumns(newColumns);
        }

    }, [columns]);

    const query = toQueryParams(ordering) + "&" + toQueryParams(paging);
    const [, result, ,headers] = useAuthorizedFetch<any[]>(`${API_ADMIN_AUDIT}?${query}`);
    const [pages] = useMemo(() => {
        if(headers !== undefined)
        {
            const numberOfItems = parseInt(headers.get("X-Total-Count")!);
            const pages = ~~(numberOfItems / pageSize) + ((numberOfItems % pageSize) === 0 ? 0 : 1);
            return [pages, numberOfItems];
        }
        return [0, 0];
    }, [headers])

    const onPageClick = (n: number) =>
    {
        setPaging({ offset: (n - 1) * pageSize, limit: pageSize });
        setCurrentPage(n);
    }

    return (
        <div>
            <DetailsList 
                onColumnHeaderClick={onColumnClick}
                checkboxVisibility={CheckboxVisibility.hidden}
                columns={columns}
                items={result ?? []}
            />

            <div>
                <PagingBar 
                    pages={pages} 
                    currentPage={currentPage} onPageClick={onPageClick} />
            </div>
        </div>
    );
}

export default Audit;