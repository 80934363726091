import React from "react";
import { useCallback, useState, useMemo } from "react";
import { Node } from "../../components/treevew/TreeView";
import { Location, API_HOISTS } from "../../api";
import { Hoist } from "./useLocationHoists";
import { notifications } from "../../atoms/notifications";


import ListViewCommandBar from "./ListViewCommandBar";
import LocationHoistList, { LocationFilter } from "./HoistList";
import DialogController, { DialogType } from "./DialogController";
import { useAuthorizedFetch } from "../../hooks/useFetch";
import { useLocationTree } from "../../hooks/useLocationTree";
import { Label, mergeStyles, FontIcon, Dropdown, IDropdownOption } from "office-ui-fabric-react";
import { useRecoilValue, useRecoilState } from "recoil";
import { Ilocations, locationsFilterList } from "./../../atoms/google";
import './../../style.css';

export interface Props
{
    onNodeClick?: (node: Node<Location>) => void;
}

//const filterHoists = (allHoists: Hoist[] | undefined, filter: string): Hoist[] => 
//{
//    const lc_filter = filter.toLocaleLowerCase();
//    const result = allHoists?.filter(h =>
//        (h.displaySerialNo.indexOf(lc_filter) > -1)
//        || (h.locationName && h.locationName.toLocaleLowerCase().indexOf(lc_filter) > -1)
//    );
//    return result ?? allHoists ?? [];
//}

//{ key: '2', text: 'Green/White', data : { color: '#08802c', name:'ColorCircleleft'  } },

export const StatusOptions: IDropdownOption[] = [
    { key: "0", text: 'All', data : { color: '#ffffff', name:'colorCircle' }},
    { key: "1", text: 'Green', data : { color: '#08802c', name:'colorCircle'  } },
    { key: '2', text: 'White', data : { color: '#ffffff', name:'colorCircle'  } },
    { key: '3', text: 'Yellow', data : { color: '#f0f062', name:'colorCircle'  } },
    //{ key: '4', text: 'Orange', data : { color: '#e6a100', name:'colorCircle'  } },
    { key: '5', text: 'Red', data : { color: '#ff0000', name:'colorCircle'  } },
  ];

  export interface ICustomData {
    color: string;
    name: string;
  }


const newFilterHoists = (allHoists: Hoist[] | undefined, statusFilter: string | undefined, locationFilter : string | undefined, searchFilter: string | undefined, googleMapFilter: string[] | undefined): Hoist[] =>{

    const lc_filter = searchFilter !== undefined ? searchFilter.toLocaleLowerCase() : "";
    let result = allHoists;

    if (googleMapFilter !== undefined && googleMapFilter.length > 0)
    {
      result = googleMapFilteResult(allHoists,googleMapFilter);
    }

    console.log(locationFilter + " -- " + statusFilter);


    // Status filter
    if (statusFilter !== undefined && statusFilter !== "" && statusFilter !== "0"){
      result = result?.filter(x => x.status === parseInt(statusFilter))
    }

    // Location filter
    if (locationFilter !== undefined && locationFilter !== "__root__"){
      if (locationFilter !== ""){
        result = result?.filter(x => x.locationName === locationFilter)
      }
      else{
        result = result?.filter(x => x.locationName === null)
      }  
    }

    //search filter
    if (searchFilter !== undefined && searchFilter !== ""){
        result = result?.filter(h => 
          (h.hoistSerialNo.indexOf(lc_filter) > -1) || 
          (h.displaySerialNo.indexOf(lc_filter) > -1) || 
          (h.locationName && h.locationName.toLocaleLowerCase().indexOf(lc_filter) > -1));
    }

    return result ?? allHoists ?? [];
  
}



const googleMapFilteResult = (allHoists: Hoist[] | undefined,  googleMapFilter: string[] | undefined): Hoist[] =>{

  let result = allHoists;
  let arr = [] as Partial<string[]>;

  if (allHoists !== undefined){
    googleMapFilter?.map((val) =>{
      arr.push(val.toLowerCase());
    });
  
    if (googleMapFilter !== undefined && googleMapFilter?.length > 0){
      result = allHoists?.filter(x => arr.find(z =>  arr.indexOf(x.hoistSerialNo.toLowerCase()) >= 0));
    }
  }

  return result ?? allHoists ?? [];
  
}



const iconClass = mergeStyles({
    fontSize: 16,
    height: 16,
    width: 16,
    color: "green",
    cursor: "pointer",
    marginLeft: "8px"
  });


export interface IFormFilter
{
    items?: Hoist[]; 
}

//          <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />


const iconStyles = { marginRight: '8px' };


function Hoists()
{
    const [searchFilter, setSearchFilter] = useState<string>();
    const [locationFilter, setLocationFilter] = useState<LocationFilter>();
    const [hoistFiltered, setHoistFiltered] = useState<Hoist[]>();
    const [locationFilterNew, setLocationFilterNew] = useState<string | undefined>();
    const [statusFilterNew, setStatusFilterNew] = useState<string | undefined>();
    const [messages, setMessages] = useRecoilState(notifications);
    const [lFilterList, setLFilterList] = useRecoilState<string[]>(locationsFilterList);

    const [, allHoists, refresh] = useAuthorizedFetch<Hoist[]>(
        (locationFilter === "unassigned") ? `${API_HOISTS}/unassigned` :
        (locationFilter !== undefined) ? `${API_HOISTS}/location/${locationFilter}` :
        API_HOISTS
    );

    const [selection, setSelection] = useState<Hoist[]>([]);
    const [showDialog, setShowDialog] = useState<DialogType | undefined>(undefined);


    React.useEffect(() => {
      return () => {
        setMessages([]);
      }
    }, [])



    const onCommandClick = useCallback((name: string) => {
      setShowDialog(name as DialogType)
    }, []);
    
    
    const onDismiss = () => {
        setShowDialog(undefined);
        refresh();
    }

//    const hoistsFilterLocation = useMemo(() => (locationFilterNew !== undefined) ? newFilterHoists(allHoists, locationFilterNew, searchFilter, lFilterList) : googleMapFilteResult(allHoists, lFilterList) ,[locationFilterNew, searchFilter, allHoists, lFilterList]);
    const hoistsFilterLocation = useMemo(() => newFilterHoists(allHoists, statusFilterNew, locationFilterNew, searchFilter, lFilterList) ,[locationFilterNew, searchFilter, allHoists, lFilterList, statusFilterNew]);


    


    const onRenderOption2 = (option: IDropdownOption): any => {
        return (
          <div>
            {option.data && option.data.icon && (
                <FontIcon aria-label="Settings" iconName="CircleShapeSolid" className={iconClass} />
            )}
            <span>{option.text}</span>
          </div>
        );
      };
    

      const onRenderOption = (option: any): JSX.Element => {
        return (
            <div className='customOption'>
            <div className={option?.data.name} style={ { backgroundColor: option?.data.color } }></div>
            { option?.text }
          </div>
        );
      };

      const onRenderTitle = (options: any): JSX.Element => {
        const option = options[0];

        return (
            <div className='customOption'>
            <div className={option?.data.name} style={ { backgroundColor: option?.data.color } }></div>
            { option?.text }
          </div>
        );
      };


    const onClearGoogleResult = () => {
      setLFilterList([]);
    }

    const hasGoogleData = ():boolean => {
      return lFilterList === undefined || lFilterList.length === 0 ? false : true
    }


    const [locations] = useLocationTree();

    const compare = (a:IDropdownOption,b:IDropdownOption) => {
      if ( a.text < b.text ){
        return -1;
      }
      if ( a.text > b.text ){
        return 1;
      }
      return 0;
    }
   
    const FilterForms = (formFilterItems: IFormFilter) => {
      let arrLocationsOptionCopy = [] as IDropdownOption[];  
      let arrLocationsOption = [] as IDropdownOption[];
        
        
        formFilterItems.items?.forEach((el) => {
          if (arrLocationsOptionCopy.filter(x => x.key === el.locationName).length === 0){
            arrLocationsOptionCopy.push({ key: el.locationName ?? "", text: el.locationName ?? "" });
          }  
        });
        //Clear all empty keys
        arrLocationsOption = arrLocationsOptionCopy.filter(x => x.key !== "");
        //check if empty names before add it
        if (arrLocationsOptionCopy.filter(x => x.key === "").length > 0){
          arrLocationsOption.push({ key: "", text: "" });
        }
        arrLocationsOption.sort(compare);
        arrLocationsOption.unshift({ key: "__root__", text: 'All' })


        return(
            <div style={{display: "flex", marginLeft:"33px"}}>
                <div style={{marginRight:"5px"}}><Label>Filter by location</Label></div><div style={{width: "200px" }}><Dropdown label="" placeholder="Select an option"  onChange={(_, option) => {setLocationFilterNew(option?.key.toString())}} options={arrLocationsOption} selectedKey={locationFilterNew}></Dropdown></div>
                <div style={{marginLeft:"10px", display:"block"}}><Label>Filter by status</Label></div><div style={{width: "200px", marginLeft:"5px", display:"block"}}><Dropdown placeholder="Select an option" onChange={(_, option) => {setStatusFilterNew(option?.key.toString())}}  options={StatusOptions} selectedKey={statusFilterNew} onRenderOption={onRenderOption} onRenderTitle={onRenderTitle}> </Dropdown></div>     
            </div>
        )
    }


    return (
    <>
        <DialogController
            refresh={refresh}
            visibleDialog={showDialog}
            onDismiss={onDismiss}
            selectedHoists={selection}
            locations={locations ?? []}
             />

        <ListViewCommandBar
            hasSelection={selection.length > 0} 
            onCommandClick={onCommandClick}
            onFilter={setSearchFilter}
            googleMap={hasGoogleData()}
            onClearGoogleResult={() => onClearGoogleResult()}
            />

        <FilterForms items={allHoists} />

        <LocationHoistList 
            items={hoistsFilterLocation} 
            onSelectionChanged={setSelection} 
            onLocationFilterChange={setLocationFilter} 
            locationFilter={locationFilter}  
            reload={() => refresh()}/> 
    </>
    )

}

export default Hoists;