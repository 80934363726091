import React, {useEffect} from "react";
import { useCallback, useState, useMemo } from "react";
import TrainingList, { ITraining } from './TrainingList'
import {intl} from "../../i18n";
import { getTrainingList, getSuggestionsTags, SaveTagsAsync, getSavedTags, deleteSelectedTag } from "./../../api";
import { FilterList } from "../../components/filter/FilterList";
import { getDateString } from "./../dashboard/charts/utils";
import TimespanPill, { DateRange } from "./../dashboard/TimespanPill";
import { Location } from "../../api";
import LocationPill from "./../dashboard/LocationPill";
import { ITag } from "office-ui-fabric-react";
import {ITags, ISaveTags, ITrainingData} from "../../components/tagpopup/tagpopup"


export interface IQuery 
{
    from?: string;
    to?: string;
    location?: string;
    hoist?: string;
}

const convertToQueryString = (dateRange: { from: Date, to: Date }): { from: string, to: string} => ({
  from: getDateString(dateRange.from),
  to: getDateString(dateRange.to)
});

const Training = () =>
{
  const [trainigData, setTrainigData] = useState<ITraining[]>();
  const [suggestionsTagsData, setSuggestionsTagsData] = useState<ITags[]>();
  const [locationQuery, setLocationQuery] = useState<IQuery>();
  const [dateRange, setDateRange] = useState<IQuery>(convertToQueryString(TimespanPill.initialValue));
  const query: IQuery = {...dateRange, ...(locationQuery || {})};
  const [getItemData, setItemData] = useState<ITrainingData>();
  const [savedTagsData, setSavedTagsData] = useState<ITags[]>();
  const [getReload, setReload] = useState<boolean>(false);
  
  

  const onDateRangeChange = (dateRange: DateRange) => setDateRange(convertToQueryString(dateRange));
  const onLocationChange = (location?: Location[]) => setLocationQuery(
      (location === undefined) ? undefined : ({
          location: location.map(n => n.id.toString()).join(",")
      }));

  const getList = async (queryData:IQuery) => {
    const res = await getTrainingList(queryData);
  
    if(res.ok)
    {
      const retJson:ITraining[] = await res.json();
      setTrainigData(retJson);
    }
    else
    {
    }
  }
  
  const getSuggestionsTagsData = async () => {
    const res = await getSuggestionsTags();
    if(res.ok)
    {
      const retJson:ITags[] = await res.json();
      setSuggestionsTagsData(retJson);
    }
    else
    {
    }
  }

  const getSavedTagsData = async (trainingId:string) => {
    const res = await getSavedTags(trainingId);
    if(res.ok)
    {
      const retJson:ITags[] = await res.json();
      setSavedTagsData(retJson);
    }
    else
    {
    }
  }


  // training data list  
  useEffect(() => {
    const callback = async () => {
       getList(query);
  }
    callback();
  }, [dateRange,locationQuery]);
  

  // Suggestions liste
  useEffect(() => {
    const callback = async () => {
      getSuggestionsTagsData();
  }
    callback();
  }, []);


  //Saved tags
  useEffect(() => {
    const callback = async () => {
        if (getItemData != undefined){
          await getSavedTagsData(getItemData.trainingId.toString());
          await getSuggestionsTagsData();
        }
  }
    callback();
  }, [getItemData,getReload]);


 
  const ReloadData = () => {
    setReload(!getReload);
  }



  const saveTag = async (saveTags : ISaveTags) => {
    
    const res = await SaveTagsAsync(saveTags);
    if(res.ok)
    {
      const retJson:string = await res.json();
      ReloadData();
      await getList(query);
    }
    else
    {
    }
  }
  
const onClickEvent = async (itemData: ITrainingData) => {
  //alert(itemData.trainingId);
  //await getSavedTagsData(itemData.trainingId.toString());
  setItemData(itemData);
}


const onDelete = async (tag: ITags, trainingId: number) => {

  const res = await deleteSelectedTag(parseInt(tag.id),trainingId);

  if(res.ok)
  {
    ReloadData();
    await getList(query);
  }
  else
  {
  }
}



  return (
    <>

      <div style={{height:"50px"}}>
      <FilterList>
        <LocationPill onLocationChange={onLocationChange} />
        <TimespanPill onDateRangeChange={onDateRangeChange} />
      </FilterList>
        
      </div>
      <TrainingList 
      savedTags={savedTagsData ?? []} 
      data={trainigData ?? []} 
      suggestionsTags={suggestionsTagsData ?? []} 
      saveTag={(saveTags) => saveTag(saveTags)}  
      onClickEvent={(itemData) => onClickEvent(itemData)}
      onDelete={(tag,trainingId) => onDelete(tag, trainingId)}
      reloadData={() => ReloadData()}
      />
    </>
    )

}

export default Training;
