import * as React from "react";
import { useRef, useEffect, useMemo } from "react"
import { Hoist } from "./../../views/hoist/useLocationHoists";
import { API_HOISTS } from "../../api";
import { useAuthorizedFetch } from "../../hooks/useFetch";
import { HoistDetails } from "../../views/hoist/HoistDetailsView";
import { Text, TextField, mergeStyles, FontIcon, Separator, IDropdownOption, hoistStatics, elementContainsAttribute } from "office-ui-fabric-react";
import { hoistUpdateName } from "./../../api";
import { FormattedMessage } from "react-intl";
import { StatusOptions, ICustomData } from "./../../views/hoist/Hoists";
import { useUserRoles } from "./../../api-authorization/useUserRoles";
import { getProductType } from "./../../util/helper";
import ImageType from "./../ImageType";

const iconClass = mergeStyles({
    fontSize: 18,
    height: 18,
    width: 18,
    color: "black",
    cursor: "pointer",
    marginLeft: "8px",
    marginTop:"7px"
  });

  const iconClass2 = mergeStyles({
    fontSize: 18,
    height: 18,
    width: 18,
    color: "black",
    cursor: "pointer",
    marginLeft: "8px",
    marginTop:"-5px"
  });


interface IEditIcon{
    onClick: (eventEdit:string) => void;
}

interface IBase {
    item?:Hoist;
}

interface IStatusIcons extends IBase {
    item?:Hoist;
}


const serviceModules = (hoistDetails : HoistDetails ): string =>{
    let outPut = "";

    if (hoistDetails.modules.service)
    {
        outPut += "Service"
    }
    
    if (hoistDetails.modules.scaleClassIII)
    {
        outPut += outPut != "" ? ", " : "";
        outPut += "ScaleClassIII"
    }

    if (hoistDetails.modules.scale)
    {
        outPut += outPut != "" ? ", " : "";
        outPut += "Scale"
    }

    if (hoistDetails.modules.trainer)
    {
        outPut += outPut != "" ? ", " : "";
        outPut += "Trainer"
    }

    if (hoistDetails.modules.wifi)
    {
        outPut += outPut != "" ? ", " : "";
        outPut += "Wifi"
    }
    

    return outPut;
}


const EditIcon = (editProps:IEditIcon) => {
    const [editMode, setEditMode] = React.useState<boolean>(false);    

    const onClick = (eventEdit:string) => {

        setEditMode(!editMode);
        editProps.onClick(eventEdit);
    } 

    return(
  
    <span>
        {editMode ? 
        <>
            <FontIcon aria-label="Settings" iconName="saveAs" className={iconClass} onClick={() => onClick("save")}/>
            <FontIcon aria-label="Settings" iconName="cancel" className={iconClass} onClick={() => onClick("cancel")}/>
        </>
        :
            <FontIcon aria-label="Settings" iconName="edit" className={iconClass2} onClick={() => onClick("edit")}/>
        }
    </span>
    
    );
}

export interface Props 
{
    title?: string;
    hoist: Hoist;
    reload: () => void;
}

const GeneralInfoView = (props: Props) =>
{
    const {hoist} = props;
    const [state, details, refresh] = useAuthorizedFetch<HoistDetails>(hoist && `${API_HOISTS}/${hoist.hoistSerialNo}`);
    const [editMode, setEditMode] = React.useState<string>("cancel");

    const [displaySerialNo, setDisplaySerialNo] = React.useState<string>(hoist.displaySerialNo);
    const [hardwareName, setHardwareName] = React.useState<string | undefined>(hoist.hardwareName);
    const [hoistSerialNoName, setHoistSerialNoName] = React.useState<string>(hoist.hoistSerialNo);
    const [displayName, setDisplayName] = React.useState<string>(hoist.displayName);
    const [edited, setEdited] = React.useState<string>("");

    const roles = useUserRoles();
    

    const onEditClick = async (eventEdit:string) => {
        setEditMode(eventEdit);

        if (eventEdit === "save"){
            let saveValue = displayName;
            const res = await hoistUpdateName(hoist.hoistSerialNo, {name:saveValue});
            setDisplayName(saveValue);
            hoist.displaySerialNo = currentHoistName();
            setDisplaySerialNo(hoist.displaySerialNo);
            props.reload();
        }else
        {
            setDisplayName(hoist.displaySerialNo);
        }
    }

    const onNameChange = React.useCallback((ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDisplayName((ev.target as HTMLInputElement)?.value);
    }, []);

    const StatusIcons = (status: IStatusIcons) => {
        const options = StatusOptions as IDropdownOption[];
        const item = status.item?.status! === undefined ? '0' : status.item?.status!.toString();
        const data = options.find(x => x.key === item)?.data as ICustomData;

        return (
            <div className='customOption'>
            <div className={data.name} style={{ backgroundColor: data.color }}></div>
          </div>
        );
    }

    const currentHoistName = () =>{
        let outputStr = "";

        if (displayName !== ""){
            outputStr = displayName;
            setEdited("(edited)");
        }
        else if (hardwareName !== "" && hardwareName !== undefined){
                outputStr = hardwareName ;
                setEdited("");
        }
        else {
            outputStr = hoistSerialNoName;
            setEdited("");
        }

        return outputStr;
    }

    useEffect(() => {
        const callback = async () => {
            currentHoistName();
      }
        callback();
      }, []);


    const convertNA = (str:string | undefined | null):string => {

        if (str === undefined || str === "" || str === null){
            return "N/A";
        }
        else{
            return str;
        }

    }    

    const usedAdress = () => {
        let retAddress = "";

        let autoAddress =  hoist.autoAddress !== undefined && hoist.autoAddress !== null ?  hoist.autoAddress : "";
        let hoistAddress = hoist.hoistAddress !== undefined && hoist.hoistAddress !== null ?  hoist.hoistAddress : "";
        let localisationAddress = hoist.locationAddress !== undefined && hoist.locationAddress !== null ?  hoist.locationAddress : "";

        if (hoist.useAutoAddress === true && autoAddress !== "") 
        {
            retAddress = autoAddress; // 1
        }
        else if (hoist.useAutoAddress === true && autoAddress === "" && hoistAddress !== ""){
            retAddress = hoistAddress; // 2
        }
        else if (hoist.useAutoAddress === false &&  hoistAddress !== ""){
            retAddress = hoistAddress; //2
        }
        else if (hoist.useAutoAddress === false === false && hoistAddress === ""){
            retAddress = localisationAddress; //3
        }



        return retAddress;
    }


    return(
        <>
            <div style={{display:"flex", maxHeight: "260px"}}>
            <div style={{width:"40%", marginRight:"25px"}}>
                <ImageType type={hoist.liftType1}/>
            </div>
            <div >
            {state === "success" && details ?
            <>
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>Name {edited}</div>
                        <div style={{display:"flex"}}>
                        {editMode === "edit" ? <TextField value={displayName} onChange={onNameChange}/> :  displaySerialNo }
                        
                        {roles?.includes("Administrator") ?
                            <EditIcon onClick={(eventEdit:string) => onEditClick(eventEdit)}/>
                            : null
                        }
                        </div>
                    </div>                
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>Serial Number</div>
                        <div>{convertNA(hoist.hoistSerialNo)}</div>
                    </div>
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>Model</div>
                        <div>{getProductType(hoist.liftType1)}</div>
                    </div>
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>Safe Working Load (SWL)</div>
                        <div>{details!.swl} kg / {Math.round(details!.swl * 2.2)} lbs</div>
                    </div>
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>Modules</div>
                        <div>{convertNA(serviceModules(details))}</div>
                    </div>
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>Next Service</div>
                        <div>{convertNA(details.nextService)}</div>
                    </div>
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>Last Service</div>
                        <div>{convertNA(details.lastService)}</div>
                    </div>
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>First Lift</div>
                        <div>{convertNA(details.firstLift)}</div>
                    </div>                    
                    <div style={{display:"flex", marginTop:"10px"}}>
                        <div style={{width:"175px", fontWeight:"bold"}}>Status</div>
                        <div><StatusIcons item={hoist} /></div>
                    </div>                   

                </>
: null
}
            
            </div>
            </div>
            <div style={{display:"flex", maxHeight: "260px"}}>
                            <div style={{width:"48%"}}>
                                <Separator><Text variant="large">Location</Text></Separator>
                                <div style={{display:"flex", marginTop:"10px"}}>
                                    <div style={{width:"175px", fontWeight:"bold"}}>Location</div>
                                    <div>{convertNA(usedAdress())}</div>
                                </div>
                                <div style={{display:"flex", marginTop:"10px"}}>
                                    <div style={{width:"175px", fontWeight:"bold"}}>Organizational location</div>
                                    <div>{convertNA(hoist.locationName)}</div>
                                </div>

                            </div>
                            

                        <div style={{width:"4%"}}></div>
                        <div style={{width:"48%"}}>
                            <Separator><Text variant="large">Communication</Text></Separator>
                            <div style={{display:"flex", marginTop:"10px"}}>
                                    <div style={{width:"175px", fontWeight:"bold"}}>Last commication</div>
                                    <div>
                                    {
                        ( details?.communication.latest === undefined  || details?.communication.latest === null) ? (
                            <Text>N/A</Text>
                        ) : (
                            <Text><FormattedMessage defaultMessage="{date, date, ::yyyyMMdd Km} UTC" values={{ date: new Date(details?.communication.latest!) }} /></Text>
                        )
                    }

                                    </div>
                                </div>
                                <div style={{display:"flex", marginTop:"10px"}}>
                                    <div style={{width:"175px", fontWeight:"bold"}}>Last used</div>
                                    <div>
                                    {
                                        ( details?.communication.latestActivation === undefined || details.communication.latestActivation === null) ? (
                                            "N/A"
                                        ) : (
                                            <FormattedMessage defaultMessage="{date, date, ::yyyyMMdd Km} UTC" values={{ date: new Date(details!.communication.latestActivation) }} />
                                        )
                    }

                                    </div>
                                </div>
                        </div>
            </div>
        </>
        
        
        )
}

export default GeneralInfoView;