import React, { useState, useMemo, useCallback} from "react";
import { IColumn, DetailsList, ConstrainMode, ISelection, Selection, MessageBarType, IContextualMenuItem, Dialog, DialogFooter, PrimaryButton, DefaultButton, CheckboxVisibility } from "office-ui-fabric-react";
import { API_ADMIN_USERS, API_ADMIN_ROLES, adminAssignUsersToRole, adminResendInvites, adminDeleteUser } from "../../../api";
import { useAuthorizedFetch } from "../../../hooks/useFetch";
import PasswordPolicyPanel from "./PasswordPolicyPanel";
import InviteUsersPanel from "./InviteUsersPanel";
import LoadingIndicator from "../../../components/ui/LoadingIndicator";
import styled from "styled-components";
import { NeutralColors } from "@uifabric/fluent-theme/lib/fluent/FluentColors";
import { useNotificationService } from "../../../hooks/useNotificationService";
import UserListCommandBar from "./UserListCommandBar";
import IconMenuButton from "../../../components/ui/IconMenuButton";
import { userInfoAtom } from "../../../atoms/user";
import { useRecoilValue } from "recoil";

const renderYesNo = (item?: any, index?: number, column?: IColumn) => 
    <span>{ item![column!.fieldName!] ? "Yes" : "No" }</span>;

const confirmedRender = (item?: User) =>
    <span>
    {
        !item?.confirmed ? "Email sent" :
        item?.lockedOut ? "Locked Out" :
        "Active"
    }
    </span>;


const AdminBadgeInner = styled.span`
    color: ${() => NeutralColors.gray70};
`;
const AdminBadge = ({ user }: { user: User }) => (
    (user.roles[0] === "Administrator") ? (
        <AdminBadgeInner>(admin)</AdminBadgeInner>
    ) : null
)

const staticColumns: IColumn[] = [
    // { key: "menu", name: "", minWidth: 50, onRender: (user: User) => <UserContextMenu user={user} /> }
    { fieldName: "fullName", key: "fullName", name: "Name", minWidth: 100, onRender: (item: User) => (
        <span>{item.fullName} <AdminBadge user={item} /></span>
    ) },
    { fieldName: "email", key: "email", name: "Email", minWidth: 200 },
    { fieldName: "confirmed", key: "confirmaed", name: "Status", minWidth: 90, onRender: confirmedRender },
    { fieldName: "roles", key: "roles", name: "Role", minWidth: 80 },
    // { fieldName: "lockedOut", key: "lockedOut", name: "Locked Out", minWidth: 90, onRender: renderYesNo },
    { fieldName: "twoFactorEnabled", key: "twoFactorEnabled", name: "Two Factor", minWidth: 90, onRender: renderYesNo },

]

export interface User
{
    fullName: string;
    email: string;
    confirmed: boolean;
    lockedOut: boolean;
    twoFactorEnabled: boolean;
    roles: string[];
}

export interface Role
{
    name: string;
    id: string;
}

export default function UserManagement()
{
    const { addNotification } = useNotificationService();
    const [loadStatus, users, refresh] = useAuthorizedFetch<User[]>(API_ADMIN_USERS);
    const [passwordPolicyVisible, setPasswordPolicyVisible] = useState(false);
    const [usersPanelVisible, setUsersPanelVisible] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState<User | undefined>(undefined);
    const [, roles] = useAuthorizedFetch<Role[]>(API_ADMIN_ROLES);
    const userInfo = useRecoilValue(userInfoAtom);


    const selection: ISelection<User> = new Selection<User>({
        getKey: user => user.email,
        onSelectionChanged: () => {
            // setSelectedUserNames(selection.getSelection().map((x: User) => x.email));
            setSelectedUsers(selection.getSelection());
            // setHasSelection(selection.getSelectedCount() > 0);
        }
    }) as any;

    const onAssignRoleClick = (role: Role) => {
        (async () => {
            const res = await adminAssignUsersToRole(role.id, selectedUsers.map(x => x.email));
            if(res.ok)
            {
                addNotification("Succesfully assigned users.");
                refresh();
            }
            else
            {
                addNotification("Could not assign users. Something went wrong.", MessageBarType.error);
            }
        })();
    };

    const onSendInvitesClick = () => {
        adminResendInvites(selectedUsers.map(x => x.email)).then(res => {
            if (res.ok)
            {
                addNotification("Invites have been sent.");
            }
            else
            {
                return Promise.reject();
            }
        })
        .catch(() => addNotification("Could not send invites. Something went wrong.", MessageBarType.error));
    }

    const onInviteClick = () => setUsersPanelVisible(true);
    const onPasswordPolicyClick = () => setPasswordPolicyVisible(true);

    const onMoreClick = useCallback((user: User, command: string) => 
    {
        switch(command)
        {
            case "delete":
                setDeleteDialogVisible(user);
                break;
        }
    }, []);

    const onUserDeleteConfirm = (user: User) => 
    {
        setDeleteDialogVisible(undefined);
        adminDeleteUser(user.email).then(res => {
            if(res.ok)
            {
                addNotification(`User ${user.email} was successfully deleted.`);
                refresh();
            }
            else
            {
                addNotification(`Could not delete user ${user.email}. Please try again later.`, MessageBarType.error);
            }
        });
    }

    const columns = useMemo(() => [
        ...staticColumns,
        {
            name: "",
            key: "menu",
            minWidth: 40,
            onRender: (user: User ) => {
                
                const menuItems: IContextualMenuItem[] = [
                    {
                        key: "delete",
                        text: "Delete user",
                        iconProps: { iconName: "delete" },
                        onClick: () => onMoreClick(user, "delete")
                    }
                ];

                return (
                    userInfo?.email != user.email ? <IconMenuButton menuItems={menuItems} iconProps={{ iconName: "more" }} /> : null
                )
            }
        }
    ], [onMoreClick, userInfo]);

    if(loadStatus === "idle" || loadStatus === "fetching")
    {
        return <LoadingIndicator />;
    }

    return (
        <>
            <Dialog hidden={deleteDialogVisible === undefined} onDismiss={() => setDeleteDialogVisible(undefined)}
                dialogContentProps={{
                    title: "Delete User",
                    subText: `Are you sure you want to delete the user '${deleteDialogVisible?.email}'? The user will no longer have any access to the site.`
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => onUserDeleteConfirm(deleteDialogVisible!)}>Delete</PrimaryButton>
                    <DefaultButton onClick={() => setDeleteDialogVisible(undefined)}>Cancel</DefaultButton>
                </DialogFooter>
            </Dialog>

            <UserListCommandBar 
                selectedUsers={selectedUsers}
                roles={roles}
                onAssignRoleClick={onAssignRoleClick}
                onInviteClick={onInviteClick}
                onPasswordPolicyClick={onPasswordPolicyClick}
                onSendInvitesClick={onSendInvitesClick}
                />
            <DetailsList 
                items={users ?? []} 
                columns={columns} 
                constrainMode={ConstrainMode.horizontalConstrained} 
                checkboxVisibility={CheckboxVisibility.always}
                selection={selection as any} />
            <PasswordPolicyPanel 
                visible={passwordPolicyVisible} 
                onDismiss={() => setPasswordPolicyVisible(false)} />

            {
                (usersPanelVisible) ? (
                    <InviteUsersPanel visible={usersPanelVisible} onDismiss={() => setUsersPanelVisible(false)} onUsersInvited={refresh} />
                ) : null
            }
        </>
    );
}
