import { useEffect } from "react";
import { API_ACCOUNT } from "../api";
import { useSetRecoilState } from "recoil";
import { mfaCompliance } from "../atoms/system";
import authService from "./AuthorizeService";
import { useRecoilValue } from "recoil";
import { authenticatedAtom } from "../atoms/user";
import { useLocation } from "react-router-dom";

export interface Props
{

}

const checkMfaCompliance = async () => 
{
    const token = await authService.getAccessToken();
    const res = await fetch(`${API_ACCOUNT}/mfa-validated`, { 
        method: "POST", 
        headers: {
            Authorization: `Bearer ${token}`
        } 
    });

    return res.ok;
}


function CheckMfaCompliance(props: Props)
{
    const setMfaCompliance = useSetRecoilState(mfaCompliance);
    const isAuthenticated = useRecoilValue(authenticatedAtom);
    const location = useLocation();

    useEffect(() => 
    {
        let cancelled = false;

        if(isAuthenticated)
        {
            checkMfaCompliance().then(value => {
                if(!cancelled) 
                {
                    setMfaCompliance(value);
                }
            });
        }
        else
        {
            setMfaCompliance(undefined);
        }
        return () => { cancelled = true };
    }, [location, isAuthenticated, setMfaCompliance]);


    return null;
}

export default CheckMfaCompliance;