import React, { useCallback } from "react";
import { Location } from "../../../api";
import { Panel, PrimaryButton, DefaultButton, ProgressIndicator, } from "office-ui-fabric-react";
import { PanelContent, PanelButtons } from "./Dialog.styles";
import { DialogProps } from "../../../components/DialogProps";

export interface Props extends DialogProps
{
    location?: Location;
    onRemove?: (location: Location) => void;
}

function LocationRemoveDialog(props: Props)
{
    const { location, visible, onDismiss, onRemove, busy } = props;

    const onRenderFooterContent = useCallback(() => {
        const onClick = () => (location && onRemove) && onRemove(location);
        return (
            <PanelButtons>
                <PrimaryButton disabled={busy} onClick={onClick}>Remove</PrimaryButton>
                <DefaultButton disabled={busy} onClick={onDismiss}>Cancel</DefaultButton>
            </PanelButtons>
        );
    }, [onDismiss, location, onRemove, busy]);

    const locationName = location?.label ?? "<unknown>";

    return (
        <Panel 
            headerText="Remove Location"
            isOpen={visible}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom
        >
            {busy ? <ProgressIndicator description="Removing location..." /> : null}
            <PanelContent>
                <p>Are you sure you want to completely remove location '<em>{locationName}</em>' and all of its sub locations?</p>
                <p>All hoists assigned to the affected locations will be unassigned.</p>
            </PanelContent>
        </Panel>
    );
}

export default LocationRemoveDialog;