import React, { useRef, useState, useEffect, useCallback } from "react";
import { useAuthorizedFetch } from "./../../hooks/useFetch";
import { IObjectWithKey, DetailsList , Selection, SelectionMode, IColumn, IChoiceGroupOption, ChoiceGroup, Text, getEdgeChromiumNoHighContrastAdjustSelector } from "office-ui-fabric-react";
import {intl} from "../../i18n";
import TagPopUp, {IBounding, TagContainer, ITags, ISaveTags, ITrainingData} from "../../components/tagpopup/tagpopup"


const dtf = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
});

const formatDate = (item?: ITraining, index?: number, column?: IColumn) => 
{
    return (item === undefined) ? "" : dtf.format(new Date(item.datetime));
}

export interface ITraining {
    trainingId: number;
    hoistSerialNo: string;
    datetime: string;
    StartVoltage: number;
    EndVoltage: number
    weight: number;
    tags: string;
    displaySerialNo: string;
    locationName: string;
}  

interface IProps {
    data: ITraining[];
    suggestionsTags: ITags[];
    saveTag: (saveTags:ISaveTags) => void;
    onClickEvent : (itemData:ITrainingData) => void;
    savedTags: ITags[];
    onDelete: (tag:ITags, trainingId:number) => void;
    reloadData: () => void;
}


const TrainingList = (props:IProps) =>{
    const [open, setOpen] = useState(false);
    const [topPos, setTopPos] = useState();
    const [getBounding, setGetBounding] = useState<IBounding>();
    const [getItemData, setItemData] = useState<ITrainingData>();

    const columns:IColumn[] = [
        {key: "column0", name: "", fieldName: "", minWidth: 25, maxWidth: 25, isResizable: false},
        {key: "column1", name: "Name", fieldName: "displaySerialNo", minWidth: 100, maxWidth: 100, isResizable: true},
        {key: "column2", name: "Serial Number", fieldName: "hoistSerialNo", minWidth: 100, maxWidth: 100, isResizable: true},
        {key: "column3", name: "Location", fieldName: "locationName", minWidth: 100, maxWidth: 100, isResizable: true},
        {key: "column4", name: "Date", fieldName: "datetime", minWidth: 150, maxWidth: 150, isResizable: true, onRender:formatDate},
        {key: "column5", name: "Duration", fieldName: "duration", minWidth: 100, maxWidth: 100, isResizable: true},
        {key: "column6", name: "Weight", fieldName: "weight", minWidth: 100, maxWidth: 100, isResizable: true},
        {key: "column7", name: "Tags", fieldName: "tags", minWidth: 100, maxWidth: 100, isResizable: true, onRender: (item, _, col) => (<TagContainer item={item} open={(bounding,item) => openPopUp(bounding,item)}/>)}
      ];

    const openPopUp = (bounding:IBounding, item: ITrainingData) => {
        setGetBounding(bounding);
        setItemData(item);
        props.onClickEvent(item);
        setOpen(true);
    }

    const closePopup = () => {
        setOpen(false);
        props.reloadData();
    }

    const onDelete = (tag:ITags, trainingid: number) => {
        props.onDelete(tag,trainingid);
    }

    const reloadData = () => {
    }


return(
    <>
            <DetailsList
                disableSelectionZone={true}
                columns={columns}
                items={props.data}
                checkboxVisibility={2}
            />
            
            {open ? 
               <TagPopUp 
                    close={() => closePopup()} 
                    bounding={getBounding}
                    item={getItemData} 
                    suggestionsTags={props.suggestionsTags} 
                    saveTag={(saveTags) => props.saveTag(saveTags)}
                    savedTags={props.savedTags}
                    onDelete={(tag, trainingId) => onDelete(tag, trainingId)}
                    reloadData={() => reloadData()}
                />
            :null}
    </>

)

}

export default TrainingList;

