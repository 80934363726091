import { atom } from "recoil";
import { Profile } from "oidc-client";

export interface UserInfo
{
    id: string;
    email: string;
    phoneNumber?: string;
    jobTitle?: string;
    fullName?: string;
}

export const mfaAtom = atom<boolean>({
    key: "user.mfa_enabled",
    default: false
});

export const authenticatedAtom = atom<boolean | undefined>({
    key: "user.authenticated",
    default: undefined
});


export const userProfileAtom = atom<Profile | undefined>({
    key: "user.profile",
    default: undefined
})

export const userInfoAtom = atom<UserInfo | undefined>({
    key: "user.info",
    default: undefined
});
