import React, {useRef, useEffect, useCallback, useState} from "react";
import { useAuthorizedFetch } from "./../../hooks/useFetch";
import ReactHtmlParser from 'react-html-parser';
import { getTheme, mergeStyleSets, FontWeights, CommandBar, ICommandBarItemProps, IButtonProps, initializeIcons } from "office-ui-fabric-react";
import { FaPrint } from "react-icons/fa"
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



interface IEula {
    content : string;
    agree : boolean;
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const EulaView = () => {
    const [eulaStatus, eula, refresh] = useAuthorizedFetch<IEula>("api/eula/geteulaview");
    const printRef = useRef<HTMLDivElement>(null);
    const [numPages, setNumPages] = useState(null);

    const print = ()  =>{
        if (printRef.current !== null){
          window.frames[0].document.title = "End-User License Agreement (EULA)";
          window.frames[0].document.body.innerHTML = printRef.current.innerHTML;
          window.frames[0].window.focus();
          window.frames[0].window.print();
        }
      }    
      const _items: ICommandBarItemProps[] = [
        {
          key: 'print',
          text: 'Print',
          cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
          iconProps: { iconName: 'Print' },
          onClick: () => { print() }        
        },
      ];
    

      function onDocumentLoadSuccess( numPages:any ) {
        setNumPages(numPages);
      }

    return( 
        <React.Fragment>

            {/*<CommandBar items={_items} ariaLabel="Use left and right arrow keys to navigate between commands" />*/}
            
                <div ref={printRef}>
                  <div className={contentStyles.header}>
                      <span id={"eula-title"}>End-User License Agreement (EULA)</span>
                  </div>
                  <div className={contentStyles.body} id='PrintContent'>
                      {/*eula != undefined ? ReactHtmlParser(eula.content) : null*/}
                      {eula != undefined ? <embed src="/api/eula/eula" width="100%" height="800px" /> : null}
                      {/*</div>Document file="https://localhost:44398/api/eula/eula">
                      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
                      </Document>*/}

                  </div>
                </div>
        </React.Fragment>
    )
}

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      marginLeft:"100px",
      marginRight:"100px",
      hight: "600px"
    },
    header: [
      theme.fonts.large,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  
  



export default EulaView;