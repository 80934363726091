import React, { useCallback } from "react";
import { Hoist } from "./useLocationHoists";
import { PrimaryButton, DefaultButton, Panel, Stack, StackItem, ProgressIndicator } from "office-ui-fabric-react";
import { DialogProps } from "../../components/DialogProps";

export interface Props extends DialogProps
{
    hoists?: Hoist[];
    onUnassign: (hoists: Hoist[]) => void
}

function UnassignDialog(props: Props)
{
    const { visible, onDismiss, onUnassign, hoists: items, busy } = props;

    const onRenderFooterContent = useCallback(() => {
        const okClicked = () => items && onUnassign(items);
        return (
            <div>
                <PrimaryButton disabled={busy} style={{marginRight: 8}} onClick={okClicked}>Unassign</PrimaryButton>
                <DefaultButton disabled={busy} onClick={onDismiss}>Cancel</DefaultButton>
            </div>
        );
    }, [onUnassign, onDismiss, items, busy]);

    return (
        <Panel
            headerText="Unassign hoists?"
            isOpen={visible}
            onDismiss={onDismiss}
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
            onRenderFooterContent={onRenderFooterContent}
        >
            {busy ? <ProgressIndicator description="Assigning location..." /> : null}
            <p>Are you sure you want to remove the following hoists from their current locations?</p>
            <Stack tokens={{childrenGap: 8}} style={{marginTop: 16}}>
            {
                items?.map(hoist => (
                    <StackItem key={hoist.hoistSerialNo}>{hoist.displaySerialNo}</StackItem>
                ))
            }
            </Stack>
        </Panel>
    );
}

export default UnassignDialog;