import * as React from "react";
import { Checkbox, Label, Icon, PrimaryButton, DefaultButton  } from "office-ui-fabric-react";
import { useRef, useEffect, useCallback, useState} from "react"
import { Hoist } from "./../../views/hoist/useLocationHoists";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { getlocationHoistInfo, userProfile } from "../../api";
import { string } from "yup";
import TreeView, { Node } from "../../components/treevew/TreeView";
import { useLocationTree } from "../../hooks/useLocationTree";
import { Location } from "../../api";
import { usePlacesWidget } from "react-google-autocomplete";
import { updateHoistPlace, IUpdateHoistPlace, GetLocationsPlaceAsync, updateLocationPlace, IUpdateLocationPlace, updateHoistAutoAddress, IUpdateAutoAddress } from "./../../api";
import './../../fixGoogle.css';
import house from './../../assets/Adresse_green.png';
import house_yellow from './../../assets/Adresse_yellow.png';

import { useUserRoles } from "./../../api-authorization/useUserRoles";
import { FontSizes } from "@uifabric/fluent-theme";

export interface Props 
{
    title?: string;
    hoist: Hoist;
    reload: () => void;
}

export interface IGoogleMapProps{
    center: google.maps.LatLngLiteral;
    zoom: number;
}

export interface IGetlocationHoistInfo{
    hoistPostion: string;
    hoistAddress: string;
}

type locationType = "organizational" | "hoist" | "none"

export interface IShowLocation{
  type?: locationType;
}


export interface IHoistGoogleInfo
{
    hoistSerialNo: string;
    hoistPosition: string;
    hoistAddress: string;
    locationId: string;
    locationPoistion: string;
    locationAddress: string;
    addressType: number;
    locationName: string;
    userAutoAddress: boolean;
    autoAddress: string;
    autoPosition: string;
}




const render = (status: Status) => {
    if (status === Status.FAILURE) return <div>Error</div>;
    return <></>;
  };

function GoogleMapComponet(props:IGoogleMapProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();


    useEffect(() => {
        if (ref.current) {
            let map = new window.google.maps.Map(ref.current, {zoom:props.zoom, center:{ lat: props.center.lat, lng: props.center.lng } });
            new google.maps.Marker({
                position:  { lat: props.center.lat, lng: props.center.lng },
                map,
                title: "",
              });
              setMap(map);

        }
    },[props]);
  

    return <div style={{height:"100%"}} ref={ref} id="map" />;
  }


  const  GoogleMap  = (props:IGoogleMapProps) => {
    return(<Wrapper apiKey={"AIzaSyB6bnmHKloFJNJABRXBdTduNuepdToHMqI"} render={render} language={"en"} libraries={['places']}>
        <GoogleMapComponet zoom={props.zoom} center={{ lat: props.center.lat, lng: props.center.lng}}></GoogleMapComponet>
      </Wrapper>)      
  };

export interface IPosition{
    lag: string;
    lng: string;
}

export interface IGooglePlaceInfo
{
    address?: string;
    lat?: string;
    lng?: string;
}

const treeview: React.CSSProperties = {
  marginLeft: 0,
  marginTop: 16
}
const assignButton: React.CSSProperties = { marginRight: 8 };
const styles =  { treeview, assignButton };

/******************************** AutocompletePlaceCustom  ****************************************** */



export interface IAutocompletePlaceCustom
{
    onSelected: (info:IGooglePlaceInfo) => void;
    setDefaultAdress? : string;
}


const AutocompletePlaceCustom = (pops:IAutocompletePlaceCustom) => {
  const [googlePlace, setGooglePlace] = useState<IGooglePlaceInfo | undefined>();
  const [adressField, setAdressField] = useState<string>(pops.setDefaultAdress!);

  const onChangeAdress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdressField(event.target.value);

    if (event.target.value === ""){
      setGooglePlace({address: "", lat: "", lng: ""})
      pops.onSelected({address: "", lat: "", lng: ""});

    }
  }


  const { ref, autocompleteRef } = usePlacesWidget<any>({
    apiKey:"AIzaSyB6bnmHKloFJNJABRXBdTduNuepdToHMqI",
    language:"en",
    options:{types:["geocode", "establishment"]},
    onPlaceSelected: (place) => {
      if (place.formatted_address !== undefined){
        setGooglePlace({address: place.formatted_address, lat: place.geometry!.location!.lat().toString(), lng: place.geometry!.location!.lng().toString()})
        setAdressField(place.formatted_address ?? "");
        pops.onSelected({address: place.formatted_address, lat: place.geometry!.location!.lat().toString(), lng: place.geometry!.location!.lng().toString()});
      }
    }
  });
return (

  <div style={{position:"relative"}}>
  <Label>Address</Label>
  <input onChange={onChangeAdress} value={adressField ?? ""} ref={ref} style={{ marginTop:"0px", height:"32px", borderColor:"rgb(96, 94, 92)", borderWidth:"thin", padding:"0px 8px", borderRadius:"0px", display: "block", boxSizing: "border-box", width:"100%" }} ></input>
</div>


)
  


}
/*************************************************************************** */

const LocationView = (props: Props) =>
{
    const 
    [position, setPosition] = React.useState<IPosition>({lag:"0", lng:"0"});
    const [showLocation, setShowLocation] = React.useState<IShowLocation>({type:"none"});
    const [selectedNode, setSelectedNode] = React.useState<Node<Location>>();
    const [locations] = useLocationTree();
    const onNodeClick = useCallback((node: Node<Location>) => {setSelectedNode(node)}, [selectedNode]);
    const [googlePlace, setGooglePlace] = useState<IGooglePlaceInfo | undefined>();
    const [hoistGoogleInfo, setHoistGoogleInfo] = useState<IHoistGoogleInfo>();
    const [refresh, setRefresh] = useState<boolean>();
    const [addressLocation, setAddressLocation] = useState<string>("");
    const [addressHoist, setAddressHoist] = useState<string>("");
    const [addressAuto, setAddressAuto] = useState<string>("");
    const [addressHoistManuel, setAddressHoistManuel] = useState<string>("");
    const [locationName,setLocationName] = useState<string>("");
    const [isChecked, setIsChecked] = useState(false);
    const positionRef = React.useRef<IPosition>({lag:"",lng:""});
    const roles = useUserRoles();


  const getInitData = async () => {
    let res = await GetLocationsPlaceAsync(props.hoist.hoistSerialNo);
    let posArray:string[] = [];

    if(res.ok)
    {
      const ret:IHoistGoogleInfo = await res.json();
      setHoistGoogleInfo(ret);
      
      //show always both address
      //1 = show hejs address position on map, 2 = show location address position on map


      if (ret.addressType === 1)
      {
        posArray = ret.autoPosition.split(",");
      }
      else if (ret.addressType === 2)
      {
        posArray = ret.hoistPosition.split(",");
      }
      else if (ret.addressType === 3)
      {
        posArray = ret.locationPoistion.split(",");
      }

        setAddressLocation(ret.locationAddress);
        setAddressHoist(ret.hoistAddress);
        setLocationName(ret.locationName);  
        setAddressAuto(ret.autoAddress);

        setPosition({lag:posArray[0],lng:posArray[1]});
        positionRef.current = {lag:posArray[0],lng:posArray[1]}

      setIsChecked(ret.userAutoAddress);


    }
    else
    {
    }

  }



    useEffect(() => {
      const callback = async () => {
         getInitData();
      //setRefresh(false);
    }

      callback();

    }, []);


    useEffect(() => {
        const callback = async () => {
          
          const id =  selectedNode !== undefined ? parseInt(selectedNode.id) : 0

          if (id !== 0){
            let res = await getlocationHoistInfo(id);
            if(res.ok)
            {
              const ret:IGetlocationHoistInfo = await res.json();
              if (ret.hoistPostion !== null && ret.hoistPostion !== ""){
                const posArray = ret.hoistPostion.split(",");
                setPosition({lag:posArray[0],lng:posArray[1]});
                console.log(ret.hoistAddress);
              }
              else
              {
                setPosition({lag:"",lng:""});
              }
            }
            else
            {
            }  
        }
        };
        callback();
    }, [selectedNode]);



    const onMenuClick = (type:IShowLocation) => {
      setShowLocation(type);
      setRefresh(true);
    }

    const onCancelLocation = () => {
    }
      const onSelectGooglePlace =  (info:IGooglePlaceInfo) => {
      setGooglePlace(info);
      setPosition({lag:info.lat!, lng:info.lng!});
    }

    const saveHoistPlace = async () => {

      let saveData:IUpdateHoistPlace = {address : googlePlace!.address!, lat: googlePlace!.lat!, lng: googlePlace!.lng!, hoistId: props.hoist.hoistSerialNo}
      let res = await updateHoistPlace(saveData);
      
      if(res.ok)
      {
//        let hoistGoogleInfoEdit = hoistGoogleInfo;
//        hoistGoogleInfoEdit!.hoistAddress = saveData.address
//        if (saveData.address !== ""){
//          hoistGoogleInfoEdit!.locationAddress = "";
//        }
//        setHoistGoogleInfo(hoistGoogleInfoEdit);
      }
      else
      {
          console.log("Save updateHoistPlace error")
      }

      onMenuClick({type:"none"})
      await getInitData();
      //setRefresh(true);
      props.reload();

    };


    const saveLocationPlace = async () => {

      let saveData:IUpdateLocationPlace = {hoistNo: props.hoist.hoistSerialNo, locationId: parseInt(selectedNode!.id) as number}
      let res = await updateLocationPlace(props.hoist.hoistSerialNo, saveData);
      
      if(res.ok)
      {

      }
      else
      {
          console.log("Save updateHoistPlace error")
      }

      onMenuClick({type:"none"})
      await getInitData();
      
      //setRefresh(true);
      props.reload();

    };


    const onChangeAuto = useCallback(async (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): Promise<void> => {
        let posArray:string[] | undefined = [];
        setIsChecked(!!checked);
        let data:IUpdateAutoAddress = {updateAutoAddress: !!checked}
        let res = await updateHoistAutoAddress(props.hoist.hoistSerialNo, data);

        if(res.ok)
        {
          await getInitData();
         // setRefresh(true);
        }
        else
        {
        }
      },
      [],
    );


    return(
        <>
        
            <div style={containerLocation}>
                
                { showLocation.type === "none" ? 
                <div style={{height:"100%", width:"40%"}}>
                    <ul>
                      {
                        <li style={LoocationMenu}>
                        <Label style={{display:"contents", fontSize:"16px"}}>Automatic address </Label>
                        <div style={{width:"100%", marginTop:"8px"}}>
                        {roles?.includes("Administrator") &&  props.hoist.hoistSerialNo.length <= 10 ?       
                        <>
                          <div style={{display:"inline-block"}}><Checkbox onChange={onChangeAuto} checked={isChecked}/></div><div style={{display:"inline-block", marginLeft:"5px"}}><Label>Use automatic localisation</Label></div>
                        </>
                          : 
                        <>
                        <div style={{display:"inline-block"}}><Checkbox onChange={onChangeAuto} checked={isChecked} disabled={true}/></div><div style={{display:"inline-block", marginLeft:"5px"}}><Label>Use automatic localisation</Label></div>
                       </>
                        }
                        </div>
                            <div style={{marginTop:"10px", fontWeight:"bold"}}>{hoistGoogleInfo?.addressType === 1 ? <img src={house} height="13" style={{marginRight:"2px"}}/>: null}{addressAuto}</div>
                        </li>
                      } 
                        <li style={LoocationMenu2}>
                          {roles?.includes("Administrator") ? 
                            <a style={links} onClick={() => onMenuClick({type:"hoist"})}><Label style={{cursor: "pointer", display:"contents", fontSize:"16px"}}>Change hoist address </Label><span><Icon iconName="OpenEnrollment" style={{ fontSize: 16 }}/></span></a>
                            :
                            <Label style={{display:"contents", fontSize:"16px"}}>Hoist location :</Label>
                          }
                            <div style={{marginTop:"10px", fontWeight:"bold"}}>{hoistGoogleInfo?.addressType === 2 ? <img src={house_yellow} height="13" style={{marginRight:"2px"}}/>: null}{addressHoist}</div>
                        </li>
                        <li style={LoocationMenu2}>    
                            {roles?.includes("Administrator") ? 
                              <a style={{cursor: "pointer"}} onClick={() => onMenuClick({type:"organizational"})}><Label style={{cursor: "pointer", display:"contents", fontSize:"16px"}}>Change organizational location </Label><span><Icon iconName="OpenEnrollment" style={{ fontSize: 16 }}/></span></a>
                              :
                              <Label style={{display:"contents", fontSize:"16px"}} >Organizational location :</Label>
                            }              
                            <div style={{marginTop:"10px", fontWeight:"bold"}}>{hoistGoogleInfo?.addressType === 3 && addressLocation !== "" ? <img src={house_yellow} height="13" style={{marginRight:"2px"}}/> : null}{locationName}</div>
                            <div style={{marginTop:"10px", fontWeight:"bold"}}>{addressLocation}</div>
                        </li>
                    </ul>



                </div>
                : <></>}

                {
                showLocation.type === "organizational" ?
                <div style={{height:"100%", width:"40%", position: "relative"}}>
                <TreeView disabled={undefined} style={styles.treeview} nodes={locations ?? []} onNodeClick={onNodeClick} activeNodeId={selectedNode?.id} />
                  <div style={{bottom: "0", position: "absolute", width: "100%", display: "flex", flexWrap: "nowrap"}}>
                    <PrimaryButton style={buttonSpace} onClick={() => saveLocationPlace()}>Save</PrimaryButton>
                    <DefaultButton style={buttonSpace} onClick={() => onMenuClick({type:"none"})}>Cancel</DefaultButton>
                  </div>
                </div>
                : <></>}
                {
                showLocation.type === "hoist" ?

                <div style={{height:"100%", width:"40%", position: "relative"}}>
                  
                  <AutocompletePlaceCustom onSelected={(info) => onSelectGooglePlace(info)} setDefaultAdress={hoistGoogleInfo?.hoistAddress}/>

                  <div style={{bottom: "0", position: "absolute", width: "100%", display: "flex", flexWrap: "nowrap"}}>
                    <PrimaryButton style={buttonSpace} onClick={() => saveHoistPlace()}>Save</PrimaryButton>
                    <DefaultButton style={buttonSpace} onClick={() => onMenuClick({type:"none"})}>Cancel</DefaultButton>
                  </div>
                </div>
                : <></>}


                <div style={{height:"100%", width:"60%"}}>
                  {position.lag !== undefined && position.lag !== "0" && position.lag !== "" ?
                  <GoogleMap zoom={15} center={{ lat:  parseFloat(position.lag), lng: parseFloat(position.lng) }}/>
                  :
                  <div style={{marginTop:"100px", textAlign: "center", fontWeight:"bold"}}>No address data</div>
                  }
                </div>
            </div>
        </>
        )
}

const containerLocation = {
    display: "flex",
    width:"100%",
    height: "510px"
}

const links = {
    cursor: "pointer",
 }
  
  const buttonSpace = {
    marginRight: "5px",
  }

  const LoocationMenu = {
    marginTop: "15px",
  }

  const LoocationMenu2 = {
    marginTop: "35px",
  }


export default LocationView;