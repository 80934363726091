import { Query } from "../Dashboard";
import { API_CHART_ACTIVATIONS_PER_DAY } from "../../../api/charts";
import { createChartComponent } from "./createChartComponent";
import { col } from "./utils";
import { IColumn } from "office-ui-fabric-react";


export interface Props
{
    query: Query;
}

let dtf = new Intl.DateTimeFormat(undefined, { dateStyle: "short" } as any);
const formatDate = (date: string) => dtf.format(new Date(date));
const mapActivationsPerDay = (data: { 
    [date: string]: number 
}) => ({
    labels: Object.keys(data).map(ds => {return formatDate(ds)}),
    values: Object.keys(data).map(x => data[x])
});

// function ActivationsOverTime(props: Props)
// {
//     const { query } = props;
//     const queryStr = toQueryString(query);
    
//     return (
//         <ChartCard 
//             doubleWidth
//             type="line"
//             title="Activations over time"
//             uri={`${API_CHART_ACTIVATIONS_PER_DAY}?${queryStr}`}
//             map={mapActivationsPerDay} 
//             legend="Activations"
//             colorscheme="office.Circuit6" />    
//     );
// }


const columns : IColumn[] = [
    col("liftType4", "Modules", 100, 150, true),
    //col("displaySerialNo", "Name", 250, 300, true),
    col("displayName", "Name", 250, 300, true),
    col("date", "Date"),
    //col("locationId", "LocationId"),
    col("locationName", "Location"),
    col("seconds", "Seconds", 60, 100, true),
    col("height", "Height", 60, 100, true),
    col("weight", "Weight", 60, 100, true),
]



const ActivationsOverTime = createChartComponent(
    API_CHART_ACTIVATIONS_PER_DAY,
    mapActivationsPerDay,
    "Lifts over time",
    columns, 
    { type: "line", legend: "Lifts", colorscheme: "office.Circuit6", doubleWidth: true }
);

export default ActivationsOverTime;