import React, { useState } from "react";
import styled from "styled-components";
import { FilterList } from "../../components/filter/FilterList";
import TimespanPill, { DateRange } from "./TimespanPill";
import LocationPill from "./LocationPill";
import { Location } from "../../api";
import DailyActivations from "./charts/DailyActivations";
import TotalActivations from "./charts/TotalActivations";
import WeightPerActivation from "./charts/WeightPerActivation";
import LastService from "./charts/LastService";
import ActivationsOverTime from "./charts/ActivationsOverTime";
import { getDateString } from "./charts/utils";

export interface Props
{
    children?: React.ReactChildren;
}

const Grid = styled.div`
    margin: 2em;
    display: flex;
    flex-wrap: wrap;
`;

const convertToQueryString = (dateRange: { from: Date, to: Date }): { from: string, to: string} => ({
    from: getDateString(dateRange.from),
    to: getDateString(dateRange.to)
});

export interface Query 
{
    from?: string;
    to?: string;
    location?: string;
    hoist?: string;
}

type DailyData = 
{
    [K in "morning" | "afternoon" | "night"]?: number;
}

function Dashboard()
{
    const [locationQuery, setLocationQuery] = useState<Query>();
    const [dateRange, setDateRange] = useState<Query>(convertToQueryString(TimespanPill.initialValue));
    const query: Query = {...dateRange, ...(locationQuery || {})};
    
    const onDateRangeChange = (dateRange: DateRange) => setDateRange(convertToQueryString(dateRange));
    const onLocationChange = (location?: Location[]) => setLocationQuery(
        (location === undefined) ? undefined : ({
            location: location.map(n => n.id.toString()).join(",")
        }));

    return (
        <>
            <FilterList>
                <LocationPill onLocationChange={onLocationChange} />
                <TimespanPill onDateRangeChange={onDateRangeChange} />
            </FilterList>
            <Grid>
                <DailyActivations query={query} />
                <TotalActivations query={query} />
                <WeightPerActivation query={query} />
                <LastService query={query} />
                <ActivationsOverTime query={query} />
            </Grid>  
        </>
    );
    
}

export default Dashboard;