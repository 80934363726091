import { Query } from "../Dashboard";
import { limitsToRangeLabels2, col } from "./utils";
import { IColumn } from "office-ui-fabric-react";
import { createChartComponent } from "./createChartComponent";

export interface Props
{
    query: Query;
}

const mapTotalActivations = (data: {
    limits: number[];
    values: number[];
}) => ({
    labels: limitsToRangeLabels2(data.limits),
    values: data.values
});

const columns : IColumn[] = [
    col("liftType4", "Modules", 50, 100, true),
    col("displayName", "Name", 250, 300, true),
//    col("displaySerialNo", "Name", 250, 300, true),
//    col("hoistSerialNo", "Hoist Serial Number"),
    col("usage", "Activations"),
]

const TotalActivations = createChartComponent(
    "/api/statistics/usage-frequency",
    mapTotalActivations,
    "Usage frequency",
    columns
);

export default TotalActivations;