import React, { useState } from "react";
import ButtonPanel from "../../components/ui/ButtonPanel";
import { PanelForm, PanelFormSection, PanelFormFields } from "../../components/panelform/PanelForm";
import { TextField, MessageBarType } from "office-ui-fabric-react";
import { userAuthenticatorDisable } from "../../api";
import { useSetRecoilState } from "recoil";
import { notifications } from "../../atoms/notifications";
import { v1 } from "uuid";

export interface Props
{
    open?: boolean;
    onDismiss?: () => void;
}

function DisableAuthenticator(props: Props)
{
    const { open, onDismiss } = props;
    const [password, setPassword] = useState("");
    const setNotifications = useSetRecoilState(notifications);
    
    const onDisableClick = () => 
    {
        userAuthenticatorDisable(password).then(async res => {
            if(res.ok)
            {
                setNotifications(v => [...v, {
                    id: v1(),
                    messageBarType: MessageBarType.warning,
                    children: <span>Two-factor authentication has been disabled.</span>
                }]);
                onDismiss?.();
            }
        });
    }

    return (
        <ButtonPanel
            isOpen={open}
            onDismiss={onDismiss}
            headerText="Disable Authenticator App"
            primaryButtonText="Disable"
            onPrimaryButtonClick={onDisableClick}
        >
            <PanelForm>
                <PanelFormSection>
                    <p>
                        To disable the authenticator app for your account
                        please provide your  current password 
                        below and click the "Disable" button.
                    </p>
                    <PanelFormFields>
                        <TextField 
                            type="password"
                            label="Password" 
                            placeholder="Input your password..." 
                            value={password}
                            onChange={(_, v) => setPassword(v ?? "")}
                            />
                    </PanelFormFields>
                </PanelFormSection>
            </PanelForm>

        </ButtonPanel>
    );
}

export default DisableAuthenticator;