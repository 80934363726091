import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { useAuthorizedFetch } from "./../../../hooks/useFetch";
import { IObjectWithKey, DetailsList , Selection, SelectionMode, IColumn, IChoiceGroupOption, ChoiceGroup, Text, getEdgeChromiumNoHighContrastAdjustSelector, Link } from "office-ui-fabric-react";
import { getTagsList } from "./../../../api";


export interface ITraining {
    trainingId: number;
    hoistSerialNo: string;
    datetime: string;
    StartVoltage: number;
    EndVoltage: number
    weight: number;
    tags: string;
    displaySerialNo: string;
    locationName: string;
}  

interface IProps {
   onTagclick: (item:ITagData) => void; 
   tagTypeId: string | undefined;
}


export interface ITagData {
    text:string;
    id:number;
}

const TagList = (props:IProps) =>{
    const [open, setOpen] = useState(false);
    const [topPos, setTopPos] = useState();
    const [tagList, setTagList] = useState<ITagData[]>();


    const onTagClick = (item:ITagData) =>{
        props.onTagclick(item)
    }


    const getTagsDataList = async () => {
        const res = await getTagsList(props.tagTypeId);
        if(res.ok)
        {
          const retJson:ITagData[] = await res.json();
          setTagList(retJson);
        }
        else
        {
        }
      }
    

  // training data list  
  useEffect(() => {
    const callback = async () => {
        getTagsDataList();
  }
    callback();
  }, [props.tagTypeId]);


    const columns:IColumn[] = [
        {key: "column0", name: "", fieldName: "", minWidth: 25, maxWidth: 25, isResizable: false},
        {key: "column1", name: "Tag name", fieldName: "text", minWidth: 100, maxWidth: 100, isResizable: true, onRender: (item, _, col) => (
            <Link onClick={() => onTagClick(item)}>{item[col!.fieldName!]}</Link>
        ) },
      ];


return(
    <>
            <DetailsList
                disableSelectionZone={true}
                columns={columns}
                items={tagList ?? []}
                checkboxVisibility={2}
            />
    </>
)

}

export default TagList;

