
type AnyFn = (...args: any[]) => any;

/**
 * Returns a memoized version of fn that remembers its latest arguments
 * @param fn 
 */
export const memoize = <T extends AnyFn>(fn: T) =>
{
    let lastArgs: Parameters<T> | undefined = undefined;
    let result: ReturnType<T>;
    return (...args: Parameters<T>) => 
    {
        if(lastArgs === undefined || (lastArgs.length !== args.length) || (args.some((arg, i) => lastArgs![i] !== arg)))
        {
            result = fn(...args);
            lastArgs = args;
        }

        return result;
    }
}

export const debounce = <T extends AnyFn>(fn: T, ms: number) =>
{
    let handle: number | undefined = undefined;
    return (...args: Parameters<T>) => 
    {
        clearTimeout(handle);
        handle = setTimeout(() => fn(args), ms);
    }
}