import { atom } from "recoil";

export interface Ilocations {
    lat: number;
    lng: number;
    hoist: string;
    status: number | undefined;
}


export const locationsFilterList = atom<string[]>({
    key: "locationsFilterList",
    default: []
});

