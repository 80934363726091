import React from "react";
import { Query, col } from "./utils";
import { IColumn } from "office-ui-fabric-react";
import { createChartComponent } from "./createChartComponent";

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;

    query: Query;
    noBorder?: boolean;
    noDetails?: boolean;
}

type DailyData = 
{
    [K in "morning" | "afternoon" | "night"]?: number;
}

const mapDailyActivations = (values: DailyData) => ({
    labels: ["Morning", "Afternoon", "Night"],
    values: [values.morning ?? 0, values.afternoon ?? 0, values.night ?? 0]
});

const columns : IColumn[] = [
    col("liftType4", "Modules", 50, 100, true),
    //col("hoistSerialNo", "Hoist Serial Number"),
    //col("displaySerialNo", "Name", 250, 300, true),
    col("displayName", "Name", 250, 300, true),
    col("locationName", "Location"),
    //col("locationId", "Location Id"),
    col("morning", "Morning"),
    col("afternoon", "Afternoon"),
    col("night", "Night")
]

const DailyActivations = createChartComponent(
    "/api/statistics/daily",
    mapDailyActivations,
    "Daily lifts",
    columns
);

export default DailyActivations;
