import * as React from "react";
import { useMemo, useCallback, useState } from "react";
import TreeView, { Node } from "../../../components/treevew/TreeView";
import { Route, useParams} from "react-router-dom";
import { useNavigate, Routes } from "react-router-dom";
import { Location } from "../../../api";
import { Hoist } from "./useLocationHoists";

import LocationCommandBar from "./LocationCommandBar";
import DialogController, { DialogType } from "./DialogController";
import { useExpandedLocationTree } from "../../../hooks/useLocationTree";
import styled from "styled-components";
import { LocationForm } from "./LocationForm";
import { useTimezoneOptions } from "./useTimezoneOptions";

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;

    onNodeClick?: (node: Node<Location>) => void;
}

const Pane = styled.div`
    display: grid;
    grid-template-columns: minmax(200px, 30%) 1fr;
`;

const findNode = <T extends Location>(locationId: string, locations?: T[]): T | null =>
{
    if(locations !== undefined)
    {
        let id = parseInt(locationId);
        for(let location of locations)
        {
            if(location.id === id)
            {
                return location;
            }
            const childLocation  = findNode<T>(locationId, location.children as T[]);
            if(childLocation !== null)
            {
                return childLocation;
            }
        }
    }
    return null;
}

const findParent = <T extends Location>(location: T, locations?: T[]): T | null =>
{
    if(locations === undefined || locations.length === 0)
    {
        return null;
    }

    let index = locations.indexOf(location);
    if(index > -1)
    {
        return null;
    }

    let workingSet: Location[] = [...locations];
    while(workingSet.length > 0)
    {
        const parent = workingSet.shift();
        if(parent!.children.indexOf(location) > - 1)
        {
            return parent as T;
        }
        workingSet = [...workingSet, ...(parent!.children)]
    }

    return null;
}

function LocationsView(props: Props)
{
    const { onNodeClick } = props;
    const options = useTimezoneOptions();
    const [nodes, refresh, locations] = useExpandedLocationTree(false);
    const [selection] = useState<Hoist[]>([]);
    const [showDialog, setShowDialog] = useState<DialogType | undefined>(undefined);

    const { id } = useParams();
    const [location, parent] = useMemo(() => {
        const location = findNode(id, locations ?? []) ?? undefined;
        return [location, location ? findParent(location, locations) : null];
    }, [id, locations]);


    const location2 = findNode(id, locations ?? []) ?? undefined;

    console.log(locations)


    const onCommandClick = useCallback((name: string) => setShowDialog(name as DialogType), []);
    const onDismissDialog = useCallback(() => setShowDialog(undefined), []);

    return (
    <>
        <DialogController 
            visibleDialog={showDialog}
            onDismiss={onDismissDialog}
            locations={nodes ?? []}
            selectedHoists={selection}
            selectedLocation={location}
            refresh={refresh}

        />
        <LocationCommandBar currentLocation={location} onCommandClick={onCommandClick} />
        <Pane>
            <TreeView nodes={nodes ?? []} onNodeClick={onNodeClick} activeNodeId={id} />
            {
                (location) ? (
                    <div style={{padding: 16}}>
                        <LocationForm 
                            key={location.id} 
                            location={location} 
                            parentLocation={parent} 
                            timezoneOptions={options} 
                            onSaved={refresh} 
                            onShowDialog={onCommandClick}
                            />
                    </div>
                ) : null
            }
        </Pane>
    </>
    )

}

function Locations()
{
    const navigate = useNavigate();

    const onNodeClick = useCallback((node: Node<Location>) => {
        navigate(node.id);
    }, [navigate]);

    const element = <LocationsView onNodeClick={onNodeClick} />;
    return (
        <Routes>
            <Route path=":id" element={element} />
            <Route path="" element={element} />
        </Routes>
    )
}

export default Locations;