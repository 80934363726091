import React, {useRef,useEffect, useState, useCallback, HtmlHTMLAttributes,  } from "react";

export interface IUseGoogleTimezone{
    getUTC: (location: string) => void;
}

export interface ITimezone{
    dstOffset: string;
    rawOffset: string;
    timeZoneId: string;
    timeZoneName: string;
    status: string;
}


export interface IProps{
    apikey: string;
    timestamp: string
}


export const useGoogleTimezone = (props:IProps):[ITimezone | undefined, (refreshLoad: string) => Promise<void>] =>
{
    const [googleTimezone, setGoogleTimezone] = React.useState<ITimezone | undefined>();
    //const [refresh, setRefresh] = React.useState<boolean>();
    const [param, setParam] = React.useState<string>("");



    const getUTC =  async (location: string) => {
        const option = {
            method: 'GET',
            headers: {'content-type': 'application/json'},
            };
        const response = await fetch('/api/timezones/GetTimeZone?position=' + location, option);

       if (response.ok) {
        const timezone = await response.json() as ITimezone;
        setGoogleTimezone(timezone);
        //alert(parseInt(timezone.rawOffset) / 60);   
        }
    }

    const GetGoogleTime = async (refreshLoad: string) => {
        await setParam(refreshLoad);
    }

    useEffect(
        () => {
            (async () => {
            await getUTC(param);
        })()
    },[param])


    return [googleTimezone, GetGoogleTime];
}