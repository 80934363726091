import React, { useCallback, useState, useRef } from "react";
import { PanelButtons, PanelContent } from "./Dialog.styles";
import { PrimaryButton, DefaultButton, Panel, TextField, Stack, Label, ProgressIndicator } from "office-ui-fabric-react";
import { DialogProps } from "../../../components/DialogProps";
import { Location } from "../../../api";
import TreeView, { Node } from "../../../components/treevew/TreeView";

export interface Props extends DialogProps
{
    locations: Node<Location>[];
    visible?: boolean;
    onDismiss: () => void;
    onLocationAdd?: (name: string, parent?: Node<Location>) => void;
}

function LocationAddDialog(props: Props)
{
    const { visible, locations, onLocationAdd, onDismiss, busy } = props;
    const [selectedNode, setSelectedNode] = useState<Node<Location> | undefined>(undefined);
    const [ name, setName ] = useState("");
    
    const value = useRef<{ name: string, parent?: Node<Location>}>();
    value.current = { name, parent: selectedNode };
    const onDone = useCallback(() => onLocationAdd?.(value.current!.name, value.current!.parent), [onLocationAdd]);

    const onRenderFooterContent = useCallback(() => {
        return (
            <PanelButtons>
                <PrimaryButton disabled={busy || name === ""} onClick={onDone}>Add</PrimaryButton>
                <DefaultButton disabled={busy} onClick={onDismiss}>Cancel</DefaultButton>
            </PanelButtons>
        );
    }, [onDismiss, onDone, name, busy]);

    const onNodeClick = useCallback((node: Node<Location>) => setSelectedNode(node), []);
    const onNameChange = useCallback((ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName((ev.target as HTMLInputElement)?.value);
    }, []);

    return (
        <Panel 
            headerText="Add Location"
            isOpen={visible}
            onDismiss={onDismiss}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom
        >
            {busy ? <ProgressIndicator description="Adding location..." /> : null}
            <PanelContent>
                <p>Please fill in location name and where the location should be placed relative to the other locations.</p>
                <Stack tokens={{childrenGap: 12}}>
                    <TextField disabled={busy} label="Location Name" onChange={onNameChange} value={name} />
                    <Label disabled={busy}>Placement</Label>
                    <TreeView 
                        disabled={busy}
                        nodes={locations} 
                        activeNodeId={selectedNode?.id}
                        onNodeClick={onNodeClick} />
                </Stack>
            </PanelContent>
        </Panel>
    );
}

export default LocationAddDialog;