import React from "react";
import { CommandBar, ICommandBarItemProps, ContextualMenuItem } from "office-ui-fabric-react";
import { User, Role } from "./UserManagement";
import styled from "styled-components";
import { all } from "../../../util/array";

export interface Props
{
    selectedUsers: User[];
    roles?: Role[];
    onInviteClick?: () => void;
    onAssignRoleClick?: (role: Role) => void;
    onSendInvitesClick?: () => void;
    onPasswordPolicyClick?: () => void;
}

const CommandBarWrapper = styled.div`
    border-bottom: "1px solid #e0e0e0";
    display: "flex";
    padding: 2px;
`;

const commandBarStyle: React.CSSProperties = {
    flexGrow: 1
};

function UserListCommandBar(props: Props)
{
    const { selectedUsers, roles, onInviteClick, onAssignRoleClick, onSendInvitesClick, onPasswordPolicyClick } = props;
    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: "new",
            text: "Invite",
            iconProps: { iconName: "addfriend" },
            onClick: () => onInviteClick?.(),
       },
        {
            key: "role-assign",
            text: "Assign Role",
            iconProps: { iconName: "securitygroup" },
            disabled: selectedUsers.length === 0,
            subMenuProps: {
                items: roles?.map(role => ({
                    key: role.id,
                    text: role.name,
                    onClick: () => onAssignRoleClick?.(role)
                })) ?? []
            }
        },
    ];

    if(selectedUsers.length > 0 && all(selectedUsers, x => !x.confirmed))
    {
        commandBarItems.push({
            key: "resend-invitation",
            text: "Resend Invitation(s)",
            iconProps: { iconName: "PublicEmail" },
            onClick: onSendInvitesClick
        });
    }

    const commandBarFarItems: ICommandBarItemProps[] = [
        {
            key: "menu",
            iconProps: { iconName: "lock" },
            subMenuProps: {
                contextualMenuItemAs: ContextualMenuItem,
                items: [
                    { 
                        key: "password-policy", 
                        text: "Password Policy", 
                        iconProps: { iconName: "EntitlementPolicy" },
                        onClick: onPasswordPolicyClick
                    }
                ]
            }
        }
    ];
    
    return (
        <CommandBarWrapper>
            <CommandBar items={commandBarItems} farItems={commandBarFarItems} style={commandBarStyle}  />
        </CommandBarWrapper>    
    );
}

export default UserListCommandBar;