import React, { useState } from "react";
import Main from "./components/layout/Main";
import SetupAuthenticator from "./views/profile/SetupAuthenticator";
import { Stack, PrimaryButton, Text } from "office-ui-fabric-react";
import authService from "./api-authorization/AuthorizeService";

export interface Props
{
    mfaEnabled: boolean;
}

function MfaRequired(props: Props)
{
    const { mfaEnabled } = props;
    const [dialogVisible, setDialogVisible] = useState(false);
    
    const onLogoutClick = () => 
    {
        authService.signOut({});
    }

    return (
        <Main style={{ padding: "2em", maxWidth: 600 }}>
            {
                dialogVisible ? <SetupAuthenticator open={dialogVisible} onDismiss={() => setDialogVisible(false)} /> : null
            }
            <Text variant="mediumPlus">
                <h2>
                    <Text variant="xxLarge">Multi-factor Authentication is required to access this site</Text>
                </h2>
                <p style={{ margin: "1em 0" }}>
                    To access this Guldmann CLM site you need to have multifactor authentication enabled. Please
                    register an authenticator app to use the application and log out and try again.
                </p>
                <Stack tokens={{ childrenGap: 8, maxWidth: 250 }}>
                    <PrimaryButton disabled={mfaEnabled} onClick={() => setDialogVisible(true)}>1. Register Authenticator App</PrimaryButton>
                    <PrimaryButton disabled={!mfaEnabled} onClick={onLogoutClick}>2. Log out</PrimaryButton>
                </Stack>
            </Text>
    </Main>
);
}

export default MfaRequired;