import React, { useEffect, useState } from "react";
import { Text, DetailsList, IColumn, Label, SelectionMode } from "office-ui-fabric-react";
import { getErrorList } from "./../api";
import { Pagination, IDbPagination, IErrorList, IPagingResponse } from './../components/Pagination'
import { GetServiceStatus } from "./../api";
import { getActiveErrors } from "./../api";

import {intl} from "../i18n";


export interface Props 
{
    title?: string;
    hoistNo: string;
}

interface IStatusIcons  {
  status?: string;
  hoistNo: string;
}

interface IStatusActiveErrors  {
  hoistNo: string;
}

interface IActiveErrors  {
  title: string;
  hoistSerialNo: string;
  errorLogTypeId: number;
}


interface IServiceStatus {
  hasError: boolean;
  errorMessage: string;
  errorNumber: number;
  dayesOverdue: number;
  DaysLess: number;
}

const dtf = new Intl.DateTimeFormat(intl.locale, {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric"
});

const formatDate = (item?: IErrorList, index?: number, column?: IColumn) => 
{
    return (item === undefined) ? "" : dtf.format(new Date(item.date));
}

const StatusIcons = (iconStatus: IStatusIcons) => {
  
  let color = "";
  const state = iconStatus.status as string; 

  if (state == "6")
  {
    color = "#08802C";
  }
  else if (state == "5")
  {
    color = "#f0f062";
  }
  else if (state == "1"){
    color = "#ff0000";
  }


  return (
    <div className='customOption'>
      <div className={"colorCircleErrorList"} style={ { backgroundColor: color } }></div>
    </div>
  );
}

const StatusIconsService = (iconStatus: IStatusIcons) => {
  const [status, setStatus] = useState<IServiceStatus>();

  const getStatus = async () => {
    const res = await GetServiceStatus(iconStatus.hoistNo);

    if(res.ok)
    {
      const retJson:IServiceStatus = await res.json();
      setStatus(retJson);
    }
    else
    {
    }
 
  }

  useEffect(() => {
    const callback = async () => {
       getStatus();
  }
    callback();
  }, []);

  let color = "";
  const state = status?.errorNumber;
  
  if (state !== undefined){
    if (state == 1)
    {
      color = "#08802C";
    }
    else if (state == 3)
    {
      color = "#f0f062";
    }
    else if (state == 5){
      color = "#ff0000";
    }
  }



  return (
    <>
    {status?.hasError === true ?
    <div className='customOption'>
      <div className={"colorCircleErrorList"} style={ { backgroundColor: color, marginLeft:"12px" } }></div><Text variant={"medium"} style={{marginLeft:"24px"}}>{status?.errorMessage}</Text>
    </div>
    :
    null
    }
    </>
  );
}



const StatusActiveErrors = (statusActiveErrors: IStatusActiveErrors) => {
  const [activeErrors, setActiveErrors] = useState<IActiveErrors[]>();

  const getStatus = async () => {
    const res = await getActiveErrors(statusActiveErrors.hoistNo);

    if(res.ok)
    {
      const retJson:IActiveErrors[] = await res.json();
      setActiveErrors(retJson);
    }
    else
    {
    }
 
  }


  useEffect(() => {
    const callback = async () => {
       getStatus();
  }
    callback();
  }, []);

  const colorStat = (errorType:number):string => {
    let color = "";
    const state = errorType;
    
    if (state !== undefined){
      if (state == 1)
      {
        color = "#ff0000";
      }
      else if (state == 5){
        color = "#f0f062";
      }
    }
  
    return color;
  }


  return(
    <>
    {activeErrors?.map((item => (
    <div className='customOption'>
      <div className={"colorCircleErrorList"} style={ { backgroundColor: colorStat(item.errorLogTypeId), marginLeft:"12px", marginTop:"3px" } }></div><Text variant={"medium"} style={{marginLeft:"24px"}}>{item.title}</Text>
    </div>
    )))}
    </>
  )

}








export const ErrorListControl = (props: Props) =>
{
    const [ErrorList, setErrorList] = useState<IErrorList[]>();
    const [errorCount, setErrorCount] = useState<number>(0);
    let _columns: IColumn[] = [];
    let _allItems = [];


    _columns = [
      { fieldName: "ErrorState", key: "IconStatus", name: "", minWidth: 10,maxWidth: 20, isResizable: false, onRender: (item, _, col) => {
        return (
          <StatusIcons status={item.errorTypeNbr} hoistNo={props.hoistNo} />
        )
    
      }
        
     },  
      { key: 'erorTypeTxt', name: 'Message', fieldName: 'erorTypeTxt', minWidth: 200, maxWidth: 600, isResizable: false },
        { key: 'Date', name: 'Logged', fieldName: 'date', minWidth: 100, maxWidth: 200, isResizable: false, onRender:formatDate },
        /*{ key: 'statusTxt', name: 'Status', fieldName: 'statusTxt', minWidth: 100, maxWidth: 200, isResizable: false },*/
      ];


    
    const OnChangePaging = async (dbPagination:IDbPagination) => {

      let res = await getErrorList({hoistNo:props.hoistNo,offset:dbPagination.offset,take:dbPagination.take,count:errorCount});

      if(res.ok)
      {
        const ret:IPagingResponse = await res.json();
        setErrorList(ret.arrList);
        setErrorCount(ret.count!);
      }
      else
      {
      }
    }


    return(
        <>
            <div><StatusIconsService status="2" hoistNo={props.hoistNo}/></div>
            <StatusActiveErrors hoistNo={props.hoistNo}/>

            <DetailsList
            items={ErrorList || []}
            columns={_columns}
            setKey="set"
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
            selectionMode={SelectionMode.none}/>
            <div className="paging-container">
              <div className="paging-positioon"><Pagination totalItems={errorCount} maxShowing={10} onChange={(dbPagination) => OnChangePaging(dbPagination)}/></div>
            </div>
        </>
        
        
        )

}

