import { useAuthorizedFetch } from "../../../hooks/useFetch";
import { Timezone, API_TIMEZONE } from "../../../api";
import { useMemo } from "react";
import { IDropdownOption } from "office-ui-fabric-react";

export function useTimezoneOptions()
{
    const [state, timezones] = useAuthorizedFetch<Timezone[]>(API_TIMEZONE + "/GetTimeZones");
    const options = useMemo<IDropdownOption[]>(() => {

        if(state !== "success" || timezones === undefined)
        {
            return [];
        }

        return timezones.map(tz => ({
            key: tz.name,
            id: tz.name,
            text: tz.displayName
        }));

    }, [state, timezones]);

    return options;
}