import React, { useState, useCallback } from "react";
import { Location, adminAddLocation, adminDeleteLocation } from "../../../api";
import { Hoist } from "./useLocationHoists";
import { Node } from "../../../components/treevew/TreeView";
import LocationRemoveDialog from "./LocationRemoveDialog";
import LocationAddDialog from "./LocationAddDialog";

export type DialogType = "hoist-unassign" | "hoist-assign" | "location-remove" | "location-add";

export interface Props
{
    visibleDialog?: DialogType;
    selectedLocation?: Location;
    selectedHoists?: Hoist[];
    locations: Node<Location>[];

    onAssignHoistToLocation?: (hoists: Hoist[], location: Location) => void;
    onUnassignHoistLocation?: (hoists: Hoist[]) => void;
    onDismiss: () => void;
    refresh: () => void;
}

function DialogController(props: Props)
{
    const [busy, setBusy] = useState(false);
    const { 
        visibleDialog, 
        selectedLocation,
        locations,
        onDismiss,
        refresh,
    }  = props;

    const internalDismiss = useCallback(() => !busy && onDismiss(), [busy, onDismiss]);

    const onLocationAdd = (name: string, parent?: Node<Location>) => {
        setBusy(true);
        adminAddLocation(name, parent ? parseInt(parent.id) : undefined)
            .then(res => {
                if(res.ok)
                {
                    onDismiss();
                    refresh();
                }
                else
                {
                    console.error("error");
                }
            })
            .finally(() => setBusy(false));

    }

    const onLocationRemove = (location: Location) => {
        setBusy(true);
        adminDeleteLocation(location.id).then(res => {
            if(res.ok)
            {
                onDismiss();
                refresh();
            }
        }).finally(() => setBusy(false));
    }

    return (
        <>
            <LocationRemoveDialog
                busy={busy}
                location={selectedLocation}
                visible={visibleDialog === "location-remove" && selectedLocation !== undefined}
                onDismiss={internalDismiss}
                onRemove={onLocationRemove}
                />     
             <LocationAddDialog
                busy={busy}
                locations={locations}
                visible={visibleDialog === "location-add"}
                onDismiss={internalDismiss}
                onLocationAdd={onLocationAdd}
                />
        </>
    );
}

export default DialogController;