import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { notifications } from "../atoms/notifications";
import { MessageBarType } from "office-ui-fabric-react";
import { v1 } from "uuid";

export function useNotificationService()
{
    const setNotifications = useSetRecoilState(notifications);
    const addNotification = useCallback((message: string | JSX.Element, messageBarType: MessageBarType = MessageBarType.info) => {
        const id = v1();
        setNotifications(notifs => [
            ...notifs,
            {
                id,
                messageBarType,
                children: message
            }
        ]);
        return id;
    }, [setNotifications]);

    return {addNotification};
}