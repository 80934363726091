import React, { useCallback, useState } from "react";
import { PrimaryButton, DefaultButton, Panel, ProgressIndicator } from "office-ui-fabric-react";
import { Location } from "../../api";
import TreeView, { Node } from "../../components/treevew/TreeView";
import { Hoist } from "./useLocationHoists";
import { DialogProps } from "../../components/DialogProps";

export interface Props extends DialogProps
{
    hoists?: Hoist[];
    locations: Node<Location>[];
    onAssign: (hoists: Hoist[], location: Location) => void
}

const treeview: React.CSSProperties = {
    marginLeft: 0,
    marginTop: 16
}
const assignButton: React.CSSProperties = { marginRight: 8 };
const styles =  { treeview, assignButton };

function AssignDialog(props: Props)
{
    const { hoists, visible, onDismiss, onAssign, locations, busy } = props;
    const [selectedNode, setSelectedNode] = useState<Node<Location>>();

    const onRenderFooterContent = useCallback(() => {
        const okClicked = () => (hoists && selectedNode) && onAssign(hoists, selectedNode.data!); 
        return (
            <div>
                <PrimaryButton disabled={busy || selectedNode === undefined} style={styles.assignButton} onClick={okClicked}>Assign</PrimaryButton>
                <DefaultButton disabled={busy} onClick={onDismiss}>Cancel</DefaultButton>
            </div>
        );
    }, [onAssign, onDismiss, selectedNode, hoists, busy]);

    const onNodeClick = useCallback((node: Node<Location>) => setSelectedNode(node), []);

    return (
        <Panel
            headerText="Assign hoist(s)"
            isOpen={visible}
            onDismiss={onDismiss}
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
            onRenderFooterContent={onRenderFooterContent}
        >
            {busy ? <ProgressIndicator description="Assigning location..." /> : null}
            <p>Please select a location below to assign to the selected hoists:</p>
            <TreeView 
                disabled={busy}
                style={styles.treeview} 
                nodes={locations} 
                activeNodeId={selectedNode?.id}
                onNodeClick={onNodeClick} />
        </Panel>
    );
}
export default AssignDialog;