import React from "react";
import { useRoutes } from "react-router-dom";
import { Login } from "../components/Login"
import { Logout } from "../components/Logout"
import { ApplicationPaths, LoginActions, LogoutActions, LoginAction, LogoutAction } from "./ApiAuthorizationConstants";

export default function ApiAuthorizationRoutes()
{
  return useRoutes([
    { path: ApplicationPaths.Login, element: loginAction(LoginActions.Login) },
    { path: ApplicationPaths.LoginFailed, element: loginAction(LoginActions.LoginFailed) },
    { path: ApplicationPaths.LoginCallback, element: loginAction(LoginActions.LoginCallback) },
    { path: ApplicationPaths.Profile, element: loginAction(LoginActions.Profile) },
    { path: ApplicationPaths.Register, element: loginAction(LoginActions.Register) },
    { path: ApplicationPaths.LogOut, element: logoutAction(LogoutActions.Logout) },
    { path: ApplicationPaths.LogOutCallback, element: logoutAction(LogoutActions.LogoutCallback) },
    { path: ApplicationPaths.LoggedOut, element: logoutAction(LogoutActions.LoggedOut) },
  ]);
}

function loginAction(name: LoginAction){
    return (<Login action={name}></Login>);
}

function logoutAction(name: LogoutAction) {
    return (<Logout action={name}></Logout>);
}
