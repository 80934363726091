import { useEffect, useRef } from "react";
import authService from "./AuthorizeService";
import { useSetRecoilState, useRecoilState } from "recoil";
import { mfaAtom, authenticatedAtom, userProfileAtom, UserInfo, userInfoAtom } from "../atoms/user";
import { userInfo } from "../api";

let currentRequest: Promise<UserInfo> | undefined = undefined;
function fetchUserInfo(this: any): Promise<UserInfo>
{
    if (currentRequest === undefined)
    {
        currentRequest = new Promise(async (accept, reject) => {
            try {
                const res = await userInfo();
                if(res.ok)
                {
                    const result: UserInfo = await res.json();
                    accept(result);
                }
            }catch(e)
            {
                reject(e);
            }
        });
    }
    return currentRequest;
}

function UserStateManager()
{
    const [mfa, setMfa] = useRecoilState(mfaAtom);
    const setAuthenticated = useSetRecoilState(authenticatedAtom);
    const setUserProfile = useSetRecoilState(userProfileAtom);
    const setUserInfo = useSetRecoilState(userInfoAtom);

    const mfaRef = useRef<boolean>(mfa);

    useEffect(() => {
        const callback = async () => {
            
            if(await authService.isAuthenticated())
            {
                setAuthenticated(true);
                const user = await authService.getUser();
                if(user !== null)
                {
                    const mfaEnabled = !!user.amr?.includes("mfa")
                    if(mfaRef.current !== mfaEnabled)
                    {
                        setMfa(mfaEnabled);
                    }

                    setUserInfo(await fetchUserInfo());
                }
                else
                {
                    setUserProfile(undefined);
                }
            }
            else
            {
                setMfa(false);
                setAuthenticated(false);
                setUserProfile(undefined);
            }
        };
        
        callback();
        
        const subscriptionId = authService.subscribe(callback);
        return () => {
            authService.unsubscribe(subscriptionId);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

export default UserStateManager;