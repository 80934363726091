import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";

const LoadingIndicatorContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
`;

function LoadingIndicator()
{
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const handle = setTimeout(() => setVisible(true), 500);
        return () => { clearTimeout(handle) };
    }, []);

    if(!visible)
    {
        return null;
    }

    return (
        <LoadingIndicatorContainer>
            <Spinner size={SpinnerSize.large} label="Loading, please wait..." />
        </LoadingIndicatorContainer>
    )

}

export default LoadingIndicator;