import gh1 from "./../assets/GH1.jpg";
import gh1f from "./../assets/GH1F.jpg";
import gh1q from "./../assets/GH1Q.jpg";
import gh3 from "./../assets/GH3.jpg";
import ghz from "./../assets/GHZ.jpg";
import gh3plustwin from "./../assets/GH3PlusTwin.jpg";
import gh3plus from "./../assets/gh3plus.jpg";
import gl5 from "./../assets/GL5.jpg";
import gls5 from "./../assets/GLS5.jpg";


/**
 * Finds an item with the specified key in a recursive structure
 * @param key 
 * @param items 
 * @param getChildren 
 */
export const findByKey = <T extends { key: string }>(key: string, items: T[], getChildren: (item: T) => T[]): T | undefined =>
    (items.length === 0) ? undefined :
        items.find(v => v.key === key) ?? findByKey(key, items.flatMap(getChildren), getChildren);




export const getProductType = (type:number):string =>
{
    switch (type)
    {
        case 0: return "GH1";
        case 1: return "GH1F";
        case 2: return "GH1Q";
        case 3: return "GH3";
        case 4: return "GHZ";
        case 5: return "GH3+ Twin";
        case 6: return "GH3+";
        case 7: return "GL5";
        case 8: return "GLS5";
        default: return "Unknown";
    }
}

export const getImageTextFromType = (type:number):string => {
    switch (type)
    {
        case 0: return gh1;
        case 1: return gh1f;
        case 2: return gh1q;
        case 3: return gh3;
        case 4: return ghz;
        case 5: return gh3plustwin;
        case 6: return gh3plus;
        case 7: return gl5;
        case 8: return gls5;
        default: return "";
    }
}



