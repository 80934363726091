import React, { useRef, useState, useCallback, useEffect } from "react"; 
import styled from "styled-components"; 
import { PanelType, Panel, IContextualMenuItem, ContextualMenuItemType, Persona, PersonaSize, ContextualMenu } from "office-ui-fabric-react";
import authService from "../../api-authorization/AuthorizeService";
import ChangePasswordPanel from "./ChangePasswordPanel";
import EditProfilePanel from "./EditProfilePanel";
import SetupAuthenticator from "./SetupAuthenticator";
import { authenticatedAtom, mfaAtom, userInfoAtom } from "../../atoms/user";
import { useRecoilValue } from "recoil";
import DisableAuthenticator from "./DisableAuthenticator";
import { useNavigate } from "react-router-dom";
import { useAuthorizedFetch } from "./../../hooks/useFetch";
import About from "./About";
import { useBoolean, useId } from '@uifabric/react-hooks'



const Badge = styled.div`
    align-self: center;
    cursor: pointer;
`;

interface PanelProps
{
    open?: boolean;
    onDismiss?: () => void;
}

const panels: { [key: string]: React.ComponentType<PanelProps> } = {
    change_password: ChangePasswordPanel,
    edit: EditProfilePanel,
    mfa: SetupAuthenticator,
    "mfa-disable": DisableAuthenticator,
}

interface PanelManagerProps
{
    name?: string;
    onDismiss?: () => void;
}

interface IDownloadPDFProps
{
    url?: string;
    onDowload?: boolean;
    onFinish?: () => void;
}

export interface IDownloadSetting
{
    url?: string;
}


function PanelManager(props: PanelManagerProps)
{
    const { name, onDismiss } = props;
    if(name === undefined || !(name in panels))
    {
        return null;
    }
    return React.createElement(panels[name], { onDismiss, open: true });
}

function UserMenu() {
    const [settingStatus, downloadSetting, refresh] = useAuthorizedFetch<IDownloadSetting>("api/setting/downloaduserguide");
    const authenticated = useRecoilValue(authenticatedAtom);
    const mfaEnabled = useRecoilValue(mfaAtom);
    const userInfo = useRecoilValue(userInfoAtom);
    const ref = useRef<HTMLDivElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [openPanel, setOpenPanel] = useState<string>();
    const [isOpen, { setTrue: openPanelAbout, setFalse: dismissPanelAbout }] = useBoolean(false);

    const onMenuToggle = useCallback(() => setMenuOpen(v => !v), []);
    const onMenuDismiss = useCallback(() => setMenuOpen(false), []);
    const onPanelDismiss = useCallback(() => setOpenPanel(undefined), []);
    
    const onRenderPrimaryText = useCallback(() => null, []);
    const navigate = useNavigate()
    const [downloadState, setDownloadState] = useState(false);


    const downloadfile = () =>
    {
        setDownloadState(true);
    }

    const downloadFinish = () => {
        setDownloadState(false);
    }

    const about = () => {
        alert("about");
    }



    if(!authenticated)
    {
        return null;
    }

    const items: IContextualMenuItem[] = [
    {
        key: "edit",
        text: "Edit profile",
        iconProps: { iconName: "editcontact" },
    },
    {
        key: "divider_0",
        itemType: ContextualMenuItemType.Divider
    }, 
    {
        key: "print",
        text: "Print EULA",
        iconProps: { iconName: "Print" },
        onClick: () => {navigate('/eula');}
    },
    {
        key: "userguide",
        text: "Download user guide",
        iconProps: { iconName: "Download" },
        onClick: () => {downloadfile()}
    },
    {
        key: "divider_1",
        itemType: ContextualMenuItemType.Divider
    }, 
    {
        key: "change_password",
        text: "Change password",
        iconProps: { iconName: "passwordfield" }
    }, {
        key: "mfa",
        text: "Enable Two-factor Authentication",
        iconProps: { iconName: "AuthenticatorApp" }
    }, {
        key: "mfa-disable",
        text: "Disable Two-factor Authentication",
        style: { color: "red" },
        iconProps: { iconName: "AuthenticatorApp" }
    }, 
    {
        key: "about",
        text: "About",
        iconProps: { iconName: "info" },
        onClick: () => {openPanelAbout()}
    },
    {
        key: "divider_2",
        itemType: ContextualMenuItemType.Divider
    }, {
        key: "logout",
        text: "Logout",
        onClick: () => {authService.signOut({})}
    }].filter(item => 
        item.key !== (mfaEnabled ? "mfa" : "mfa-disable")
    );

    return (
        <>
            <Badge ref={ref}>
                <Persona 
                    onRenderPrimaryText={onRenderPrimaryText}
                    showUnknownPersonaCoin={userInfo === undefined}
                    text={userInfo?.fullName}
                    onClick={onMenuToggle}
                    size={PersonaSize.size32} />
            </Badge>

            <ContextualMenu 
                target={ref} 
                items={items} 
                hidden={!menuOpen} 
                onItemClick={(_, item) => setOpenPanel(item?.key)}
                onDismiss={onMenuDismiss} />

            <DownloadPDF url={downloadSetting?.url} onDowload={downloadState} onFinish={downloadFinish}/>

            <PanelManager name={openPanel} onDismiss={onPanelDismiss} />
            
            <Panel headerText="About" isOpen={isOpen} closeButtonAriaLabel="Close" onDismiss={dismissPanelAbout} type={PanelType.custom} customWidth="500px" >
                <About download={downloadfile}/>
            </Panel>



        </>
    )

}

const DownloadPDF = (prop:IDownloadPDFProps) => {
    const reference = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (reference.current !== null && prop.onDowload === true)
        {
            reference.current.submit();
            if (prop.onFinish !== undefined)
            {
                prop.onFinish();
            }
        }
      }, [prop.onDowload])

    return(   
        <form ref={reference} method="get" target="_blank" action={prop.url}>
        </form>
    )
}


export default UserMenu;