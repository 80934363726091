import React, { useEffect } from "react"
import { Route } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { ApplicationPaths, QueryParameterNames } from "./ApiAuthorizationConstants"
import { useRecoilValue } from "recoil";
import { authenticatedAtom } from "../atoms/user";

type PropsOf<T> = T extends React.FunctionComponent<infer P> ? P : never;
interface Props extends PropsOf<typeof Route>
{
    path: string;
}

const parseUri = (uri: string) =>
{
    const link = document.createElement("a");
    link.href = uri;

    const { protocol, host, pathname, search, hash } = link;
    return { protocol, host, pathname, search, hash };
}

function AuthorizeRoute(props: Props)
{
    const navigate = useNavigate();
    const isAuthenticated = useRecoilValue(authenticatedAtom);
    const { path } = props;
    
    useEffect(() => {
        if(isAuthenticated === false)
        {
            const link = parseUri(path);
            const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
            const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`
            navigate(redirectUrl);
        }
    }, [isAuthenticated, path, navigate])

    return (
        (isAuthenticated === true) ? (
            <Route {...props} />
        ) : null
    );
}

export default AuthorizeRoute;