import React, { useCallback, useState, useEffect } from "react";
import { Stack, Label, Checkbox, TextField, Toggle } from "office-ui-fabric-react";
import { useAuthorizedFetch } from "../../../hooks/useFetch";
import { API_ADMIN_PASSWORD_POLICY, adminUpdatePasswordPolicy } from "../../../api";
import { useFormik } from "formik";
import ButtonPanel from "../../../components/ui/ButtonPanel";
import { PasswordPolicySchema, passwordPolicySchema as schema } from "../../../api/schema";
import { getOptions, saveOption } from "../../../api";


export interface IOption{
    id: string;
    optionType: string;
    optionValue: string;
    customerId: string
}

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;

    visible?: boolean;
    onDismiss?: () => void;
    onPolicySaved?: () => void;
}

interface FormProps
{
    initialValues: PasswordPolicySchema;
    onSave?: (values: PasswordPolicySchema, timeOutOption?:string) => void;
    onDismiss?: () => void;
}

function PasswordPolicyForm(props: FormProps)
{
    const { initialValues, onSave, onDismiss } = props;
    const [idle, setIdle] = useState<string | undefined>("");
    const [options, setOptions] = useState<IOption[]>();
    const [timeoutEmpty, setTimeoutEmpty] = useState<boolean>(false);
    const [timeoutOption, setTimeoutOption] = useState<IOption | undefined>();
    const { handleChange, values, errors, setFieldValue, submitForm } = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: (values) => {
            onSave?.(schema.cast(values),idle);
        }
    });

    const initAlloptions = async (optionJson:IOption[]) => {

        //timeout
        const timeoutOption = optionJson?.find(x => x.optionType.toLowerCase() === 'timeout');
        if (timeoutOption === undefined){
            setTimeoutEmpty(true);
            setIdle("0");
    
            let res = await saveOption({id:'0',optionType:'timeout', optionValue: "0", customerId:'0'});
            if(res.ok)
            {
            }
            else
            {
                console.log("Save weight option error");
            }
    
        }
        else
        {
            if (timeoutOption.optionValue === "")
            {
                setIdle("0");    
            }
            else
            {
                setIdle(JSON.parse(timeoutOption.optionValue));    
            }
        }   
        setTimeoutOption(timeoutOption);
    }

    const getOptionsAsync = async () => {
        let res = await getOptions();
        if(res.ok)
        {
            const retValues:IOption[] = await res.json();
            setOptions(retValues);
            initAlloptions(retValues);
        }
        else
        {
            console.log("Error from get options")
        }  
}

    useEffect(() => {
            getOptionsAsync();
     }, []);




    const onToggleChange = useCallback((_: any, v: boolean | undefined) => setFieldValue("mfaEnabled", v), [setFieldValue]);

    const isNumeric = (str:any):boolean => {
        if (typeof str != "string") return false
        return !isNaN(str as any) && 
               !isNaN(parseFloat(str)) 
    };
    
    const isBigger = (str:string|undefined):boolean => {
        if (str !== undefined && str.length === 1 && str === "0") {
            return false;
        }
        else
        {
            return true;
        }
    };

    const noDoubleZero = (str:string|undefined):boolean => {
        if (str !== undefined && str.charAt(0) === "0" && str.length > 1) {
            return false;
        }
        else
        {
            return true;
        }
    };

    const noDecimal = (str:string|undefined):boolean => {
        if (str !== undefined && (str.indexOf(".") !== -1 || str.indexOf(".") !== -1 || str.indexOf(" ") !== -1)) {
            return false;
        }
        else
        {
            return true;
        }
    };

    const onIdleChange = async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
       if ((isNumeric(newValue) ||  newValue === '') && noDoubleZero(newValue) && noDecimal(newValue))
        {
           setIdle(newValue);
        }
        else{
            setIdle(idle);
        }
    }

    return (            
        <ButtonPanel
            headerText="Password Policy"
            onDismiss={onDismiss}
            onPrimaryButtonClick={submitForm}
            isOpen
        >
            <Stack tokens={{ childrenGap: "l1" }} style={{ marginTop: 8 }}>
                <Stack.Item>
                    <Stack tokens={{ childrenGap: "s1" }}>
                        <Label>Complexity</Label>
                        <Checkbox label="Lowercase characters" name="requireLowercase" checked={values.requireLowercase} onChange={handleChange as any} />
                        <Checkbox label="Uppercase characters" name="requireUppercase" checked={values.requireUppercase} onChange={handleChange as any} />
                        <Checkbox label="Symbols / non-alphanumeric characters" name="requireNonAlphanumeric" checked={values.requireNonAlphanumeric} onChange={handleChange as any} />
                        <Checkbox label="Digits" name="requireDigit" checked={values.requireDigit} onChange={handleChange as any} />
                        <TextField label="Minimum Length"
                            name="requiredLength"
                            description="Minimum enforced length of passwords."
                            value={values.requiredLength?.toString()} onChange={handleChange}
                            errorMessage={errors.requiredLength}
                        />
                        <TextField label="Unique Characters"
                            name="requiredUniqueChars"
                            description="Minimum number of unique characters which a password must contain."
                            errorMessage={errors.requiredUniqueChars}
                            value={values.requiredUniqueChars?.toString()} onChange={handleChange} />
                    
                        <TextField label="Idle timeout"
                            name="idletimeout"
                            description="Minutes."
                            onChange={onIdleChange}
                            value={idle}
                        />
                    
                    
                    
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Toggle
                        // label="Two-factor authentication (TOTP)" 
                        onText="Two-factor authentication is enforced"
                        offText="Two-factor authentication is optional"
                        checked={values.mfaEnabled}
                        onChange={onToggleChange}
                    />
                </Stack.Item>
            </Stack>
        </ButtonPanel>
    );
}

function PasswordPolicyPanel(props: Props)
{
    const { onDismiss, onPolicySaved, visible } = props;
    const [state, policy, refresh] = useAuthorizedFetch<PasswordPolicySchema>(API_ADMIN_PASSWORD_POLICY);

    const onSave = useCallback(async (values: PasswordPolicySchema,timeOutOption?:string) => {

        let res = await saveOption({id:'0',optionType:'timeout', optionValue: timeOutOption!, customerId:'0'});
        if(res.ok)
        {
        }
        else
        {
            console.log("Save timeout option error");
        }


        adminUpdatePasswordPolicy(values).then(() => {
            refresh();
            onPolicySaved?.();
            onDismiss?.();
        });
    }, [onDismiss, onPolicySaved, refresh]);

    return (visible && state === "success" && policy) ? (
        <PasswordPolicyForm onDismiss={onDismiss} onSave={onSave} initialValues={policy} />
    ) : null
}

export default PasswordPolicyPanel;