import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { notifications } from "../atoms/notifications";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import { useRecoilValue } from "recoil";
import { License, licenseAtom, IRefreshLicense, refreshLicense} from "../atoms/license";
import { intl } from "../i18n";
import { Routes, useNavigate} from "react-router-dom";

export interface Props
{
}

const dateFormat = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long"
};


function LicenseNotification(props: { license: License })
{
    const { license: { valid, details} } = props;
    const navigate = useNavigate();

    if(valid && details !== undefined && details.softValidTo < new Date() && details.hardValidTo > new Date())
    {
        return (
            <MessageBar key="license" messageBarType={MessageBarType.warning}>
                Your license has expired or is no longer valid.
                Please renew your license before {intl.formatDate(details.hardValidTo, dateFormat)} to maintain access to the site.
            </MessageBar>
        );
    }

    if(valid)
    {
        return null;
    }

    return (
        <React.Fragment>
            <MessageBar key="license" messageBarType={MessageBarType.severeWarning}>
                Your license has expired or is no longer valid. Please renew your license to regain access to the site.
            </MessageBar>
        </React.Fragment>
    );
}

function Notifications(props: Props)
{
    let license = useRecoilValue(licenseAtom);
    const [messages, setMessages] = useRecoilState(notifications);
    const [refreshLic, setRefreshLic] = useRecoilState<IRefreshLicense>(refreshLicense);
    const timers = useRef<string[]>([]);
    const onDismiss = (id: string) => () => setMessages(v => v.filter(x => x.id !== id));

    useEffect(() => {
        for (let message of messages.filter(x => x.expiresAfter !== undefined))
        {
            if(timers.current.indexOf(message.id) > -1)
            {
                continue;
            }

            timers.current.push(message.id);
            setTimeout(() => 
                setMessages(v => v.filter(x => x.id !== message.id)),  
                message.expiresAfter!);
        }
    }, [messages, setMessages]);

    return (
        <div>
            <LicenseNotification license={license} />
        {
            messages.map(({ id, expiresAfter, ...props }) => (
                <MessageBar key={id} {...props} onDismiss={onDismiss(id)} />
            ))
        }
        </div>
    );
}

export default Notifications;