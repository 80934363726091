import { Query } from "../Dashboard";
import { col } from "./utils";
import { createChartComponent } from "./createChartComponent";
import { IColumn } from "office-ui-fabric-react";

export interface Props
{
    query: Query;
}

const mapService = (data: number[]) => ({
    labels: ["< 9 months", "< 12 months", "> 1 year" ],
    values: data
})

const withRender = (col: IColumn, onRender: (v: any) => any): IColumn => ({
    ...col, onRender: (item?: any) => onRender(item[col.fieldName ?? ""])
});

var df = new Intl.DateTimeFormat();
const withDateFormat = (col: IColumn): IColumn => withRender(col, v => df.format(Date.parse(v)));

const columns : IColumn[] = [
    col("liftType4", "Modules", 50, 100, true),
    //col("hoistSerialNo", "Hoist Serial Number"),
    //col("displaySerialNo", "Name", 250, 300, true),
    col("displayName", "Name", 250, 300, true),
    col("locationName", "Location"),
    withDateFormat(col("lastService", "Last Service")),
    withDateFormat(col("nextService", "Next Service")),
];


const LastService = createChartComponent(
    "/api/statistics/last-service",
    mapService,
    "Last Service",
    columns,
    {
        colorscheme: "office.Circuit6"
    }
);


// function LastService(props: Props)
// {
//     const { query } = props;
//     const queryStr = toQueryString(query);

//     return (
//         <ChartCard 
//             type="doughnut"
//             title="Last Service"
//             uri={`/api/statistics/last-service/summary?${queryStr}`}
//             csvLink={`/api/statistics/last-service/csv?${queryStr}`}
//             xlsxLink={`/api/statistics/last-service/xlsx${queryStr}`}
//             // detailsLink={`/api/statistics/last-service?${queryStr}&csv=true`}
//             map={mapService} 
//             colorscheme="office.Circuit6" />
//     );
// }

export default LastService;