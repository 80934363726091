import React from "react";
import ButtonPanel from "../../../components/ui/ButtonPanel";
import { TextField, Stack, StackItem, IStackTokens } from "office-ui-fabric-react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNotificationService } from "../../../hooks/useNotificationService";
import { adminCreateRole } from "../../../api";

export interface Props
{
    onAdded?: (id: string) => void;
    onDismiss?: () => void;
}

const schema = yup.object({
    name: yup.string().required("Name is a required field")
});

const tokens: IStackTokens = { childrenGap: "m" };

function AddRolePanel(props: Props)
{
    const { onDismiss, onAdded } = props;
    const { addNotification } = useNotificationService();
    const { handleChange, values, submitForm, errors, setErrors } = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            const res = await adminCreateRole(values.name);
            if(res.ok)
            {
                addNotification(`Role '${values.name}' was created.`);
                const { name } = await res.json();
                onAdded?.(name);
                onDismiss?.();
            }
            else
            {
                if(res.status === 409) // conflich
                {
                    setErrors({
                        name: "Name of role must be unique"
                    })
                }
            }
        }
    });
    
    return (
        <ButtonPanel
            isOpen
            primaryButtonText="Add"
            onPrimaryButtonClick={submitForm}
            onDismiss={onDismiss}
            headerText="Add Role"
            >

            <Stack tokens={tokens}>
                <StackItem>
                    <p>
                        Add a new role using a unique name. You can change role permissions after the role has been created.
                    </p>
                </StackItem>
                <StackItem>
                    <TextField label="Name" onChange={handleChange} name="name" value={values.name} errorMessage={errors.name} />
                </StackItem>
            </Stack>


        </ButtonPanel>
    )
}

export default AddRolePanel;