import React from "react";
import { Panel, PanelType, SelectionMode, Text, IColumn, ShimmeredDetailsList } from "office-ui-fabric-react";
import { useAuthorizedFetch } from "../../hooks/useFetch";
import { FaBalanceScale, FaTools } from "react-icons/fa"
import wifi from './../../assets/WiFi_green.png';
import scale from './../../assets/Scale_green.png';
import scaleIII from './../../assets/ScaleIII_green.png';
import service from './../../assets/Setup_green.png';
import trainer from './../../assets/Trainer_green.png';
import house from './../../assets/Adresse_green.png';


interface Props
{
    title: string;
    url: string;
    visible: boolean;
    onDismiss?: () => void;
    columns?: IColumn[];
}

interface Hoist
{
    liftType4: number;
}

const moduleInstalledColor = "#87c34b";

const hasModuleScale = (h: Hoist) => {
    return (Math.floor(h.liftType4 / 1000) % 10) > 0;  
};

const hasModuleService = (h: Hoist) => {
    return (Math.floor(h.liftType4 / 10) % 10) > 0;  
};

const getBinPosition = (binString:string, position:number):number => {
    if (position == 0) return -1;
    if (position > binString.length) return -1;

    const len = binString.length;
    const bit = binString.substring(len - (position-1), len - position);

    return Number(bit);
}

function DetailsPanel(props: Props)
{
    const { title, url, visible, columns, onDismiss } = props;
    const [, items] = useAuthorizedFetch<any>(url);
    return (
        <Panel
            isOpen={visible}
            onDismiss={onDismiss}
            isLightDismiss={true}
            type={PanelType.large}>

            <Text variant="large">{title}</Text>

            <ShimmeredDetailsList 
                setKey="items"
                columns={columns}
                selectionMode={SelectionMode.none}
                items={items ?? []} 
                enableShimmer={!items}
                onRenderItemColumn={(item, index, column) => {
                    if (column!.key === 'liftType4' ) {
                        return (
                            <>
                                {getBinPosition(item.liftType4.toString(),9) > 0 ? <><img src={trainer} height="20"/>&nbsp;</>: null}
                                {getBinPosition(item.liftType4.toString(),6) > 0 ? <><img src={scaleIII} height="20"/>&nbsp;</> : null}
                                {getBinPosition(item.liftType4.toString(),5) > 0 ? <><img src={wifi} height="20"/>&nbsp;</> : null}
                                {getBinPosition(item.liftType4.toString(),4) > 0 ? <><img src={scale} height="20"/>&nbsp;</> : null}
                                {getBinPosition(item.liftType4.toString(),2) > 0 ? <><img src={service} height="20"/>&nbsp;</> : null}
                            </> 
                        )
                    }
                    else{
                        return item[column?.fieldName!];
                      }
                  }}
          
            />
        </Panel>
    );
}

export default DetailsPanel;