import React, { Suspense, useState, useEffect, useCallback } from "react";
import { Routes, Navigate, useNavigate } from "react-router-dom";

import { SiteContainer } from "./components/layout";

import Header from "./components/layout/Header";
import Main from "./components/layout/Main";
import Sidebar from "./components/layout/Sidebar";

import SidebarNavigation from "./components/SidebarNavigation";
import AuthorizeRoute from "./api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./api-authorization/ApiAuthorizationRoutes";
import UserManagement from "./views/admin/user-management/UserManagement";
import Notifications from "./components/Notifications";
import UserStateManager from "./api-authorization/UserStateManager";

// const Dashboard = lazy(() => import(/* webpackPrefetch: true */ "./components/view/dashboard/Dashboard"));
// const Locations = lazy(() => import(/* webpackPrefetch: true */ "./components/view/admin/location/Locations"));
// const Hoists = lazy(() => import(/* webpackPrefetch: true */ "./components/view/hoist/Hoists"));
import Dashboard from "./views/dashboard/Dashboard";
import Locations from "./views/admin/location/Locations";
import Hoists from "./views/hoist/Hoists";
import EulaView from "./views/eula/eula";
import Subscription from "./views/admin/subscription/subscription";
import Settings from "./views/admin/settings/Settings";
import MapSite from "./views/map/map";
import Training from "./views/training/Training";
import Tag from "./views/admin/tag/Tag";


import RoleManagement from "./views/admin/user-management/RoleManagement";
import { Text } from "office-ui-fabric-react";
import { SidebarFooter } from "./components/layout/Sidebar";
import CheckMfaCompliance from "./api-authorization/CheckEnforcedMfa";
import { mfaCompliance } from "./atoms/system";
import { useRecoilValue } from "recoil";
import { authenticatedAtom, mfaAtom } from "./atoms/user";
import MfaRequired from "./MfaRequired";
import Audit from "./views/admin/audit/Audit";
import { RawIntlProvider } from "react-intl";
import { intl } from "./i18n";
import LicenseManager from "./components/LicenseManager";
import { useAuthorizedFetch } from "./hooks/useFetch";
import { License, licenseAtom, substriptionPageStep,refreshLicense, IRefreshLicense} from "./atoms/license";
import Eula from "./components/Eula";
import { useRecoilState, useSetRecoilState } from "recoil";
import { INavLink } from "office-ui-fabric-react/lib/Nav";
import { locationsFilterList } from "./atoms/google";
import { NotificationTimer } from "./components/NotificationTimer";
import { useIdleTimer } from "react-idle-timer"
import authService from "./api-authorization/AuthorizeService";
import { IOption } from "./views/admin/settings/Settings";


function LicenseRouter() {
    return (
        <Routes>
            <AuthorizeRoute path="/*" element={<Subscription />} />
        </Routes>
    )
}

interface ISubscriptionInfo {
    validLicense: boolean;
}
type JsonDate<T> = {
    [P in keyof T]: T[P] extends Date ? string : T[P];
}

interface LicenseReply
{
    details?: JsonDate<License["details"]>;
    valid: License["valid"];
}

function HasLicenseRouter() {
    return (
        <Routes>
            <AuthorizeRoute path="/" element={<Dashboard />} />
            <AuthorizeRoute path="/hoists/*" element={<Hoists />} />
            <AuthorizeRoute path="/map/*" element={<MapSite />} />
            <AuthorizeRoute path="/training/*" element={<Training />} />
            <AuthorizeRoute path="/eula/*" element={<EulaView />} />
            <AuthorizeRoute path="/admin/locations/*" element={<Locations />} />
            <AuthorizeRoute path="/admin/user-management/*" element={<UserManagement />} />
            <AuthorizeRoute path="/admin/role-management" element={<RoleManagement />} />
            <AuthorizeRoute path="/admin/audit" element={<Audit />} />
            <AuthorizeRoute path="/admin/subscription" element={<Subscription />} />
            <AuthorizeRoute path="/admin/settings" element={<Settings />} />
            <AuthorizeRoute path="/admin/tag" element={<Tag />} />
        </Routes>
    )
}


function LicenseCheck() {
    const [statusload, license, refresh] = useAuthorizedFetch<License>("api/customer/license");
    const [pageView, setPageView] = useRecoilState(substriptionPageStep);
    const navigate = useNavigate();
    const [refreshLic, setRefreshLic] = useRecoilState<IRefreshLicense>(refreshLicense);


    useEffect(() => {
        if (refreshLic.refresh === true)
        {
            refresh(); // get last license status
        }
    }, [refreshLic]);



    let valid = false;
    let dateNow = new Date();

    if (statusload === "success" && license !== undefined)
    {

        if(license?.valid && license?.details !== undefined && license.details.hardValidTo  < new Date() && license.details.hardValidTo  > new Date())
        {
            valid = true;
        }
        else if(license?.valid === true) {
            valid = true;
        }
        else{
            valid = false;
        }

        


        return ( valid ? <HasLicenseRouter /> : <LicenseRouter /> )
    }
    else
        return (<HasLicenseRouter />)
}






function MainComponent() {
    const mfaCompliant = useRecoilValue(mfaCompliance);
    const isAuthenticated = useRecoilValue(authenticatedAtom);
    const mfaEnabled = useRecoilValue(mfaAtom);


    if (isAuthenticated === true && mfaCompliant === false) {
        return <MfaRequired mfaEnabled={mfaEnabled} />;
    }

    return (
        <RawIntlProvider value={intl as any}>
            <Main>
                <Notifications />
                {/* <ProgressIndicator /> */}
                <LicenseCheck />
                <Suspense fallback={<div>Loading...</div>}>
                </Suspense>
            </Main>
        </RawIntlProvider>
    )
}

function SidebarBottom() {
    return (
        (process.env["GITVERSION_SEMVER"]) ? (
            <SidebarFooter>
                <Text variant="tiny">
                    Version: {process.env["GITVERSION_SEMVER"]} ({process.env["GITVERSION_SHORTSHA"]})
                </Text>
            </SidebarFooter>
        ) : null
    );
}

const handleOnIdle = () => {
    authService.signOut({});
};


interface ITimerIdle {
    timeOutInMin: number | undefined;
}

const TimerIdle = (props:ITimerIdle) => {
    let timeout = 1000 * 60 * props.timeOutInMin!;
    const { getRemainingTime, start, reset } = useIdleTimer({ timeout, onIdle: handleOnIdle, startManually:true });
    if (props.timeOutInMin != 0){
        reset();
        start();
    }
    
    return (null);

}



export default () => {
    const isAuthenticated = useRecoilValue(authenticatedAtom);
    const mfaCompliant = useRecoilValue(mfaCompliance);
    const mfaEnabled = useRecoilValue(mfaAtom);
    const [lFilterList, setLFilterList] = useRecoilState<string[]>(locationsFilterList);
    const [status, optionValue, refresh] = useAuthorizedFetch<IOption>("api/setting/GetOption/timeout");


    
    if (status === "success"){
        if (optionValue?.optionValue !== undefined && optionValue?.optionValue !== "")
        {
            //timeout = 1000*60*parseInt(optionValue?.optionValue);
            
        }
    }



    const removeMapData = useCallback((item: INavLink) => {
        if (item.name === "Hoists"){
          setLFilterList([]);
        }
        
    }, []);


    return (
        <SiteContainer>
            {/*<NotificationTimer timeout={10}/>*/}
            {optionValue?.optionValue !== undefined ? <TimerIdle timeOutInMin={parseInt(optionValue?.optionValue)}/> : null}
            <LicenseManager />
            <UserStateManager />
            <CheckMfaCompliance />

            <Header />

            {
                (isAuthenticated && mfaCompliant) ? (
                    <Sidebar>
                        <SidebarNavigation onLinkClick={(item) => removeMapData(item)} />
                        <SidebarBottom />
                        <Eula/>
                    </Sidebar>
                ) : null
            }

            <MainComponent />
            <ApiAuthorizationRoutes />

        </SiteContainer>
    );
}