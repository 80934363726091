import { atom } from "recoil";

export type chooseView = "overview" | "plans" | "payment" | "voucher" | "m3" | "all";

export interface License
{
    details?: {
        totalHoists: number;
        licensedHoists: number;
        softValidTo: Date;
        hardValidTo: Date;
    }
    valid: boolean;
}

export interface IValid {
    msg: string;
    valid: boolean | undefined;
    show: boolean;
    saved: boolean;
  }
  


export interface substriptionRefresh
{
    licenseID?: number
    refresh: boolean;
    refreshLicense: boolean;
}

export interface ISubstriptionPageStep
{
    step: number;
    pagetype: chooseView;
    lincenseRefresh: boolean;
}

export interface IRefreshLicense
{
    refresh: boolean;
}


export const licenseAtom = atom<License>({
    key: "license",
    default: {
        valid: true
    }
});

export const licenseValid = atom<boolean>({
    key: "licenseValid",
    default: false
});


export const showSubscriptionAtom = atom({
    key: "showSubscription",
    default: {
        showSubscriptionPlan: false,
    }
});

export const substriptionAtom = atom<substriptionRefresh>({
    key: "substription",
    default: {
        licenseID: 0,
        refresh: false,
        refreshLicense: false
    }
});


export const substriptionPageStep = atom<ISubstriptionPageStep>({
    key: "substriptionPageStep",
    default: {
        step: 1,
        pagetype: "overview",
        lincenseRefresh: false
    }
});


export const substriptionValidation = atom<IValid>({
    key: "substriptionValidation",
    default: {msg: "", valid: undefined, show:false, saved: false}
});


export const refreshLicense = atom<IRefreshLicense>({
    key: "refreshLicense",
    default: {refresh : false}
});



