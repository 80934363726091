import React, { useCallback, useEffect, useState } from "react";
import { Panel, IPanelProps, PrimaryButton, DefaultButton, Spinner, SpinnerSize } from "office-ui-fabric-react";

export interface Props extends IPanelProps
{
    primaryButtonText?: string;
    secondaryButtonText?: string;
    onPrimaryButtonClick?: () => void;
    onSecondaryButtonBlick?: () => void;
    primaryButtonDisabled?: boolean;
    secondaryButtonDisabled?: boolean;
    destroyChildrenOnDismiss?: boolean;
    isLoading?: boolean;
}

const buttonStyles = { root: { marginRight: 8 } };

function SpinnerWithDelay(props: { delay: number })
{
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const handle = setTimeout(() => setVisible(true), props.delay);
        return () => clearTimeout(handle);
    }, [props.delay]);

    return visible ? (
        <Spinner size={SpinnerSize.large} label="Loading..." />
    ) : null;
}

function ButtonPanel(props: Props)
{
    const {
        primaryButtonText = "Save", 
        secondaryButtonText = "Cancel", 
        onPrimaryButtonClick, 
        onSecondaryButtonBlick, 
        primaryButtonDisabled = false, 
        secondaryButtonDisabled = false,
        destroyChildrenOnDismiss = false,
        children,
        isLoading = false,
        ...rest 
    } = props;

    const onRenderFooterContent = useCallback(() => (
        <div>
            <PrimaryButton styles={buttonStyles} disabled={primaryButtonDisabled} onClick={onPrimaryButtonClick}>{primaryButtonText}</PrimaryButton>
            <DefaultButton disabled={secondaryButtonDisabled} onClick={onSecondaryButtonBlick ?? (() => props.onDismiss?.())}>{secondaryButtonText}</DefaultButton>
        </div>
    ), [onPrimaryButtonClick, onSecondaryButtonBlick, primaryButtonDisabled, primaryButtonText, props.onDismiss, secondaryButtonText, secondaryButtonDisabled]);

    const styles: IPanelProps["styles"] = isLoading ? ({
        scrollableContent: {
            display: "flex",
            flexDirection: "column"
        },
        content: {
            display: "flex",
            // backgroundColor: "antiquewhite",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center"
        }
    }) : undefined;

    return (
        <Panel 
            onRenderFooterContent={isLoading ? undefined : onRenderFooterContent}
            styles={styles}
            isFooterAtBottom={true}
            {...rest}>
            {
                (isLoading) ? (

                    <div>
                        <SpinnerWithDelay delay={200} />
                        {/* <Spinner size={SpinnerSize.large} label="Loading..." /> */}
                    </div>

                ) : (destroyChildrenOnDismiss && !props.isOpen) ? null : (
                    children
                )
            }
        </Panel>
    );
}

export default ButtonPanel;