import React, { useState } from "react";
import * as yup from "yup";
import {  TextField, MessageBarType } from "office-ui-fabric-react";
import { PanelForm, PanelFormSection, PanelFormFields } from "../../components/panelform/PanelForm";
import ButtonPanel from "../../components/ui/ButtonPanel";
import { useFormik } from "formik";
import { userChangePassword } from "../../api";
import { notifications } from "../../atoms/notifications";
import { useSetRecoilState } from "recoil";
import { v1 } from "uuid";
import styled from "styled-components";

export interface Props
{
    open?: boolean;
    onDismiss?: () => void;
}

const schema = yup.object({
    currentPassword: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().oneOf([yup.ref("password")], "Passwords do not match")
});

const ErrorList = styled.ul`
    li {
        color: red;
        padding: 6px 0;
    }
`;

function ChangePasswordPanel(props: Props)
{
    const { open, onDismiss } = props;
    const setNotifications = useSetRecoilState(notifications);
    const [submitErrors, setSubmitErrors] = useState<string[]>();
    const { handleChange, values, errors, submitForm  } = useFormik({
        initialValues: { 
            currentPassword: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema: schema,
        onSubmit: async ({ currentPassword, password, confirmPassword }) => {
            const res = await userChangePassword(currentPassword, password, confirmPassword);
            if(res.ok)
            {
                onDismiss?.();
                setNotifications(v => [...v, {
                    id: v1(),
                    messageBarType: MessageBarType.info,
                    children: <span>Your password was changed.</span>
                }]);
            }
            else
            {
                const errors: { code: string, description: string}[] = await res.json();
                setSubmitErrors(errors.map(x => x.description));
            }
        }
    });
    
    return (
        <ButtonPanel 
            isOpen={open}
            onDismiss={onDismiss}
            headerText="Change Password"
            primaryButtonText="Change"
            onPrimaryButtonClick={submitForm}
        >

            <PanelForm>
                <PanelFormSection>
                    <PanelFormFields>
                        <TextField name="currentPassword" type="password" label="Current Password" placeholder="Enter your current password" autoComplete="current-password"
                            onChange={handleChange} value={values.currentPassword} errorMessage={errors.currentPassword}
                            />
                        <TextField name="password" type="password" label="New Password" placeholder="Enter your new password" autoComplete="new-password"
                            onChange={handleChange} value={values.password} errorMessage={errors.password}
                            />
                        <TextField name="confirmPassword" type="password" label="New Password (again)" placeholder="Repeat your new password"  autoComplete="new-password"
                            onChange={handleChange} value={values.confirmPassword} errorMessage={errors.confirmPassword}
                            />
                    </PanelFormFields>
                </PanelFormSection>
                <PanelFormSection>
                {
                    (submitErrors) ? (
                        <ErrorList>
                        {
                            submitErrors.map((errorMessage, i) => (
                                <li key={i}>{errorMessage}</li>
                            ))
                        }
                        </ErrorList>
                    ) : null
                }
                </PanelFormSection>
            </PanelForm>

        </ButtonPanel>
    );
}

export default ChangePasswordPanel;