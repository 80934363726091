import authService from "../api-authorization/AuthorizeService"

export const authorizedFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => 
{
    const accessToken = await authService.getAccessToken();
    const _init: RequestInit = init || {};
    _init.headers = {
        ...(_init.headers || {}),
        Authorization: `Bearer ${accessToken}`
    }
    return fetch(input, _init);
}