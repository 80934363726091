import React, { useState, useEffect } from "react";
import qrcode, { QRCodeOptions } from "qrcode";

type QRCodeErrorCorrectionLevel = QRCodeOptions["errorCorrectionLevel"];

export interface Props
{
    data: string;

    className?: string;
    style?: React.CSSProperties;
    width?: number | string;
    height?: number | string;
    errorCorrectionLevel?: QRCodeErrorCorrectionLevel;
    version?: number;
}

function QRCode(props: Props)
{
    const { data, errorCorrectionLevel, version, ...rest } = props;
    const [dataUri, setDataUri] = useState<string>("");

    useEffect(() => {
        qrcode.toDataURL(data, {errorCorrectionLevel, version})
            .then(setDataUri)
            .catch(e => {
                console.warn("Error rendering QRCode", e);
            });
    }, [data, errorCorrectionLevel, version]);

    return (
        <img {...rest} alt="qrcode" src={dataUri}  />
    );
}

export default QRCode;