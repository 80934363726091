import React, { Component, useState, useEffect, useCallback } from "react";
import { useAuthorizedFetch } from "./../../hooks/useFetch";
import { PanelForm, PanelFormSection, PanelFormFields } from "../../components/panelform/PanelForm";
import {intl} from "../../i18n";
import { IHoistErrors } from './RegisterHoistPanel'

interface ILicenseDescriptionProps {
    title?: string;
    hoistErrors?: IHoistErrors[];
}

const convertErrorMsg = (msg:string):string => {
  let retMsg = "";

  switch(msg) {
    case "unknow":
      retMsg = "Can't add this hoist. No data received from the hoist.";
      break;
    case "notexists":
      retMsg = "Hoist serial do not exist. Check if the serial number is correct.";
      break;
    case "already":
      retMsg = "Can't add this hoist. Contact administrator for more info.";
      break;
    case "nolicense":
      retMsg = "Not enough hoists license.";
      break;
    default:
      retMsg =  "Unknow error."
  }
  
  return retMsg;
}



const ErrorPanel = (props:ILicenseDescriptionProps) => {


      const textStyle = {
        root: {
          color: "rgba(51, 51, 51, 0.933)",
        }
      };
    
      const cardTokens = {
        boxShadow: "none",
        maxWidth: "415px",
    };
    
      const stackTokens = {
        childrenGap: 10
      };


      const ErrorList = () =>{
        return (
          <React.Fragment>
          {
            props.hoistErrors!.map((item, index) => (
              <div key={index} style={row}>
                <div style={colUnderline}>{item.displaySerial}</div>
                <div style={colUnderline}>{convertErrorMsg(item.msg)}</div>
              </div>
            ))
          }
          </React.Fragment>
        );
    } 




    return (
      <PanelForm>
        <PanelFormSection>
        <section style={sectionStyle}>
        <ErrorList/>
        </section>
        </PanelFormSection>


      <PanelFormSection>
        <section style={sectionStyle}>
          <div style={row}>
            {/*<PrimaryButton onClick={onRestart} styles={buttonStyles}>Start CLM</PrimaryButton>*/}
          </div>
        </section>
      </PanelFormSection>



      </PanelForm>
      )
  }



  const dtf = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
});


const sectionStyle = {
  display: "table",
  width: "100%",
}

const row = {
  display: "table-row",
}

const col = {
  display: "table-cell",
  paddingBottom: "10px"
}

const colUnderline = {
  display: "table-cell",
  paddingBottom: "10px",
  paddingTop: "10px",
  borderBottom: "1px solid rgb(243, 242, 241)",
}

const active = {
  color : "rgba(16, 176, 67, 1)"
}

const expired = {
  color : "rgba(248, 7, 27, 1)"
}

const tableSpace = {
  marginTop: "40px"  
}

const bold = {
  fontWeight: 900
}



export default ErrorPanel