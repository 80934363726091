import * as React from "react";
import {GoogleMap, IPosition} from "./GoogleMap"
import { useAuthorizedFetch } from "../../hooks/useFetch";
import { Hoist } from "./../hoist/useLocationHoists";
import { Location, API_HOISTS } from "../../api";
import { Ilocations } from "./../../atoms/google";






const createLocationMarksList = (hoist:Hoist[]):Partial<Ilocations[]> => {
    let arr = [] as Partial<Ilocations[]>;

    hoist.map((val) => {
        //priority 1
        const apos = val.autoPosition !== undefined && val.autoPosition !== null ? val.autoPosition.split(",") : "";
        //priority 2
        const hpos = val.hoistPosition !== undefined && val.hoistPosition !== null  ? val.hoistPosition.split(",") : "";
        //priority 3
        const lpos = val.locationPosition !== undefined && val.locationPosition !== null ? val.locationPosition.split(",") : "";
        

       
        let hposCopy = Array.from(hpos);
        let lposCopy = Array.from(lpos);
        let aposCopy = Array.from(apos);
        hposCopy[0] = hposCopy[0] === undefined ? "" : hposCopy[0];
        hposCopy[1] = hposCopy[1] === undefined ? "" : hposCopy[1];
        lposCopy[0] = lposCopy[0] === undefined ? "" : lposCopy[0];
        lposCopy[1] = lposCopy[1] === undefined ? "" : lposCopy[1];
        aposCopy[0] = aposCopy[0] === undefined ? "" : aposCopy[0];
        aposCopy[1] = aposCopy[1] === undefined ? "" : aposCopy[1];
        


        if (val.useAutoAddress === true && aposCopy[0] !== "") 
        {
            arr.push({lat: parseFloat(apos[0]), lng: parseFloat(apos[1]), hoist: val.hoistSerialNo, status: val.status}); // 1
        }
        else if (val.useAutoAddress === true && aposCopy[0] === "" && hposCopy[0] !== ""){
            arr.push({lat: parseFloat(hpos[0]), lng: parseFloat(hpos[1]), hoist: val.hoistSerialNo, status: val.status}); // 2
        }
        else if (val.useAutoAddress === false &&  hposCopy[0] !== ""){
            arr.push({lat: parseFloat(hpos[0]), lng: parseFloat(hpos[1]), hoist: val.hoistSerialNo, status: val.status}); //2
        }
        else if (val.useAutoAddress === false && hposCopy[0] === ""){
            arr.push({lat: parseFloat(lpos[0]), lng: parseFloat(lpos[1]), hoist: val.hoistSerialNo, status: val.status}); //3
        }


/*        if (lpos !== "" && lpos[0] !== "undefined")
        {
            arr.push({lat: parseFloat(lpos[0]), lng: parseFloat(lpos[1]), hoist: val.hoistSerialNo, status: val.status});
        }
        else
        {
            if (hpos !== "" && hpos[0] !== "undefined")
            {
                arr.push({lat: parseFloat(hpos[0]), lng: parseFloat(hpos[1]), hoist: val.hoistSerialNo, status: val.status});
            }
       }*/
    })

    return arr;

}


const MapSite = () => {


//    const [position, setPosition] = React.useState<IPosition>({lag:"55.98969912059262", lng:"11.340736473491981"});
//    const [position, setPosition] = React.useState<IPosition>({lag:"55.80360406337439", lng:"10.947860456424324"}); // DK (zoom 7)
    const [position, setPosition] = React.useState<IPosition>({lag:"42.02541704404555", lng:"5.39270976201903"}); // world (zoom 2)
    const [, allHoists, refresh] = useAuthorizedFetch<Hoist[]>(`${API_HOISTS}/`);
    const [locationList, setLocationList] = React.useState<Ilocations[]>([]);
   


    React.useEffect(() => {
        if (allHoists !== undefined){
            let data = createLocationMarksList(allHoists!) as Ilocations[];
            setLocationList(data);
        }
    },[allHoists]);



    return (

        <div style={{height:"100%", width:"100%"}}>
            <GoogleMap zoom={2} center={{ lat:  parseFloat(position.lag), lng: parseFloat(position.lng)}} locations={locationList} />
        </div>

    )
}


export default MapSite;