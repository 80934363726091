import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat";
import { createIntl, createIntlCache } from "@formatjs/intl";

const cache = createIntlCache();
const intl =  createIntl({
    locale: "en-US",
    messages: {},
    onError: () => {}
}, 
    cache
);

export { intl };