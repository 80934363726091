import React from "react";
import { Layer, Overlay, DefaultEffects, Spinner } from "office-ui-fabric-react";
import styled from "styled-components";

export interface Props
{
    label?: string;
}

const boxShadow = DefaultEffects.elevation8;

const CenteredBox = styled.div`
    max-width: 50%;
    width: 200px;
    height: 100px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
`;

export function OverlayProgressBox(props: { children?: React.ReactNode })
{
    return (
        <Layer>
            <Overlay>
                <CenteredBox style={{boxShadow}}>
                    {props.children}
                </CenteredBox>
            </Overlay>
        </Layer>
    );
}

function OverlayProgress(props: Props)
{
    const { label = "Please wait..." } = props;
    return (
        <OverlayProgressBox>
            <Spinner label={label} />
        </OverlayProgressBox>
    );
}

export default OverlayProgress;