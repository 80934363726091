import React from 'react';
import ReactDOM from 'react-dom';
import "./i18n";
import { BrowserRouter } from 'react-router-dom';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import { ThemeContext } from "styled-components";
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

import "reset-css";
import { theme } from './theme/guldmann';
import { RecoilRoot } from 'recoil';

const rootElement = document.getElementById('root');

// initializeIcons("");
initializeIcons("https://static2.sharepointonline.com/files/fabric/assets/icons/");
initializeFileTypeIcons();



ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <ThemeContext.Provider value={theme}>
        <App />
      </ThemeContext.Provider>
    </BrowserRouter>
  </RecoilRoot>,
  rootElement);

//registerServiceWorker();

