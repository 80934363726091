import authService from "../api-authorization/AuthorizeService";
import { Iproducts } from "../views/admin/subscription/SubscriptionList";
import { IVoucherInfo } from "../views/admin/subscription/Voucher";
import { IPlan } from "../views/admin/subscription/subscription";
import { PasswordPolicySchema, UserProfileSchema } from "./schema";
import { IOption } from "../views/admin/settings/Settings";
import { IDbPagination } from "./../components/Pagination";
import { IQuery } from "../views/training/Training";
import { ISaveTags } from "./../components/tagpopup/tagpopup"




export const API_ACCOUNT = "/api/account";
export const API_CUSTOMER = "/api/customer";
export const API_USER = "/api/user";
export const API_LOCATIONS = "/api/location";
export const API_HOISTS = "/api/hoists";
export const API_TIMEZONE = "/api/timezones";
export const API_ADMIN_LOCATIONS = "/api/admin/location";
export const API_ADMIN_HOISTS = "/api/admin/hoist";
export const API_ADMIN_USERS = "/api/admin/user";
export const API_ADMIN_ROLES = "/api/admin/role";
export const API_ADMIN_PASSWORD_POLICY = "api/admin/passwordpolicy";
export const API_ADMIN_AUDIT = "/api/admin/audit";
export const API_ADMIN_SUBSCRIPTION = "/api/admin/subscription";
export const API_ADMIN_OPTIONS = "/api/setting";
export const API_EULA = "/api/eula";
export const API_ABOUT = "/api/about";
export const API_ERRORLOG = "/api/errorlog";
export const API_TRAINING = "/api/training";
export const API_TAG = "/api/tag";




export interface Location
{
    id: number;
    label: string;
    children: Location[];
}

export interface ExpandedLocation extends Location
{
    timezone: string | null;
    effectiveTimezone: string;
    address: string;
    effectiveAddress : string;
    position: string;
}

export interface Timezone
{
    displayName: string;
    name: string;
    bias: number;
}


export interface IUpdateHoistPlace
{
    address: string;
    lat: string;
    lng: string;
    hoistId: string
}

export interface IUpdateAutoAddress
{
    updateAutoAddress: boolean;
}


export interface IUpdateLocationPlace
{
    hoistNo: string;
    locationId: number;
}

export interface IApiKeys{
    user: string;
    key: string;
}

export const apiCall = async (url: string, method: string, data?: any) => 
{
    const accessToken = await authService.getAccessToken();
    const authHeader: Record<string,string> = accessToken ? ({ Authorization: `Bearer ${accessToken}` }) : ({});
    return fetch(url, {
        method,
        headers: (data === undefined) ? {...authHeader} : {
            "Content-Type": "application/json",
            ...authHeader
        },
        credentials: "same-origin",
        body: data && JSON.stringify(data)
    });
}

export const adminAddLocation = (label: string, parentId?: number) => 
    apiCall(API_ADMIN_LOCATIONS, "POST", { label, parentId });

export const adminDeleteLocation = (id: number) =>
    apiCall(`${API_ADMIN_LOCATIONS}/${id}`, "DELETE");

interface UpdateLocation
{
   label?: string; 
   parentId?: number;
   timezone?: string | null;
   address?: string | null;
   position?: string | null;
}

export const adminUpdateLocation = (id: number, data: UpdateLocation) => 
    apiCall(`${API_ADMIN_LOCATIONS}/${id}`, "PUT", data);

export const adminHoistAssignLocation = (locationId: number, hoistSerialNos: string[]) =>
    apiCall(`${API_ADMIN_HOISTS}/assign/${locationId}`, "POST", hoistSerialNos);

export const adminHoistUnassignLocation = (hoistSerialNos: string[]) =>
    apiCall(`${API_ADMIN_HOISTS}/unassign`, "POST", hoistSerialNos);

export const adminUpdatePasswordPolicy = (policy: PasswordPolicySchema) => 
    apiCall(`${API_ADMIN_PASSWORD_POLICY}`, "POST", policy);

export const adminDeleteUser = (email: string) => 
    apiCall(`${API_ADMIN_USERS}/${email}`, "DELETE");

export const adminInviteUsers = (emails: string[]) => 
    apiCall(`${API_ADMIN_USERS}/invite`, "PUT", emails);

export const adminResendInvites = (emailAddresses: string[]) => 
    apiCall(`${API_ADMIN_USERS}/resend-invites`, "POST", emailAddresses);

export const adminRegisterHoists = (hoists: string[]) =>
    apiCall(`${API_ADMIN_HOISTS}/register`, "POST", hoists);

export const userAuthenticatorEnable = (code: string) => 
    apiCall(`${API_USER}/authenticator-enable`, "POST", { code });

export const userAuthenticatorDisable = (password: string) => 
    apiCall(`${API_USER}/authenticator-disable`, "POST", { password });

export const userInfo = (userId?: string) => 
    apiCall(`${API_USER}/info/${userId ?? ""}`, "GET");

export const userProfile = () => 
    apiCall(`${API_USER}/profile`, "GET");

export const updateUserProfile = (profile: UserProfileSchema) =>
    apiCall(`${API_USER}/profile`, "PUT", profile);
    
export const userChangePassword = (currentPassword: string, password: string, confirmPassword: string) => 
    apiCall(`${API_USER}/change-password`, "POST", { currentPassword, password, confirmPassword });

export const adminCreateRole = (name: string) =>
    apiCall(`${API_ADMIN_ROLES}`, "POST", { name, acl: [] });

export const adminUpdateRole = (roleId: string, data: { name: string, acl: { locationId: number, read: string }[] }) =>
    apiCall(`${API_ADMIN_ROLES}/${roleId}`, "PUT", data);

export const adminDeleteRole = (roleId: string) =>
    apiCall(`${API_ADMIN_ROLES}/${roleId}`, "DELETE");

export const adminAssignUsersToRole = (roleId: string, userNames: string[]) => 
    apiCall(`${API_ADMIN_ROLES}/${roleId}/users`, "PUT", { userNames });

export const adminsubscribe = (userNames: string[]) =>
    apiCall(`${API_ADMIN_SUBSCRIPTION}/subscribe`, "POST", { userNames });

export const getVoucher = (voucherCode: string) =>
    apiCall(`${API_ADMIN_SUBSCRIPTION}/checkvoucher`, "POST", { voucherCode });

export const createVoucher = (voucherCode: string) =>
    apiCall(`${API_ADMIN_SUBSCRIPTION}/createvoucher`, "POST", { voucherCode });

export const cancelsubscription = () =>
    apiCall(`${API_ADMIN_SUBSCRIPTION}/cancelsubscription`, "GET");

export const createPlan = (plan?:IPlan) =>
    apiCall(`${API_ADMIN_SUBSCRIPTION}/createplan`, "POST", plan);

export const saveAgreEeula = () =>
    apiCall(`${API_EULA}/saveagreeeula`, "GET");

export const getversion = () =>
    apiCall(`${API_ABOUT}/getversion`, "GET");

export const getOptions = () =>
    apiCall(`${API_ADMIN_OPTIONS}/getoptions`, "GET");

export const saveOption = (option?:IOption) =>
    apiCall(`${API_ADMIN_OPTIONS}/saveoption`, "POST", option);

export const getlocationHoistInfo = (hoistId?: number) => 
    apiCall(`${API_LOCATIONS}/getlocationhoistinfoasync/${hoistId ?? 0}`, "GET");

export const updateHoistPlace = (hoistPlace?:IUpdateHoistPlace) =>
    apiCall(`${API_ADMIN_LOCATIONS}/updatehoistPlaceasync`, "POST", hoistPlace );

export const updateLocationPlace = (hoistNo: string, data:IUpdateLocationPlace) =>
    apiCall(`${API_ADMIN_LOCATIONS}/updatelocationPlaceasync/${hoistNo}`, "PUT", data );

export const updateHoistAutoAddress = (hoistNo: string, data:IUpdateAutoAddress) =>
    apiCall(`${API_ADMIN_LOCATIONS}/updateHoistAutoAddressasync/${hoistNo}`, "PUT", data );

export const GetLocationsPlaceAsync = (hoistSerialNos: string) =>
    apiCall(`${API_HOISTS}/getLocationsplaceasync/${hoistSerialNos}`, "GET");
    
export const GetServiceStatus = (hoistSerialNo: string) =>
    apiCall(`${API_HOISTS}/GetServiceStatus/${hoistSerialNo}`, "GET");

export const hoistUpdateName = (id: string, data : {name: string}) => 
    apiCall(`${API_HOISTS}/UpdateName/${id}`, "PUT", data);

export const getErrorList = (pagination: IDbPagination) => 
    apiCall(`${API_ERRORLOG}/getErrorList/`, "POST", pagination);

export const getActiveErrors = (hoistSerialNo: string) =>
    apiCall(`${API_ERRORLOG}/GetActiveErrors/${hoistSerialNo}`, "GET");
    
export const getApikey = () =>
    apiCall(`${API_ADMIN_OPTIONS}/getapikey`, "GET");
   
export const generateApikey = () =>
    apiCall(`${API_ADMIN_OPTIONS}/generatekey`, "GET");

export const GetOptionValue = (optionType: string) =>
    apiCall(`${API_ADMIN_OPTIONS}/GetOption/${optionType}`, "GET");

export const getTrainingList = (query: IQuery) => 
    apiCall(`${API_TRAINING}/getTrainingList/`, "POST", query);

export const getSuggestionsTags = () =>
    apiCall(`${API_TAG}/GetSuggestionsTags`, "GET");

export const SaveTagsAsync = (saveTags?:ISaveTags) =>
    apiCall(`${API_TAG}/SaveTagsAsync`, "POST", saveTags);

export const getSavedTags = (keyId:string) =>
    apiCall(`${API_TAG}/getSavedTags/${keyId}`, "GET");

export const deleteSelectedTag = (tagId:number, trainingId:number) =>
    apiCall(`${API_TAG}/DeleteSelectedTag/${tagId}`, "POST", trainingId);

export const getTagsList = (typeId:string | undefined) =>
    apiCall(`${API_TAG}/GetTagsList/${typeId}`, "GET");

export const getTypeList = () =>
    apiCall(`${API_TAG}/GetTypeList`, "GET");

    