import { filterMarkersToPaddedViewport } from "@googlemaps/markerclusterer";
import { mergeStyles, FontIcon, Checkbox, IconButton, getTheme, Modal, IIconProps, IStackProps, mergeStyleSets, IButtonStyles, FontWeights, IColumn, Selection, Link, Panel, ColumnActionsMode, IContextualMenuItem, ContextualMenu, ContextualMenuItemType, ShimmeredDetailsList, Check } from "office-ui-fabric-react";
import React, { useRef, useMemo, useState, useCallback } from "react";
import  ErrorView  from './../../components/hoist/ErrorView'
import  GeneralInfoView  from './../../components/hoist/GeneralInfoView'
import  LocationView  from './../../components/hoist/LocationView'
import  StatisticsView  from './../../components/hoist/StatisticsView'
import { Hoist } from "./useLocationHoists";
import './../../style.css';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: "950px",
      height:"70%",
    },
    header: [
      theme.fonts.medium,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });
  
const stackProps: Partial<IStackProps> = {
    horizontal: true,
    tokens: { childrenGap: 40 },
    styles: { root: { marginBottom: 20 } },
  };
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  
  const menuLi = {
      display: "inline",
      marginRight: "5px",
  }
  
  const menuUl = {
    display: "inline",
  }
  
  const links = {
    cursor: "pointer",
    "&:hover": {
      color: "red"
    },
  }
  
  const setmenuUnderLines = (set:ViewFactoryChoice, store:ViewFactoryChoice):string | undefined => {
    let retValue = "underline-on-hover "
  
    if (set === store)
    {
      retValue += "underline-on-set";
    }
  
    return retValue
  }


  type ViewFactoryChoice = "GeneralInfoView" | "LocationView" | "StatisticsView" | "ErrorView";

  interface IViewFactory {
    view: ViewFactoryChoice;
    hoist?: Hoist;
    reload: () => void;
  }

  const ViewFactory = (viewChoice:IViewFactory):JSX.Element =>{

    const {view, hoist} = viewChoice;
  
    let outputView = <GeneralInfoView hoist={hoist!} reload={() => viewChoice.reload()}></GeneralInfoView>
  
    if (view === "GeneralInfoView"){
      outputView = <GeneralInfoView hoist={hoist!} reload={() => viewChoice.reload()}></GeneralInfoView>;
    }
    else if (view === "StatisticsView"){
      outputView = <StatisticsView hoist={hoist!}></StatisticsView>
    }
    else if (view === "LocationView"){
      outputView = <LocationView hoist={hoist!} reload={() => viewChoice.reload()}></LocationView>
    }
    else if (view === "ErrorView"){
      outputView = <ErrorView hoist={hoist!}></ErrorView>
    }
  
     return outputView;
  
  }

interface IModalHoist {
    isOpen: boolean;
    hoistItem: Hoist | undefined
    onDismiss: () => void;
    onReload: () => void;
}


export const ModalHoist = (props: IModalHoist) => {
    const [viewMode, setViewMode] = useState<ViewFactoryChoice>("GeneralInfoView");


return(

<Modal titleAriaId={""} isOpen={props.isOpen} onDismiss={props.onDismiss} isBlocking={false} containerClassName={contentStyles.container}  >
<div className={contentStyles.header}>
  <span id={"topmenu"}>
    <ul style={menuUl}>
        <li style={menuLi} className={setmenuUnderLines("GeneralInfoView",viewMode)}><a style={links} onClick={() => setViewMode("GeneralInfoView")}>General information</a></li>
        <li style={menuLi} className={setmenuUnderLines("StatisticsView",viewMode)}><a style={links} onClick={() => setViewMode("StatisticsView")}>Statistics</a></li>
        <li style={menuLi} className={setmenuUnderLines("LocationView",viewMode)}><a style={links} onClick={() => setViewMode("LocationView")}>Location</a></li>
        <li style={menuLi} className={setmenuUnderLines("ErrorView",viewMode)}><a style={links} onClick={() => setViewMode("ErrorView")}>Error history</a></li>
    </ul>
  </span>
  
  <IconButton
    styles={iconButtonStyles}
    iconProps={cancelIcon}
    ariaLabel="Close popup modal"
    onClick={() => props.onDismiss()}
  />
</div>
<div className={contentStyles.body}>
    <ViewFactory view={viewMode} hoist={props.hoistItem} reload={() => props.onReload()} />
   
</div>
</Modal>


)



}



