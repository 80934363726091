import React, { useCallback, useMemo } from "react";
import { ICommandBarItemProps, CommandBar, IContextualMenuItem } from "office-ui-fabric-react";
import { Location } from "../../../api";

export interface Props
{
    currentLocation?: Location;
    onCommandClick?: (name: string) => void;
}

const commandMenu: ICommandBarItemProps[] =  [{
    key: "location-add",
    text: "Add",
    iconProps: { iconName: "AddTo" }
}];

function LocationCommandBar(props: Props)
{
    const { onCommandClick } = props;
    
    const onItemClick = useCallback((_, item?: IContextualMenuItem) => {
        if(item?.key !== undefined)
        {
            onCommandClick?.(item.key);
        }
    }, [onCommandClick]);
    
    const items: ICommandBarItemProps[] = useMemo(() => commandMenu.map(item => ({ ...item, onClick: onItemClick })), [onItemClick]);

    return (
        <CommandBar items={items} />
    );
}

export default LocationCommandBar;