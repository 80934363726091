
export const DAY_MS = 24 * 60 * 60 * 1000;

export const addDays = (date: Date, days: number): Date => 
    new Date(date.getTime() + days * DAY_MS);

/**
 * Returns the date portion of a Date object (with the time part being midnight UTC)
 * @param date 
 */
export const getDate = (date: Date): Date => 
    new Date(date.getTime() - (date.getTime() % DAY_MS));

export const max = (...dts: Date[]) =>
    dts.reduce<Date | undefined>((result, date) => 
        (result === undefined) 
            ? date
            : (result.getTime() < date.getTime()) 
                ? date 
                : result, undefined);

export const min = (...dts: Date[]) =>
    dts.reduce<Date | undefined>((result, date) => 
        (result === undefined) 
            ? date
            : (result.getTime() > date.getTime()) 
                ? date 
                : result, undefined);
