import { DialogType, IDialogContentProps } from "office-ui-fabric-react";

export const buttonStyles = { root: { marginRight: 8, marginLeft:5, marginTop:5 } };

export const buttonCancelStyles = { 
    root: { 
        marginRight: 8, 
        marginLeft:5, 
        marginTop:5, 
        backgroundColor:"rgba(0, 0, 0, 1)",
        borderColor:"rgba(0, 0, 0, 1)", 
    },
        rootHovered: {
            backgroundColor: "rgba(255, 0, 61, 1)",
            borderColor:"rgba(255, 0, 61, 1)", 
        } 
};

export const textStyle = {
    root: {
      color: "rgba(51, 51, 51, 0.933)",
      fontSize: 16,
      marginLeft: "30px",
    },
    
};  
  
export const linkStyle = {
    textDecoration: "none",
    color: "rgba(84, 66, 224, 1)"
};   

export const contentContainer = {
    marginTop:"20px",
};

export const chooseContainer = {
    marginLeft: "25px",
    marginTop:"10px",
};   

export const dialogContentProps = {
    type: DialogType.normal,
    title: 'Cancel subscription',
    closeButtonAriaLabel: 'Close',
    subText: 'Are you sure you want to cancel your subscription?',
};

export const dialogContentPropsSave = (title:string, content:string): IDialogContentProps => {
    return ({ type: DialogType.normal,
        title: title,
        closeButtonAriaLabel: 'Close',
        subText: content,
    })
};

export const contestContainer = {
    marginTop:"20px",
  } 

  export const subscriptionContainer = {
    marginTop:"20px",
    marginLeft:"30px",
  } 

  

export const dialogStyles = { main: { maxWidth: "450px" } };


