import styled from "styled-components";

export const PanelContent = styled.div`
    p { margin: 6px 0px; }
    p + p { margin-top: 0px; }
    em { font-weight: bold; }
`;

export const PanelButtons = styled.div`
    button + button { margin-left: 8px; }
`;



