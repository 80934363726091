import React from "react";
import { CommandBar, ICommandBarItemProps, ContextualMenuItem } from "office-ui-fabric-react";
//import { User, Role } from "./UserManagement";
import styled from "styled-components";
import { all } from "../../../util/array";

export interface Props
{
  //  selectedUsers: User[];
 //   roles?: Role[];
      onAddTagClick?: () => void;
  //  onAssignRoleClick?: (role: Role) => void;
  //  onSendInvitesClick?: () => void;
  //  onPasswordPolicyClick?: () => void;
}

const CommandBarWrapper = styled.div`
    border-bottom: "1px solid #e0e0e0";
    display: "flex";
    padding: 2px;
`;

const commandBarStyle: React.CSSProperties = {
    flexGrow: 1
};

function TagListCommandBar(props: Props)
{
    const { onAddTagClick } = props;
    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: "new",
            text: "Add tag",
            iconProps: { iconName: "add" },
            onClick:  onAddTagClick,
       },
];

 
    
    return (
        <CommandBarWrapper>
            <CommandBar items={commandBarItems}  style={commandBarStyle}  />
        </CommandBarWrapper>    
    );
}

export default TagListCommandBar;