import * as React from "react";
import { useCallback, useContext } from "react"
import { TreeItemLi, TreeItemIcon, Tree, TreeItemLabel } from "./TreeView.styles";
import { produce } from "immer";

export interface Node<T>
{
    id: string;
    data?: T;
    label: string;
    disabled?: boolean;
    children?: Node<T>[];
}

interface TreeItemProps<T>
{
    node: Node<T>;
    open?: boolean;
    onClick?: (node: Node<T>) => void;
    active?: boolean;
    disabled?: boolean;
    dispatch: React.Dispatch<TreeAction>;
    state: TreeState;
}

function TreeItem<T>(props: TreeItemProps<T>)
{
    const { open = false, node, onClick, dispatch, state, disabled } = props;
    
    const hasChildren = (node.children?.length ?? 0) > 0;

    // disable deps warning below since we don't need dispatch in the deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    const toggle = useCallback(() => !disabled && dispatch({ type: "TOGGLE", node: node.id }), [node.id, disabled]);
    const onNodeClick = useCallback(() => !disabled && onClick?.(node), [node, onClick, disabled]);
    
    return (
        <TreeItemLi active={props.active} disabled={disabled}>
            <TreeItemIcon show={hasChildren} open={open} onClick={toggle} />
            <TreeItemLabel onClick={onNodeClick}>{node.label}</TreeItemLabel>
            { open && hasChildren ? (
                <TreeViewRender nodes={node.children!} onNodeClick={onClick} dispatch={dispatch} disabled={disabled} state={state} />
            ) : null }
        </TreeItemLi>
    );
}

interface TreeState 
{
    activeNode?: string;
    open: { [key: string]: boolean };
}

type SimpleAction<T extends string> = { type: T };
type NodeAction<T extends string> = { type: T, node: string };
type TreeAction = SimpleAction<"COLLAPSE_ALL"> | SimpleAction<"EXPAND_ALL">
    | NodeAction<"TOGGLE"> | NodeAction<"SET_ACTIVE_NODE">
;

const treeReducer = (state: TreeState, action: TreeAction): TreeState => produce(state, state => {
    if(action.type === "COLLAPSE_ALL" || action.type === "EXPAND_ALL")
    {
        const val = action.type === "EXPAND_ALL";
        for(let nodeId of Object.keys(state.open))
        {
            state.open[nodeId] = val;
        }
        return;
    }

    else if(action.type === "TOGGLE")
    {
        state.open[action.node] = !state.open[action.node];
    }
    
    else if(action.type === "SET_ACTIVE_NODE")
    {
        state.activeNode = action.node;
    }
});

const getNodeOpen = (state: TreeState, node: string) => !!state.open[node];

interface TreeViewRenderProps<T>
{
    className?: string;
    style?: React.CSSProperties;
    state: TreeState;
    dispatch: React.Dispatch<TreeAction>;
    nodes: Node<T>[];
    disabled?: boolean;
    onNodeClick?: (node: Node<T>) => void;
}

export function TreeViewRender<T>(props: TreeViewRenderProps<T>)
{
    const { dispatch, nodes, state, onNodeClick, disabled = false,  ...rest } = props;
    const activeNodeId = useContext(TreeContext);
    
    return (
        <Tree {...rest}>
        {
            nodes.map(item => (
                <TreeItem<T> key={item.id} 
                    disabled={disabled}
                    dispatch={dispatch}
                    active={activeNodeId === item.id}
                    state={state}
                    node={item} 
                    open={getNodeOpen(state, item.id)} 
                    onClick={onNodeClick}
                    />
            ))
        }
        </Tree>
    )

}

export interface Props<T>
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;
    nodes: Node<T>[];
    activeNodeId?: string;
    disabled?: boolean;
    onNodeClick?: (node: Node<T>) => void;
}

const TreeContext = React.createContext<string | undefined>(undefined);

function TreeView<T>(props: Props<T>)
{
    const [state, dispatch] = React.useReducer((treeReducer), { open: {} });
    return (
        <TreeContext.Provider value={props.activeNodeId}>
            <TreeViewRender dispatch={dispatch} state={state} {...props} />
        </TreeContext.Provider>
    );
}

export default TreeView;