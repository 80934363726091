import * as React from "react";
import { useRef, useEffect, useMemo } from "react"
import { Hoist } from "./../../views/hoist/useLocationHoists";
import { ErrorListControl  }  from "./../ErrorListControl"

export interface Props 
{
    title?: string;
    hoist: Hoist;
}


const ErrorView = (props: Props) =>
{

    return(
        <>
            <div>
               <ErrorListControl hoistNo={props.hoist.hoistSerialNo}/>
            </div>
        </>
        
        
        )

}

export default ErrorView;