import React, {useState, useCallback, useEffect, MouseEvent, useRef} from "react";
import { Text, Icon, getTheme } from "office-ui-fabric-react";


export interface IErrorList {
    errorState: number;
    errorTypeNbr: number;
    erorTypeTxt: string;
    groupName: string;
    statusTxt: string;
    date: Date;
}

export interface IPagination {
    totalItems: number;
    maxShowing: number;
    index?: number;
    onChange: (dbPagination:IDbPagination) => void;
}

export interface IDbPagination {
    offset: number;
    take: number;
    hoistNo: string;
    count?: number | undefined;
}

export interface IPagingResponse {
    count?: number | undefined;
    arrList: IErrorList[];
}



export const Pagination = (props:IPagination) => {
    const [pageIndex, setPageIndex] = useState<JSX.Element[]>();
    const [valueClick, setValueClick] = useState<number>(1);
    

    const onIndexClick = (e:MouseEvent) => {
        setValueClick(parseInt(e.currentTarget.id));
    }
    

    useEffect(() => {
        let indexing = Math.ceil(props.totalItems / props.maxShowing);
        let indents = [];

        for (var i = 1; i <= indexing; i++) {
            if (valueClick === i)
            {
                indents.push(<li className="select-index" key={i} id={i.toString()} onClick={(e) => onIndexClick(e)}>{i}</li>);
            }
            else{
                indents.push(<li key={i} id={i.toString()} onClick={(e) => onIndexClick(e)}>{i}</li>);
            }
        }

        setPageIndex(indents);
        
        indexCal();


    }, [valueClick, props.totalItems]);
  

    const PagingItems = () => {

        return (<>{pageIndex}</>);
    } 

    const onLeftClick = () => {
  
        if (valueClick > 1){
            setValueClick(valueClick => valueClick - 1);
        }

    }
    
    const onRightClick = () => {
        let indexing = Math.ceil(props.totalItems / props.maxShowing);
        
        if (valueClick < indexing){
            setValueClick(valueClick => valueClick + 1);
        }
    }

    


    const indexCal = () => {
        const indexing = Math.ceil(props.totalItems / props.maxShowing);
        const offset = valueClick-1 === 0 ? ((valueClick-1) * props.maxShowing) : ((valueClick-1) * props.maxShowing)+1;
        const take =  props.maxShowing;
        props.onChange({offset:offset, take:take, hoistNo:""});

//        console.log(valueClick);
//        console.log("skip = " + offset);
//        console.log("take = " + take);
    };


    const PagingControl = () => {
        return (
            <div className="paging">
            <ul>
            <li>
                <Icon iconName="ChevronLeft" style={{ fontSize: 11 }} onClick={() => onLeftClick()}/>
            </li>     
                <PagingItems/>
            <li>
                <Icon iconName="ChevronRight" style={{ fontSize: 11}}  onClick={() => onRightClick()}/>
            </li>     


            </ul>
            </div>
        )
    }

    return(
        <PagingControl/>
    )
}
