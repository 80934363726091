import React from "react";
import { Panel, TextField, IconButton, PrimaryButton, DefaultButton } from "office-ui-fabric-react";
import { PanelForm, PanelFormSection, PanelFormFields } from "../../../components/panelform/PanelForm";
import * as yup from "yup";
import { useFormik } from "formik";
import { adminInviteUsers } from "../../../api";

export interface Props
{
    visible?: boolean;
    onDismiss?: () => void;
    onUsersInvited?: () => void;
}

const schema = yup.object({
    emails: yup.array(
        yup.string().email("Not a valid email address")
    )
});

const buttonStyles = { root: { marginRight: 8 } };

function InviteUsersPanel(props: Props)
{
    const { visible, onDismiss, onUsersInvited } = props;
    const { handleChange, values, errors, handleBlur, setValues, dirty } = useFormik({
        initialValues: { emails: [""] },
        validationSchema: schema,
        onSubmit: () => {},
        validateOnBlur: true,
        validateOnChange: false,
    });

    const onSave = async () => 
    {
        await adminInviteUsers(values.emails);
        onUsersInvited?.();
        onDismiss?.();
    }

    const onRenderFooterContent = () => (
        <div>
            <PrimaryButton onClick={onSave} styles={buttonStyles} disabled={!dirty}>Save</PrimaryButton>
            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </div>
    );


    return (
        <Panel 
            headerText="Invite Users"
            isOpen={visible}
            onDismiss={onDismiss}
            isFooterAtBottom={true}
            onRenderFooterContent={onRenderFooterContent}
        >
            <PanelForm>
                <p>
                    Please provide the email addresses of the users you want to invite below.
                </p>
                <PanelFormSection>
                    <PanelFormFields>
                    {
                        values.emails?.map((value, id) => (
                            <TextField 
                                placeholder="Email address" 
                                name={`emails[${id}]`} 
                                key={`emails-${id}`} 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMessage={errors.emails?.[id]}
                                value={value} />
                            ))
                    }

                        <IconButton iconProps={{ iconName: "add" }} 
                            onClick={() => setValues({ emails: [...values.emails, ""]})}
                        />
                    </PanelFormFields>
                </PanelFormSection>
            </PanelForm>
        </Panel>
    );
}

export default InviteUsersPanel;