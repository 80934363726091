import * as React from "react";
import { useRef, useEffect, useMemo, useState } from "react"
import { Hoist } from "./../../views/hoist/useLocationHoists";
import { Text, Stack, Label, IStackTokens, StackItem, Separator } from "office-ui-fabric-react";
import TimespanPill, { DateRange } from "../../views/dashboard/TimespanPill";
import { getDateString } from "../../views/dashboard/charts/utils";
import { Query } from "../../views/dashboard/Dashboard";
import DailyActivations from "../../views/dashboard/charts/DailyActivations";
import WeightPerActivation from "../../views/dashboard/charts/WeightPerActivation";
import ActivationsOverTime from "../../views/dashboard/charts/ActivationsOverTime";



export interface Props 
{
    title?: string;
    hoist: Hoist;
}

const StatisticsView = (props: Props) =>
{
    const [dateRange, setDateRange] = useState<DateRange>(TimespanPill.initialValue);

    const query: Query = {
        hoist: props.hoist.hoistSerialNo,
        from: getDateString(dateRange.from),
        to: getDateString(dateRange.to)
    }

return(
<>
<Stack.Item styles={{root: { marginBottom: 8 }}}>
                    <Separator> <Text variant="large">Statistics</Text> </Separator> </Stack.Item>
                <Stack.Item>
                    <TimespanPill onDateRangeChange={setDateRange} />
                </Stack.Item>
                <div style={{display:"flex"}}>
                    <div><DailyActivations query={query} noBorder noDetails /></div>
                    <div><WeightPerActivation query={query} noBorder noDetails /></div>
                    <div><ActivationsOverTime query={query} noBorder noDetails doubleWidth={false} /></div>
                 </div>   


</>


)


}

export default StatisticsView;