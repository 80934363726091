import React from "react"
import { Component } from "react";
import authService from "../api-authorization/AuthorizeService";
import { LoginActions, QueryParameterNames, ApplicationPaths, LoginAction } from "../api-authorization/ApiAuthorizationConstants";
import OverlayProgress, { OverlayProgressBox } from "./view/OverlayProgress";
import { Stack, PrimaryButton } from "office-ui-fabric-react";

interface Props
{
    action: LoginAction;
}

interface State
{
    message?: string | null;
    failed?: boolean;
}

// The main responsibility of this component is to handle the user"s login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            message: undefined,
            failed: false,
        };

        console.log("login component created");
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LoginActions.Login:
                this.login(this.getReturnUrl());
                break;
            case LoginActions.LoginCallback:
                this.processLoginCallback();
                break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                this.setState({ message: error });
                break;
            case LoginActions.Profile:
                this.redirectToProfile();
                break;
            case LoginActions.Register:
                this.redirectToRegister();
                break;
            default:
                throw new Error(`Invalid action "${action}"`);
        }
    }

    render() {
        const action = this.props.action;
        const { message } = this.state;

        let label: string | undefined;
        if (!!message) {
            label = message;
        } else {
            switch (action) {
                case LoginActions.Login:
                    label = "Processing login";
                    break;
                case LoginActions.LoginCallback:
                    label = "Processing login callback";
                    break;
                case LoginActions.Profile:
                case LoginActions.Register:
                    label = undefined;
                    break;
                default:
                    throw new Error(`Invalid action "${action}"`);
            }
        }

        if(this.state.failed)
        {
            return (
                <OverlayProgressBox>
                    <Stack tokens={{childrenGap: 16}}>
                        <span>There was an error signing in.</span>
                        <PrimaryButton onClick={() => this.navigateToReturnUrl(window.location.origin)}>Back to Login Page</PrimaryButton>
                    </Stack>
                </OverlayProgressBox>
            );
        }

        return <OverlayProgress label={label} />
    }

    async login(returnUrl: string) {
        const state = { returnUrl };
        const result = await authService.signIn(state);
        switch (result.status) {
            case "redirect":
                break;
            case "success":
                await this.navigateToReturnUrl(returnUrl);
                break;
            case "fail":
                this.setState({ message: result.message, failed: true });
                break;
            default:
                throw new Error(`Invalid status result ${(result as any)?.status}.`);
        }
    }

    async processLoginCallback() {
        const url = window.location.href;
        const result = await authService.completeSignIn(url);
        switch (result.status) {
            case "redirect":
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error("Should not redirect.");
            case "success":
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case "fail":
                this.setState({ message: result.message, failed: true });
                break;
            default:
                throw new Error(`Invalid authentication result status "${(result as any)?.status}".`);
        }
    }

    getReturnUrl(state?: { returnUrl?: string }) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It"s important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl: string) {
        // It"s important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}
