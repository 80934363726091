import React, { useState, useRef, useMemo, useCallback } from "react";
import styled from "styled-components";
import { IContextualMenuItem, ContextualMenu, Text } from "office-ui-fabric-react";
import { useOutsideClickCallback } from "../../hooks/useOutsideClickCallback";

export const Pill = styled.div`
    border-radius: 16px;
    border: 1px solid black;
    padding: 4px 12px;
    cursor: pointer;
    display: inline-block;
`;

export interface Props
{
    label: string;
    text: string; 
    menu?: IContextualMenuItem[]; // | (() => Promise<IContextualMenuItem[]>);
    onMenuOpened?: () => void;
    onItemClicked?: (item: IContextualMenuItem) => void;
}

const setItemClickHandlers = (menu: IContextualMenuItem[], onItemClick: any): IContextualMenuItem[] =>
    menu.map<IContextualMenuItem>(item => ({
        ...item,
        onClick: (ev, clickedItem) => 
        {
            onItemClick(ev, clickedItem);
            item.onClick?.();
        },
        ...(item.subMenuProps === undefined ? undefined : {
            subMenuProps: {
                ...item.subMenuProps,
                items: setItemClickHandlers(item.subMenuProps.items, onItemClick)
            }
        })
    }));

export function FilterPill(props: Props)
{
    const [visible, setVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const { label, menu, text, onMenuOpened } = props;

    useOutsideClickCallback(() => setVisible(false), ref);

    const onItemClick = useCallback((_: unknown, item?: IContextualMenuItem) => {
        if(item !== undefined)
        {
            props.onItemClicked?.(item)
        }
        setVisible(false);
    }, [props.onItemClicked]);

    const derivedMenu = useMemo(() => 
        (menu === undefined) ? undefined : setItemClickHandlers(menu, onItemClick),
        [menu, onItemClick]);

    return (
        <>
            <Pill ref={ref} onClick={() => setVisible(v => !v)}>
                <Text>{label}: </Text>
                <Text style={{fontWeight: "bold"}}>{text}</Text>
            </Pill>
            {
                (menu !== undefined) ? (
                    <ContextualMenu 
                        target={ref}
                        items={derivedMenu || []}
                        onMenuOpened={onMenuOpened}
                        hidden={!visible}
                        // onItemClick={onItemClick}
                        />
                ) : null
            }
        </>
    );
}
