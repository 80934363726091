import { useAuthorizedFetch } from "./useFetch";
import { API_LOCATIONS, Location } from "../api";
import { useMemo } from "react";
import { IContextualMenuItem } from "office-ui-fabric-react";


const locationsToMenuItems = (locations: Location[], selection: Location[], additionalProps: Partial<IContextualMenuItem> = {}, disabledIds: number[] = []): IContextualMenuItem[] => 
    locations.map(loc => ({
        key: loc.id.toString(),
        text: loc.label,
        // canCheck: true,
        // checked: selection.find(x => x.id === loc.id) !== undefined,
        primaryDisabled: disabledIds.includes(loc.id),
        disabled: loc.children.length > 0 ? false : disabledIds.includes(loc.id),
        data: loc,
        ...((loc.children.length === 0) ? {} : ({
            subMenuProps: { items: locationsToMenuItems(loc.children, selection, additionalProps, disabledIds) },
            split: true
        })),
        ...additionalProps
    }));

export function useLocationMenu(selection: Location[], additionalProps?: Partial<IContextualMenuItem>, disabledIds: number[] = [])
{
    const [, locations] = useAuthorizedFetch<Location[]>(API_LOCATIONS);
    return useMemo(() => 
        locations && locationsToMenuItems(locations, selection, additionalProps, disabledIds), 
    [locations, selection, additionalProps, disabledIds]);
}