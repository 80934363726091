import { useEffect, useMemo, useState } from "react";

interface MediaQueryMap
{
    [key: string]: string;
}

// polyfill for older browsers, (mainly edgehtml)
if (typeof MediaQueryList.prototype.addEventListener !== "function") {
    MediaQueryList.prototype.addEventListener = function <K extends keyof MediaQueryListEventMap>(
        type: K,
        listener: (this: MediaQueryList, ev: MediaQueryListEventMap[K]) => any,
        options?: boolean | AddEventListenerOptions) {
        this.addListener(listener);
    };

    MediaQueryList.prototype.removeEventListener = function <K extends keyof MediaQueryListEventMap>(
        type: K,
        listener: (this: MediaQueryList, ev: MediaQueryListEventMap[K]) => any,
        options?: boolean | EventListenerOptions) {
        this.removeListener(listener);
    }
}

export function useMediaQuery(mediaQuery: string): boolean;
export function useMediaQuery(mediaQueries: string[]): boolean[];
export function useMediaQuery(mediaQueries: string[] | string): boolean[] | boolean
{
    const mqs = (typeof mediaQueries === "string")
        ? [mediaQueries]
        : mediaQueries;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initialResult = useMemo(() => mqs.map(q => window.matchMedia(q)).map(list => list.matches), []);
    const [result, setResult] = useState<boolean[]>(initialResult);

    useEffect(() => 
    {
        const lists = mqs.map(query => window.matchMedia(query));
        setResult(lists.map(list => list.matches));
        function onChange(ev: MediaQueryListEvent)
        {
            const index = mqs.indexOf(ev.media);
            if(index < -1)
            {
                console.warn("useMediaQuery: received change event for unknown media query");
                return;
            }

            setResult(result => {
                const newResult = [...result];
                newResult[index] = ev.matches;
                return newResult;
            });
        }

        lists.forEach(list => list.addEventListener("change", onChange));            

        return () => {
            lists.forEach(list => list.removeEventListener("change", onChange));            
        };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, mqs);

    return (mediaQueries === mqs) ? result : result[0];
}