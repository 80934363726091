import { useState, useEffect } from "react";
import authService from "./AuthorizeService";

export function useUserRoles()
{
    const [roles, setRoles] = useState<string[] | undefined>(undefined);
    useEffect(() => {
        const callback = async () => {
            setRoles(undefined);
            if(await authService.isAuthenticated())
            {
                const user = await authService.getUser();
                if(user !== null)
                {
                    setRoles(typeof user.role === "string" ? [user.role] : user.role);
                }
            }
            
        };
        
        callback();
        
        const subscriptionId = authService.subscribe(callback);
        return () => {
            authService.unsubscribe(subscriptionId);
        }
    }, []);
    return roles;
}