import { atom } from "recoil";
import { IMessageBarProps } from "office-ui-fabric-react";

type WithId<T> = T & { id: string; };
type WithExpiration<T> = T & { expiresAfter?: number };

export const notifications = atom<WithId<WithExpiration<IMessageBarProps>>[]>({
    key: "notifications",
    default: []
});

