import React, { useCallback, useState, useEffect, useRef } from "react";
import { Stack, Label, Checkbox, TextField, Toggle, StackItem, Dropdown, IDropdownOption } from "office-ui-fabric-react";
import { useAuthorizedFetch } from "../../../hooks/useFetch";
import { API_ADMIN_PASSWORD_POLICY, adminUpdatePasswordPolicy } from "../../../api";
import { useFormik } from "formik";
import ButtonPanel from "../../../components/ui/ButtonPanel";
import { PasswordPolicySchema, passwordPolicySchema as schema } from "../../../api/schema";
import { getOptions, saveOption, getTypeList } from "../../../api";


export interface IOption{
    id: string;
    optionType: string;
    optionValue: string;
    customerId: string
}

export interface ITagInfo{
    editMode: boolean;
}

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;

    visible?: boolean;
    onDismiss?: () => void;
    onPolicySaved?: () => void;

    tagInfo: ITagInfo;

}

interface FormProps
{
    initialValues: PasswordPolicySchema;
    onSave?: (values: PasswordPolicySchema, timeOutOption?:string) => void;
    onDismiss?: () => void;
    tagInfo: ITagInfo;
}

export const GroupOptions: IDropdownOption[] = [
    { key: "1", text: 'Global', data : { }},
    { key: '2', text: 'Training', data : { }},
  ];

  export interface ITagType 
  {
      id: string;
      typeName: string;
  }

function TagGroupForm(props: FormProps)
{
    const { initialValues, onSave, onDismiss, tagInfo } = props;
    const [idle, setIdle] = useState<string | undefined>("");
    const [options, setOptions] = useState<IOption[]>();
    const [timeoutEmpty, setTimeoutEmpty] = useState<boolean>(false);
    const [timeoutOption, setTimeoutOption] = useState<IOption | undefined>();
    const [tagType, setTagType] = useState<IDropdownOption[]>([]);
    const nameField = useRef<string>();
    const descrptionField = useRef<string>();
    const [activeCheckBox, setActiveCheckBox] = useState<boolean | undefined>(true);
    const [selectedType, setSelectedType] = useState<number>(0);
    const [tageTypeId, settagTypeId] = useState<string | undefined>();
  

    const onChangeField = (e:any, newValue?: string | undefined) => {
        if (e.target.name === "name"){
            nameField.current = newValue;
        }
        if (e.target.name === "description"){
            descrptionField.current = newValue;
        }
    }


    const initAlloptions = async (optionJson:IOption[]) => {

        //timeout
        const timeoutOption = optionJson?.find(x => x.optionType.toLowerCase() === 'timeout');
        if (timeoutOption === undefined){
            setTimeoutEmpty(true);
            setIdle("0");
    
            let res = await saveOption({id:'0',optionType:'timeout', optionValue: "0", customerId:'0'});
            if(res.ok)
            {
            }
            else
            {
                console.log("Save weight option error");
            }
    
        }
        else
        {
            if (timeoutOption.optionValue === "")
            {
                setIdle("0");    
            }
            else
            {
                setIdle(JSON.parse(timeoutOption.optionValue));    
            }
        }   
        setTimeoutOption(timeoutOption);
    }

    const createTypeOption = (tagTypeList:ITagType[]) => {
        let tagTypeArr: IDropdownOption[] = [];
        tagTypeList.map((item,index) => {
          tagTypeArr.push({ key: index+1, text: item.typeName, data : {id: item.id}});
        })
        setTagType(tagTypeArr);
      }

    const getOptionsAsync = async () => {
        let res = await getOptions();
        if(res.ok)
        {
            const retValues:IOption[] = await res.json();
            setOptions(retValues);
            initAlloptions(retValues);
        }
        else
        {
            console.log("Error from get options")
        }  
}

    useEffect(() => {
            getOptionsAsync();
     }, []);


     
     const getTypeDataList = async () => {
        const res = await getTypeList();
    
        if(res.ok)
        {
          const retJson:ITagType[] = await res.json();
          createTypeOption(retJson);
        }
        else
        {
        }
      }
    
        // training data list  
        useEffect(() => {
          const callback = async () => {
            getTypeDataList();
        }
          callback();
        }, []);
    
    
    const onChangeActive = (e:any,checked:boolean | undefined) => {
        setActiveCheckBox(checked);
    }

    const onChangeGroup = (id:string |undefined) => {
        if (id !== undefined){
          setSelectedType(parseInt(id));
          const idTagType:IDropdownOption | undefined = tagType.find(x => x.key === parseInt(id));
          settagTypeId(idTagType?.data.id);
        }
      }

      const onSaveTag = () => {
//        alert(nameField.current + "," + descrptionField.current + "," + activeCheckBox + "," + tageTypeId);


      }


    return (            
        <ButtonPanel
            headerText={tagInfo.editMode === true ? "Edit tag" : "Add tag"}
            onDismiss={onDismiss}
            onPrimaryButtonClick={() => onSaveTag()}
            isOpen
        >


<Stack tokens={{ childrenGap: "l1" }} style={{ marginTop: 8 }}>
                <StackItem>
                    <p>
                    </p>
                </StackItem>
                <StackItem>
                    <TextField label="Name" onChange={onChangeField} name="name" value={nameField.current} errorMessage={""} />
                </StackItem>
                <StackItem>
                    <TextField label="Description" onChange={onChangeField} name="description" value={descrptionField.current} errorMessage={""} />
                </StackItem>
                <StackItem>
                    <Checkbox label="Active" checked={activeCheckBox} onChange={(e, check) => onChangeActive(e, check)} />
                </StackItem>
                <StackItem>
                    <Dropdown label="Group" placeholder="Select a group" style={{width: "200px" }}  onChange={(_, option) => onChangeGroup(option?.key.toString())} options={tagType}></Dropdown>
                </StackItem>

            </Stack>
        </ButtonPanel>
    );
}

function AddGroupPanel(props: Props)
{
    const { onDismiss, onPolicySaved, visible } = props;
    const [state, policy, refresh] = useAuthorizedFetch<PasswordPolicySchema>(API_ADMIN_PASSWORD_POLICY);

    const onSave = () => {

        alert("Save");

    };

    return (visible && state === "success" && policy) ? (
        <TagGroupForm onDismiss={onDismiss} onSave={onSave} initialValues={policy} tagInfo={props.tagInfo}/>
    ) : null
}

export default AddGroupPanel;