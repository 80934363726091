import * as React from "react";
import { useRef, useEffect, useMemo } from "react"
import ChartJs from "chart.js";
import "chartjs-plugin-colorschemes";

export interface Props 
{
    className?: string;
    style?: React.CSSProperties;
    width?: number;
    height?: number;
    data: Chart.ChartData;
    options?: Chart.ChartOptions;
    type: string;
}

function Chart(props: Props)
{
    const { className, style = {}, width, height, data, options, type } = props;
    const canvas = useRef<HTMLCanvasElement>(null);
    const chart = useRef<Chart>();

    const divStyles = useMemo(() => ({
        ...style, width, height
    }), [style, width, height]);

    useEffect(() =>
    {
        if (chart.current === undefined)
        {
            return;
        }

        chart.current.data = data;
        chart.current.options = { ...(options || {}), maintainAspectRatio: false };
        chart.current.update();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, data]); // we don't want update here as it would trigger an unwanted update

    useEffect(() =>
    {
        if(canvas.current !== null && width !== undefined && height !== undefined)
        {
            canvas.current.width = width;
            canvas.current.height = height;
        }

        if (chart.current !== undefined && width !== undefined && height !== undefined)
        {
            chart.current.width = width;
            chart.current.height = height;
            chart.current.resize();
            chart.current.update();
        }
    }, [width, height])

    useEffect(() =>
    {

        if (canvas.current == null)
        {
            return;
        }

        const ctx: CanvasRenderingContext2D | null = canvas.current.getContext("2d");
        if (ctx === null)
        {
            throw Error("Could not get canvas 2d context");
        }

        chart.current = new ChartJs(ctx, {
            type: type,
            data, options
        });

        return () => 
        {
            chart.current?.destroy();
            chart.current = undefined;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas]); // we don't want data and options here as they are handled in the update effect hook


    return (
        <div style={divStyles} className={className}>
            <canvas ref={canvas} />
        </div>
    );
}

export default Chart;