import React from "react";
import ChartCard, { Props as ChartCardProps } from "../../../components/charts/ChartCard";
import { Query, toQueryString } from "./utils";
import { IColumn } from "office-ui-fabric-react";

export interface Props
{
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactChildren;

    query: Query;
    title?: string;
    noBorder?: boolean;
    noDetails?: boolean;
    doubleWidth?: boolean;
}

type MapDataToChart<T> = (response: T) => { labels: string[], values: number[] };

export const createChartComponent = <T extends any>(
    baseUri: string,
    map: MapDataToChart<T>,
    defaultTitle?: string,
    detailsColumns?: IColumn[],
    otherProps?: Partial<ChartCardProps<T>>
    ) => 
    (props: Props) => {
        const { query, noBorder, noDetails, title = defaultTitle, doubleWidth } = props;
        const queryStr = toQueryString(query);
    
        //console.log(map);

        const restProps = {
            noBorder,
            ...(noDetails ? ({}) : ({
                csvLink: `${baseUri}/csv?${queryStr}`,
                xlsxLink: `${baseUri}/xlsx?${queryStr}`,
                detailsLink: `${baseUri}?${queryStr}`,
                detailsColumns: detailsColumns
            })),
            ...(doubleWidth !== undefined ? ({doubleWidth}) : {})
        }

        return (
            <ChartCard
                type="doughnut"
                title={title}
                uri={`${baseUri}/summary?${queryStr}`}
                map={map} 
                {...(otherProps || {})}
                {...restProps}

            />);
    };

